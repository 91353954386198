import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AddUserAddress.css";
import NavigationLeft from "../../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { IoMenu } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import MenuItem from "@mui/material/MenuItem";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import PageHero from "../../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../../cookieUtils";

const AddUserAddress = () => {
  const [name, setName] = useState("");
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [eane, setEane] = useState("");
  const [eang, setEang] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const { userId } = useParams<{ userId: string }>(); // Extract the user ID from the URL

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleStreetnameChange = (event: any) => {
    setStreetname(event.target.value);
  };

  const handleHousenumberChange = (event: any) => {
    setHousenumber(event.target.value);
  };

  const handleZipcodeChange = (event: any) => {
    setZipcode(event.target.value);
  };

  const handleCityChange = (event: any) => {
    setCity(event.target.value);
  };

  const handleEaneChange = (event: any) => {
    setEane(event.target.value);
  };

  const handleEangChange = (event: any) => {
    setEang(event.target.value);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleAddUserAddress = async () => {
    try {
      const newUserAddress = {
        userId,
        name,
        streetname,
        housenumber,
        zipcode,
        city,
        eane,
        eang,
      };
      await axios.post(
        `${process.env.REACT_APP_DB_URL}/user-addresses`,
        newUserAddress,
      );
      console.log("UserAddress created successfully");
      navigate("/IndexAdministrator");
    } catch (error) {
      console.error("Error creating user:", error);
      // Handle error state or display an error message
    }
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Leveringsadres toevoegen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              {/* <hr className="dividerDashedAddAddressUser" /> */}

              <div className="userCardAddAddressUser">
                <div className="userCardContainerAddAddressUser">
                  <div className="userCardColumnAddAddressUser">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Naam"
                          variant="standard"
                          type="text"
                          id="name"
                          value={name}
                          onChange={handleNameChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Straatnaam"
                          variant="standard"
                          type="text"
                          id="streetname"
                          value={streetname}
                          onChange={handleStreetnameChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Huisnummer + busnummer"
                          variant="standard"
                          type="text"
                          id="housenumber"
                          value={housenumber}
                          onChange={handleHousenumberChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <button
                        className="addButton"
                        onClick={handleAddUserAddress}
                      >
                        Gebruiker toevoegen
                      </button>
                    </div>
                  </div>

                  <div className="userCardColumnAddAddressUser">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Postcode"
                          variant="standard"
                          type="text"
                          id="zipcode"
                          value={zipcode}
                          onChange={handleZipcodeChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Stad / Gemeente"
                          variant="standard"
                          type="text"
                          id="city"
                          value={city}
                          onChange={handleCityChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <ElectricBoltIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="EAN Elektriciteit"
                          variant="standard"
                          type="text"
                          id="eane"
                          value={eane}
                          onChange={handleEaneChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <GasMeterIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="EAN Gas"
                          variant="standard"
                          type="text"
                          id="eang"
                          value={eang}
                          onChange={handleEangChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddUserAddress;
