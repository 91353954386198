import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import "./MyProfile.css";
import { useNavigate } from "react-router";
import axios from "axios";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import CenterTitle from "../../../components/CenterTitle/CenterTitle";
import Verbruiksadres from "../../../components/Verbruiksadres/Verbruiksadres";
import { AiOutlinePlus } from "react-icons/ai";
import { getCookie } from "../../../cookieUtils";
import DeleteWarning from "../../../components/DeleteWarning/DeleteWarning";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";



interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  password: string;
  roleId: number;
}

const MyProfile: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [companyUserId, setCompanyUserId] = useState("");
  const [role, setRole] = useState("Customer");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteAction, setDeleteAction] = useState<() => void>(() => () => {});

  // Get stored userdata
  const storedUser = getCookie("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user.id;

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/users/${userId}`,
      );
      const user = response.data;
      formik.setValues({
        surname: user.surname,
        name: user.name,
        email: user.email,
        phone: user.phone,
        streetname: user.streetname,
        housenumber: user.housenumber,
        zipcode: user.zipcode,
        city: user.city,
        password: "",
      });
      setCompanyUserId(user.companyUserId);
      setRole(user.role);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const validationSchema = Yup.object({
    surname: Yup.string().required("Voornaam is verplicht"),
    name: Yup.string().required("Naam is verplicht"),
    email: Yup.string()
      .email("Ongeldig emailadres")
      .required("Email is verplicht"),
    phone: Yup.string()
      .matches(
        /^(\+|0)[0-9]+$/,
        "Telefoonnummer moet beginnen met + of 0 en alleen cijfers bevatten",
      )
      .required("Telefoonnummer is verplicht"),
    streetname: Yup.string().required("Straatnaam is verplicht"),
    housenumber: Yup.string()
      .required("Huisnummer is verplicht"),
    zipcode: Yup.string()
      .matches(/^[0-9]+$/, "Postcode mag alleen cijfers bevatten")
      .required("Postcode is verplicht"),
    city: Yup.string().required("Stad / Gemeente is verplicht"),
    password: Yup.string()
      .min(6, "Wachtwoord moet minimaal 6 karakters bevatten")
      .optional(),
  });

  const formik = useFormik({
    initialValues: {
      surname: "",
      name: "",
      email: "",
      phone: "",
      streetname: "",
      housenumber: "",
      zipcode: "",
      city: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const updatedUser = {
          companyUserId,
          ...values,
          roleId: role === "Administrator" ? 1 : 2,
        };
        await axios.put(
          `${process.env.REACT_APP_DB_URL}/users/${userId}`,
          updatedUser,
        );
        navigate("/IndexCustomer");
      } catch (error) {
        console.error("Error updating user:", error);
        setErrorMessage(
          "Er is iets misgegaan bij het bijwerken van uw gegevens.",
        );
      }
    },
  });

  const handlePasswordReset = async (data: any) => {
    try {
      await axios.post(`${process.env.REACT_APP_DB_URL}/passwordrequest`, data);
      navigate("/ProfilePasswordReset");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddUserAddressCustomer = (userId: any) => {
    navigate(`/AddUserAddressCustomer/${userId}`);
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const showDeleteConfirmation = (action: () => void) => {
    setDeleteAction(() => action);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteAction();
    setDialogOpen(false);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Mijn Profiel</PageHero>
          <div
            className="pageContainer"
            style={{
              display: "flex",
              minHeight: "100vh",
              backgroundColor: "#f8f9fa",
            }}
          >
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div
              className="contentContainer"
              style={{
                flex: "1",
                backgroundColor: "#f8f9fa",
                boxShadow: "none",
                padding: "20px",
                transition: "all 0.3s",
              }}
            >
              <CenterTitle
                title="Mijn Persoonsgegevens"
                subtitle="Hier kan u alle persoonsgegevens met betrekking op uw account terugvinden en aanpassen."
              />
              <form onSubmit={formik.handleSubmit} className="userCardContact">
                <div className="userCardContainerMyProfile">
                  <div className="userCardColumnMyProfile">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AccountCircle
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Voornaam"
                          variant="standard"
                          type="text"
                          id="surNameFilter"
                          {...formik.getFieldProps("surname")}
                          error={
                            formik.touched.surname &&
                            Boolean(formik.errors.surname)
                          }
                          helperText={
                            formik.touched.surname && formik.errors.surname
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AccountCircle
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Naam"
                          variant="standard"
                          type="text"
                          id="nameFilter"
                          {...formik.getFieldProps("name")}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AlternateEmailIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Email"
                          variant="standard"
                          type="text"
                          id="emailFilter"
                          {...formik.getFieldProps("email")}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <ContactPhoneIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Telefoonnummer"
                          variant="standard"
                          type="text"
                          id="phone"
                          {...formik.getFieldProps("phone")}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                  </div>
                  <div className="userCardColumnMyProfile">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Straatnaam"
                          variant="standard"
                          type="text"
                          id="streetname"
                          {...formik.getFieldProps("streetname")}
                          error={
                            formik.touched.streetname &&
                            Boolean(formik.errors.streetname)
                          }
                          helperText={
                            formik.touched.streetname &&
                            formik.errors.streetname
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Huisnummer + busnummer"
                          variant="standard"
                          type="text"
                          id="housenumber"
                          {...formik.getFieldProps("housenumber")}
                          error={
                            formik.touched.housenumber &&
                            Boolean(formik.errors.housenumber)
                          }
                          helperText={
                            formik.touched.housenumber &&
                            formik.errors.housenumber
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Postcode"
                          variant="standard"
                          type="text"
                          id="zipcode"
                          {...formik.getFieldProps("zipcode")}
                          error={
                            formik.touched.zipcode &&
                            Boolean(formik.errors.zipcode)
                          }
                          helperText={
                            formik.touched.zipcode && formik.errors.zipcode
                          }
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Stad / Gemeente"
                          variant="standard"
                          type="text"
                          id="city"
                          {...formik.getFieldProps("city")}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          fullWidth
                        />
                      </Box>
                    </div>
                  </div>
                  <div className="form-group flex-align-bottom">
                    <button className="buttonstyle33" type="submit">
                      Mijn gegevens aanpassen
                    </button>
                  </div>
                </div>
              </form>
              <div className="form-group">
                <p>Wachtwoord vergeten? Vraag hier een nieuw wachtwoord aan</p>
                <button
                  className="buttonstyle33"
                  onClick={() =>
                    handlePasswordReset({ email: formik.values.email })
                  }
                >
                  Nieuw wachtwoord aanvragen
                </button>
              </div>
              <div id="verbruiksadressen">
                <CenterTitle
                  title="Mijn Verbruiksadressen"
                  subtitle="Hier kan u alle verbruiksaddressen met betrekking op uw account terugvinden en aanpassen."
                />
                <Verbruiksadres
                  userId={userId}
                  showDeleteConfirmation={showDeleteConfirmation}
                />{" "}
                {/* Pass the delete confirmation function */}
                <button
                  className="buttonstyle33 flex"
                  onClick={() => handleAddUserAddressCustomer(userId)}
                >
                  <AiOutlinePlus
                    size={24}
                    style={{ strokeWidth: "30px", alignSelf: "center" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
      {/* <DeleteWarning
        open={isDialogOpen}
        title="Bevestigen Verwijderen"
        message="Weet u zeker dat u deze item wilt verwijderen?"
        onConfirm={handleConfirmDelete}
        onCancel={() => setDialogOpen(false)}
      /> */}
        <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Bevestigen Verwijderen
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Weet u zeker dat u deze item wilt verwijderen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleConfirmDelete} style={{ color: "red" }}>
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyProfile;


