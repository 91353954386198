import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import solarpanelsimage from "./zonnepanelen.jpg";
import solarpanelscleaningimage from "./zonnepanelenReinigen.jpg";
import boilerimage from "./ketel.jpg";
import windimage from "./windmolens.jpg";
import "./IndexCustomer.css";
import "./../../../theme/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import SectionHeroColored from "../../../components/SectionheroColored/SectionHeroColored";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box, Divider, Snackbar, Switch, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CenterTitle from "../../../components/CenterTitle/CenterTitle";
import MeterReadingsPopup from "./MeterReadingsPopup/MeterReadingsPopup";
import { v4 as uuidv4 } from "uuid";
import MeterReadingsGPopup from "./MeterReadingsPopup/MeterReadingsGPopup";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { IoFlame } from "react-icons/io5";
import { GiPylon } from "react-icons/gi";
import fluviusphoto from "../../../assets/fluvius.jpg";
import MeterReadingsIPopup from "./MeterReadingsPopup/MeterReadingsIPopup";
import SectionHeroColoredSmall from "../../../components/SectionheroColoredSmall/SectionHeroColoredSmall";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SwitchTextTrack from "../../../components/SwitchTextTrack/SwitchTextTrack";
import { getCookie } from "../../../cookieUtils";
import moment from 'moment';
import 'moment/locale/nl-be'; 

interface UserConsumptionElectricity {
  userAddressId: string;
  id: string;
  userId: string;
  meterReadingId: string;
  date: Date;
  consumption: number;
}

interface UserConsumptionGas {
  userAddressId: string;
  id: string;
  userId: string;
  meterReadingId: string;
  date: Date;
  consumption: number;
}

interface UserConsumptionInjection {
  userAddressId: string;
  id: string;
  userId: string;
  meterReadingId: string;
  date: Date;
  consumption: number;
}

interface MeterReadingElectricity {
  userAddressId: string;
  id: string;
  userId: string;
  date: Date;
  meterReading: Number;
}

interface MeterReadingGas {
  userAddressId: string;
  id: string;
  userId: string;
  date: Date;
  meterReading: Number;
}

interface MeterReadingInjection {
  userAddressId: string;
  id: string;
  userId: string;
  date: Date;
  meterReading: Number;
}

interface CombinedData {
  date: Date;
  electricity?: number;
  injection?: number;
}

const IndexCustomer: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [userConsumptionElectricity, setUserConsumptionElectricity] = useState<
    UserConsumptionElectricity[]
  >([]);
  const [userConsumptionInjection, setUserConsumptionInjection] = useState<
    UserConsumptionInjection[]
  >([]);
  const [
    userConsumptionElectricityFluvius,
    setUserConsumptionElectricityFluvius,
  ] = useState<UserConsumptionElectricity[]>([]);
  const [userMeterE, setUserMeterE] = useState<MeterReadingElectricity[]>([]);
  const [userMeterG, setUserMeterG] = useState<MeterReadingGas[]>([]);
  const [userMeterI, setUserMeterI] = useState<MeterReadingInjection[]>([]);
  const [userConsumptionE, setUserConsumptionE] = useState<number>(0);
  const [userConsumptionI, setUserConsumptionI] = useState<number>(0);
  const [userConsumptionEFluvius, setUserConsumptionEFluvius] =
    useState<number>(0);
  const [userConsumptionGas, setUserConsumptionGas] = useState<
    UserConsumptionGas[]
  >([]);
  const [userConsumptionGasFluvius, setUserConsumptionGasFluvius] = useState<
    UserConsumptionGas[]
  >([]);
  const [userconsumptionG, setUserConsumptionG] = useState<number>(0);
  const [userconsumptionGFluvius, setUserConsumptionGFluvius] =
    useState<number>(0);
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [selectedDate3, setSelectedDate3] = useState(new Date());
  const navigate = useNavigate();
  const location = useLocation();

  // State variable to control the visibility of the modal
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisibleE, setModalVisibleE] = useState(false);
  const [isModalVisibleG, setModalVisibleG] = useState(false);
  const [isModalVisibleI, setModalVisibleI] = useState(false);
  const [isModalVisibleFluvius, setModalVisibleFluvius] = useState(true);
  const [isModalVisibleInitial, setModalVisibleInitial] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<string>("jaarlijks"); // Default to daily
  const [selectedTimePeriodG, setSelectedTimePeriodG] =
    useState<string>("jaarlijks"); // Default time period for gas is daily

  // const [userAddressId, setUserAddressId] = useState<string | null>(null);
  const [userAddressId, setUserAddressId] = useState("");
  const [fluvius, setFluvius] = useState(0);
  const electricityDetailsPrice = getCookie("electricityDetailsPrice");
  const gasDetailsPrice = getCookie("gasDetailsPrice");
  const injectionDetailsPrice = getCookie("injectionDetailsPrice");
  // Set's on pageload the datetime on input for the input fields of electricity and gas meter readings
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  moment.locale('nl-be'); // Set locale globally

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    console.log(roleIdFromStorage);
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    const storedUserAddress = getCookie("selectedAddress");
    if (storedUserAddress) {
      setUserAddressId(storedUserAddress); // Set the userAddressId based on the selected user address
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    // Fetch consumption data based on the selected user address whenever userAddressId changes
    if (userAddressId) {
      fetchConsumptionData();
      fetchConsumptionDataFluvius();
      fetchSelectionData();
    }
  }, [userAddressId]); // Add userAddressId as a dependency

  const fetchConsumptionData = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);

        const UserConsumptionElectricityResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-consumption-electricity/address/${userAddressId}`,
        );
        const electricityData = UserConsumptionElectricityResponse.data.map(
          (item: UserConsumptionElectricity) => ({
            date: new Date(item.date),
            consumption: item.consumption,
          }),
        );

        const UserConsumptionInjectionResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-consumption-injection/address/${userAddressId}`,
        );
        const InjectionData = UserConsumptionInjectionResponse.data.map(
          (item: UserConsumptionInjection) => ({
            date: new Date(item.date),
            consumption: item.consumption,
          }),
        );

        const UserConsumptionGasResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-consumption-gas/address/${userAddressId}`,
        );
        const gasData = UserConsumptionGasResponse.data.map(
          (item: UserConsumptionGas) => ({
            date: new Date(item.date),
            consumption: item.consumption,
          }),
        );

        try {
          const UserMeterGResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/meter-readings-gas/address/${userAddressId}`,
          );
          setUserMeterG(UserMeterGResponse.data);
        } catch (error) {
          console.error("Error retrieving gas meter readings:", error);
        }

        try {
          const UserMeterEResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/meter-readings-electricity/address/${userAddressId}`,
          );
          setUserMeterE(UserMeterEResponse.data);

          const UserMeterIResponse = await axios.get(
            `${process.env.REACT_APP_DB_URL}/meter-readings-injection/address/${userAddressId}`,
          );
          setUserMeterI(UserMeterIResponse.data);

          console.log(userMeterE);
          console.log("injection: " + userMeterI);
        } catch (error) {
          console.error(
            "Error retrieving electricity & Injection meter readings:",
            error,
          );
        }

        setUserConsumptionElectricity(electricityData);
        setUserConsumptionGas(gasData);
        setUserConsumptionInjection(InjectionData);
      }
    } catch (error) {
      console.error("Error retrieving usagedata:", error);
    }
  };

  const fetchConsumptionDataFluvius = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);

        const UserConsumptionElectricityResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-consumption-electricity-fluvius/address/${userAddressId}`,
        );
        const electricityData = UserConsumptionElectricityResponse.data.map(
          (item: UserConsumptionElectricity) => ({
            date: new Date(item.date),
            consumption: item.consumption,
          }),
        );

        const UserConsumptionGasResponse = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-consumption-gas-fluvius/address/${userAddressId}`,
        );
        const gasData = UserConsumptionGasResponse.data.map(
          (item: UserConsumptionGas) => ({
            date: new Date(item.date),
            consumption: item.consumption,
          }),
        );

        setUserConsumptionElectricityFluvius(electricityData);
        setUserConsumptionGasFluvius(gasData);
      }
    } catch (error) {
      console.error("Error retrieving usagedata from Fluvius:", error);
    }
  };

  const fetchSelectionData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_DB_URL}/user-addresses/${userAddressId}`,
    );
    const currentUserAddress = response.data;
    setFluvius(currentUserAddress.isFluviusConnected);
  };

  const navigateToSolarPanelsApplicationCustomer = () => {
    navigate("/Quotes");
  };

  const navigateToMyContracts = () => {
    navigate("/Quotes");
  };

  const navigateToMyDocuments = () => {
    navigate("/FilesCustomer");
  };

  const navigateToMyProfile = () => {
    navigate("/MyProfile#verbruiksadressen");
  };

  const navigateToMyNews = () => {
    // navigate('https://energyneeds.be/category/app/');
    window.location.href = "https://energyneeds.be/category/app/";
  };

  const navigateToMyBeurs = () => {
    navigate("/Advice#beursSection");
  };

  const navigateToMyDiensten = () => {
    navigate("/Quotes");
  };

  const navigateToMyPartners = () => {
    navigate("/Partners");
  };

  const navigateToMyOffertes = () => {
    navigate("/Quotes");
  };

  const navigateToMyVerbruik = () => {
    navigate("/IndexCustomer#verbruik");
  };

  const navigateToMyEnergieVergelijken = () => {
    navigate("/ChangeEnergyContractCustomer");
  };

  const navigateToSolarPanelsCleaningApplicationCustomer = () => {
    navigate("/Quotes");
  };

  const navigateToBoilerMaintenanceApllicationCustomer = () => {
    navigate("/Quotes");
  };

  const handleFluviusConnect = () => {
    navigate("/FluviusConnect");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    padding: "20px",
    transition: "all 0.3s",
    boxShadow: "none", // Remove the box shadow
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
  };

  const squareStyles: React.CSSProperties = {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
  };

  const buttonStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "2px solid",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    //color: 'rgb(18, 31, 67)',
    color: "#8dc63f",
    borderColor: "#8dc63f",
  };

  const dashboardContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const chartContainerStyles: React.CSSProperties = {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    background: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    paddingBottom: "50px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const chartStyles: React.CSSProperties = {
    flex: "1",
    height: "400px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    margin: "0 auto", // Center the charts within the squares
    alignItems: "center", // Center the charts horizontally
  };

  // Styles for the modal and overlay
  const modalOverlayStyles: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  };

  const modalStyles: React.CSSProperties = {
    backgroundColor: "#ffffff",
    width: "800px",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative", // Set position to relative for the modal
  };

  const modalTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  };

  const closeButtonStyles: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
  };

  const modalContentStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Set the direction to column
    alignItems: "center", // Center items horizontally
    gap: "10px", // Add spacing between elements
  };

  const modalContainerStyles: React.CSSProperties = {
    position: "relative", // Set position to relative for the container
    maxWidth: "90%",
  };

  const insertButtonStyles: React.CSSProperties = {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const iconContainerStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#8dc63f", // Green background color
    marginRight: "0px",
  };

  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const squareCouStyles: React.CSSProperties = {
    flex: "1",
    height: "120px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "20px",
    marginTop: "30px",
    alignItems: "flex-start",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const bottomBorderStyles: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: "#8dc63f", // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };
  const counterStyles: React.CSSProperties = {
    fontSize: "30px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const [isFluviusConnected, setIsFluviusConnected] = useState(false);
  const [userData, setUserData] = useState(null); // Example state for user data

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/user-addresses/${userAddressId}`,
        );
        const currentUserAddress = response.data;
        setIsFluviusConnected(currentUserAddress.isFluviusConnected === 1); // Assuming 1 means true, 2 means false
        // Assuming other user data is also fetched
        setUserData(currentUserAddress); // Example: set user data state
      } catch (error) {
        console.error("Error fetching user address:", error);
        // Handle error state or display an error message
      }
    };

    fetchUserAddress();
  }, [userAddressId]);

  const handleFluvius = async () => {
    try {
      const updatedFluvius = isFluviusConnected ? 2 : 1;

      await axios.put(
        `${process.env.REACT_APP_DB_URL}/user-addresses-connected/${userAddressId}`,
        { isFluviusConnected: updatedFluvius },
      );

      setIsFluviusConnected(!isFluviusConnected);

      console.log("Fluvius updated successfully");
    } catch (error) {
      console.error("Error connecting to Fluvius:", error);
      // Handle error state or display an error message
    }
  };

  // Function to handle opening and closing the modal
  const handleModalToggle = () => {
    setModalVisible(!isModalVisible);

    // Call the function to update userConsumptionE
    if (!isModalVisible) {
      updateMostRecentElectricityReading();
      updateMostRecentgasReading();
      updateMostRecentInjectionReading();
    }
  };

  // Function to handle opening and closing the modal
  const handleModalToggleFluvius = () => {
    setModalVisibleFluvius(!isModalVisibleFluvius);
  };

  // Function to handle opening and closing the modal
  const handleModalToggleE = () => {
    setModalVisibleE(!isModalVisibleE);

    // Call the function to update userConsumptionE
    if (!isModalVisibleE) {
      // updateMostRecentElectricityReadingE();
    }
  };

  // Function to handle opening and closing the modal
  const handleModalToggleG = () => {
    setModalVisibleG(!isModalVisibleG);

    // Call the function to update userConsumptionG
    if (!isModalVisibleG) {
      //   updateMostRecentElectricityReadingG();
    }
  };

  // Function to handle opening and closing the modal
  const handleModalToggleI = () => {
    setModalVisibleI(!isModalVisibleI);

    // Call the function to update userConsumptionI
    if (!isModalVisibleI) {
      //   updateMostRecentElectricityReadingI();
    }
  };

  // Function to handle opening and closing the modal
  const handleModalToggleInitial = () => {
    setModalVisibleInitial(!isModalVisibleInitial);
    // Call the function to update userConsumptionE
    if (!isModalVisibleInitial) {
      updateMostRecentElectricityReading();
      updateMostRecentgasReading();
      updateMostRecentInjectionReading();
    }
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (isInitialLoad) {
      // Set the initial value of selectedDates to the current date and time
      setSelectedDate1(new Date());
      setSelectedDate2(new Date());
      setSelectedDate3(new Date());
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  const updateMostRecentElectricityReading = () => {
    if (userMeterE.length > 0) {
      const mostRecentElectricityReading = userMeterE.reduce(
        (max, meterReading) =>
          meterReading.date > max.date ? meterReading : max,
        userMeterE[0],
      );
      setUserConsumptionE(mostRecentElectricityReading.meterReading as number);
    }

    if (userMeterE.length === 0) {
      setUserConsumptionE(0 as number);
    }
  };

  const updateMostRecentgasReading = () => {
    try {
      if (userMeterG.length > 0) {
        const mostRecentGasReading = userMeterG.reduce(
          (max, meterReading) =>
            meterReading.date > max.date ? meterReading : max,
          userMeterG[0],
        );
        setUserConsumptionG(mostRecentGasReading.meterReading as number);
      }
      if (userMeterG.length === 0) {
        setUserConsumptionG(0 as number);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateMostRecentInjectionReading = () => {
    if (userMeterI.length > 0) {
      const mostRecentInjectionReading = userMeterI.reduce(
        (max, meterReading) =>
          meterReading.date > max.date ? meterReading : max,
        userMeterI[0],
      );
      setUserConsumptionI(mostRecentInjectionReading.meterReading as number);
    }

    if (userMeterI.length === 0) {
      setUserConsumptionI(0 as number);
    }
  };

  const storedUser = getCookie("user");
  let param: { id: string } | null;

  if (storedUser) {
    param = JSON.parse(storedUser);
  } else {
    param = null;
  }

  // Helper function to calculate the number of days between two dates
  const daysBetweenDates = (date1: Date, date2: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    // Ensure date1 and date2 are instances of Date
    const utcDate1 = new Date(date1);
    const utcDate2 = new Date(date2);

    // Set the time portion to midnight for both dates
    utcDate1.setHours(0, 0, 0, 0);
    utcDate2.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds
    const timeDifference = Math.abs(utcDate2.getTime() - utcDate1.getTime());

    // Calculate the number of days
    return Math.ceil(timeDifference / oneDay);
  };

 
  const createElectricityAndGasMeterReadings = async (
    electricityReading: number | null,
    gasReading: number | null,
    injectionReading: number | null,
  ) => {
    try {
      const metereId = uuidv4();
      const metergId = uuidv4();
      const meteriId = uuidv4();

      if (
        electricityReading !== null &&
        !isNaN(electricityReading) &&
        electricityReading > 0
      ) {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/meter-readings-electricity/useraddress/${userAddressId}`,
        );
        const meterReadings = response.data;
        const closestReadingBeforeDate = meterReadings.reduce(
          (prev: any, curr: any) =>
            Math.abs(new Date(curr.date).getDate() - selectedDate1.getDate()) <
            Math.abs(new Date(prev.date).getDate() - selectedDate1.getDate())
              ? curr
              : prev,
        );
        const adjustedElectricityReading =
          electricityReading - (userConsumptionE || 0);
        if (param && param.id) {
          console.log("param: " + param + "  +  " + param.id);
          const daysInRange = daysBetweenDates(
            closestReadingBeforeDate.date,
            selectedDate1,
          );
          const dailyConsumption = adjustedElectricityReading / daysInRange;

          // Distribute consumption over days
          const currentDate = new Date(closestReadingBeforeDate.date);
          currentDate.setDate(currentDate.getDate() + 1);

          for (let i = 0; i <= daysInRange - 1; i++) {
            const responseAdjustedElectricityDaily = await axios.post(
              `${process.env.REACT_APP_DB_URL}/user-consumption-electricity`,
              {
                userAddressId,
                meterReadingId: metereId,
                userId: param.id,
                consumption: dailyConsumption,
                date: currentDate.toISOString(),
              },
            );
            console.log(
              "Adjusted electricity daily consumption created:",
              responseAdjustedElectricityDaily.data,
            );

            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
      }
      // }

      if (gasReading !== null && !isNaN(gasReading) && gasReading > 0) {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/meter-readings-gas/useraddress/${userAddressId}`,
        );
        const meterReadings = response.data;
        const closestReadingBeforeDate = meterReadings.reduce(
          (prev: any, curr: any) =>
            Math.abs(new Date(curr.date).getDate() - selectedDate2.getDate()) <
            Math.abs(new Date(prev.date).getDate() - selectedDate1.getDate())
              ? curr
              : prev,
        );
        const adjustedGasReading = gasReading - (userconsumptionG || 0);
        if (param && param.id) {
          console.log("param: " + param + "  +  " + param.id);
          const daysInRange = daysBetweenDates(
            closestReadingBeforeDate.date,
            selectedDate2,
          );
          const dailyConsumption = adjustedGasReading / daysInRange;

          // Distribute consumption over days
          const currentDate = new Date(closestReadingBeforeDate.date);
          currentDate.setDate(currentDate.getDate() + 1);

          for (let i = 0; i <= daysInRange - 1; i++) {
            const responseAdjustedGasDaily = await axios.post(
              `${process.env.REACT_APP_DB_URL}/user-consumption-gas`,
              {
                userAddressId,
                meterReadingId: metereId,
                userId: param.id,
                consumption: dailyConsumption,
                date: currentDate.toISOString(),
              },
            );
            console.log(
              "Adjusted gas daily consumption created:",
              responseAdjustedGasDaily.data,
            );

            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
      }
      // Injection
      if (
        injectionReading !== null &&
        !isNaN(injectionReading) &&
        injectionReading > 0
      ) {
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/meter-readings-injection/useraddress/${userAddressId}`,
        );
        const meterReadings = response.data;
        const closestReadingBeforeDate = meterReadings.reduce(
          (prev: any, curr: any) =>
            Math.abs(new Date(curr.date).getDate() - selectedDate1.getDate()) <
            Math.abs(new Date(prev.date).getDate() - selectedDate1.getDate())
              ? curr
              : prev,
        );
        const adjustedInjectionReading =
          injectionReading - (userConsumptionI || 0);
        if (param && param.id) {
          console.log("param: " + param + "  +  " + param.id);
          const daysInRange = daysBetweenDates(
            closestReadingBeforeDate.date,
            selectedDate3,
          );
          const dailyConsumption = adjustedInjectionReading / daysInRange;

          // Distribute consumption over days
          const currentDate = new Date(closestReadingBeforeDate.date);
          currentDate.setDate(currentDate.getDate() + 1);

          for (let i = 0; i <= daysInRange - 1; i++) {
            const responseAdjustedInjectionDaily = await axios.post(
              `${process.env.REACT_APP_DB_URL}/user-consumption-injection`,
              {
                userAddressId,
                meterReadingId: metereId,
                userId: param.id,
                consumption: dailyConsumption,
                date: currentDate.toISOString(),
              },
            );
            console.log(
              "Adjusted injection daily consumption created:",
              responseAdjustedInjectionDaily.data,
            );

            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
      }

      if (
        param &&
        param.id &&
        electricityReading !== null &&
        !isNaN(electricityReading) &&
        electricityReading > 0
      ) {
        const responseElectricity = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-electricity`,
          {
            id: metereId,
            userAddressId,
            userId: param.id,
            meterReading: electricityReading,
            date: selectedDate1,
          },
        );

        console.log(
          "Electricity meter reading created:",
          responseElectricity.data,
        );
      }

      if (
        param &&
        param.id &&
        gasReading !== null &&
        !isNaN(gasReading) &&
        gasReading > 0
      ) {
        const responseGas = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-gas`,
          {
            id: metergId,
            userAddressId,
            userId: param.id,
            meterReading: gasReading,
            date: selectedDate2,
          },
        );
        console.log("Gas meter reading created:", responseGas.data);
      }

      if (
        param &&
        param.id &&
        injectionReading !== null &&
        !isNaN(injectionReading) &&
        injectionReading > 0
      ) {
        const responseInjection = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-injection`,
          {
            id: metereId,
            userAddressId,
            userId: param.id,
            meterReading: injectionReading,
            date: selectedDate3,
          },
        );

        console.log("Injection meter reading created:", responseInjection.data);
      }

      handleModalToggle();
      window.location.reload();
    } catch (error) {
      console.error("Error creating meter readings:", error);
      // Handle error if needed
    }
  };

  const createElectricityAndGasMeterReadingsInitial = async (
    electricityReading: number | null,
    gasReading: number | null,
    injectionReading: number | null,
  ) => {
    const userMeterE = uuidv4();

    const userMeterG = uuidv4();

    const userMeterI = uuidv4();

    try {
      if (
        param &&
        param.id &&
        electricityReading !== null &&
        !isNaN(electricityReading) &&
        electricityReading !== 0
      ) {
        const responseElectricity = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-electricity`,
          {
            id: userMeterE,
            userAddressId,
            userId: param.id,
            meterReading: electricityReading,
            date: selectedDate1,
          },
        );
        console.log(
          "Electricity meter reading created:",
          responseElectricity.data,
        );

        const responseUserElectricity = await axios.post(
          `${process.env.REACT_APP_DB_URL}/user-consumption-electricity`,
          {
            userAddressId,
            meterReadingId: userMeterE,
            userId: param.id,
            consumption: 0,
            date: selectedDate1,
          },
        );
        console.log(
          "New electricity consumption created:",
          responseUserElectricity.data,
        );
      }

      if (
        param &&
        param.id &&
        gasReading !== null &&
        !isNaN(gasReading) &&
        gasReading !== 0
      ) {
        const responseGas = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-gas`,
          {
            id: userMeterG,
            userAddressId,
            userId: param.id,
            meterReading: gasReading,
            date: selectedDate2,
          },
        );
        console.log("Gas meter reading created:", responseGas.data);

        const responseUserGas = await axios.post(
          `${process.env.REACT_APP_DB_URL}/user-consumption-gas`,
          {
            userAddressId,
            meterReadingId: userMeterG,
            userId: param.id,
            consumption: 0,
            date: selectedDate2,
          },
        );
        console.log("New gas consumption created:", responseUserGas.data);
      }

      if (
        param &&
        param.id &&
        injectionReading !== null &&
        !isNaN(injectionReading) &&
        injectionReading !== 0
      ) {
        const responseInjection = await axios.post(
          `${process.env.REACT_APP_DB_URL}/meter-readings-injection`,
          {
            id: userMeterI,
            userAddressId,
            userId: param.id,
            meterReading: injectionReading,
            date: selectedDate3,
          },
        );
        console.log("Injection meter reading created:", responseInjection.data);

        const responseUserInjection = await axios.post(
          `${process.env.REACT_APP_DB_URL}/user-consumption-injection`,
          {
            userAddressId,
            meterReadingId: userMeterI,
            userId: param.id,
            consumption: 0,
            date: selectedDate3,
          },
        );
        console.log(
          "New injection consumption created:",
          responseUserInjection.data,
        );
      }

      handleModalToggleInitial();
      window.location.reload();
    } catch (error) {
      console.error("Error creating meter readings:", error);
      // Handle error if needed
    }
  };

  // Function to sort data by date Electricity
  const sortByDate = (data: UserConsumptionElectricity[]) => {
    return data.sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  // Function to sort data by date Gas
  const sortByDateG = (data: UserConsumptionGas[]) => {
    return data.sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  // Function to sort data by date Injection
  const sortByDateI = (data: UserConsumptionInjection[]) => {
    return data.sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  const filterDataByTimePeriod = (
    data: UserConsumptionElectricity[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "elk uur":
        const oneHourAgo = new Date(currentDate.getTime() - 1 * 60 * 60 * 1000);
        return data.filter((item) => item.date >= oneHourAgo);
      case "elke 3 uur":
        const threeHoursAgo = new Date(
          currentDate.getTime() - 3 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= threeHoursAgo);
      case "elke 6 uur":
        const sixHoursAgo = new Date(
          currentDate.getTime() - 6 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= sixHoursAgo);
      case "elke 12 uur":
        const twelveHoursAgo = new Date(
          currentDate.getTime() - 12 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= twelveHoursAgo);
      case "dagelijks":
        return data.filter(
          (item) =>
            item.date.getFullYear() === currentDate.getFullYear() &&
            item.date.getMonth() === currentDate.getMonth() &&
            item.date.getDate() === currentDate.getDate(),
        );
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneYearAgo);
      default:
        return data;
    }
  };

  const filterDataByTimePeriodG = (
    data: UserConsumptionGas[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "elk uur":
        const oneHourAgo = new Date(currentDate.getTime() - 1 * 60 * 60 * 1000);
        return data.filter((item) => item.date >= oneHourAgo);

      case "elke 3 uur":
        const threeHoursAgo = new Date(
          currentDate.getTime() - 3 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= threeHoursAgo);

      case "elke 6 uur":
        const sixHoursAgo = new Date(
          currentDate.getTime() - 6 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= sixHoursAgo);

      case "elke 12 uur":
        const twelveHoursAgo = new Date(
          currentDate.getTime() - 12 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= twelveHoursAgo);

      case "dagelijks":
        return data.filter(
          (item) =>
            item.date.getFullYear() === currentDate.getFullYear() &&
            item.date.getMonth() === currentDate.getMonth() &&
            item.date.getDate() === currentDate.getDate(),
        );

      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= oneWeekAgo);

      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneMonthAgo);

      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneYearAgo);

      default:
        return data;
    }
  };

  const filterDataByTimePeriodI = (
    data: UserConsumptionInjection[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "dagelijks":
        return data.filter(
          (item) =>
            item.date.getFullYear() === currentDate.getFullYear() &&
            item.date.getMonth() === currentDate.getMonth() &&
            item.date.getDate() === currentDate.getDate(),
        );
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => item.date >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => item.date >= oneYearAgo);
      default:
        return data;
    }
  };

  // Assuming userConsumptionElectricity is an array of objects with a consumption property

  const filteredData = filterDataByTimePeriod(
    sortByDate(userConsumptionElectricity),
    selectedTimePeriod,
  );
  const totalElectricityConsumption = filteredData.reduce(
    (total, item) => total + Number(item.consumption),
    0,
  );

  const totalElectricityDetailsPrice =
    totalElectricityConsumption * parseFloat(electricityDetailsPrice ?? "0");
  // setTotalElectricityDetailsPrice(totalElectricityDetailsPrice1);

  // Assuming userConsumptionGas is an array of objects with a consumption property

  const filteredGasData = filterDataByTimePeriodG(
    sortByDateG(userConsumptionGas),
    selectedTimePeriod,
  );
  const totalGasConsumption = filteredGasData.reduce(
    (total, item) => total + Number(item.consumption),
    0,
  );

  const totalGasDetailsPrice =
    totalGasConsumption * parseFloat(gasDetailsPrice ?? "0");

  // Assuming userConsumptionInjection is an array of objects with a consumption property

  const filteredInjectionData = filterDataByTimePeriod(
    sortByDate(userConsumptionInjection),
    selectedTimePeriod,
  );
  const totalInjectionConsumption = filteredData.reduce(
    (total, item) => total + Number(item.consumption),
    0,
  );

  const totalInjectionDetailsPrice =
    totalInjectionConsumption * parseFloat(injectionDetailsPrice ?? "0");
  // setTotalElectricityDetailsPrice(totalElectricityDetailsPrice1);

  const filteredDataFluvius = filterDataByTimePeriod(
    sortByDate(userConsumptionElectricityFluvius),
    selectedTimePeriod,
  );
  const totalElectricityConsumptionFluvius = filteredDataFluvius.reduce(
    (total, item) => total + Number(item.consumption),
    0,
  );

  const totalElectricityDetailsPriceFluvius =
    totalElectricityConsumptionFluvius *
    parseFloat(electricityDetailsPrice ?? "0");
  // setTotalElectricityDetailsPrice(totalElectricityDetailsPrice1);

  // Assuming userConsumptionGas is an array of objects with a consumption property

  const filteredGasDataFluvius = filterDataByTimePeriodG(
    sortByDateG(userConsumptionGasFluvius),
    selectedTimePeriod,
  );
  const totalGasConsumptionFluvius = filteredGasDataFluvius.reduce(
    (total, item) => total + Number(item.consumption),
    0,
  );

  const totalGasDetailsPriceFluvius =
    totalGasConsumptionFluvius * parseFloat(gasDetailsPrice ?? "0");

  // Calculate the maximum consumption value of electricity
  const maxConsumption = Math.max(
    ...userConsumptionElectricity.map((item) => item.consumption),
  );

  // Calculate the maximum consumption value of gas
  const maxConsumptionGas = Math.max(
    ...userConsumptionGas.map((item) => item.consumption),
  );

  // Calculate the maximum consumption value of injection
  const maxConsumptionInjection = Math.max(
    ...userConsumptionInjection.map((item) => item.consumption),
  );

  // // Electricity and Injection chart to combine the data and give it back into the chart
  // const generateCombinedData = (selectedTimePeriod: any): CombinedData[] => {
  //   // Filter and sort electricity consumption data
  //   // console.log("Filtered Electricity Data:", userConsumptionElectricity);
  //   const filteredElectricityData = filterDataByTimePeriod(sortByDate(userConsumptionElectricity), selectedTimePeriod);
  //   // console.log("Filtered and Sorted Electricity Data:", filteredElectricityData);

  //   // Filter and sort injection consumption data
  //   // console.log("Filtered Injection Data:", userConsumptionInjection);
  //   const filteredInjectionData = filterDataByTimePeriod(sortByDate(userConsumptionInjection), selectedTimePeriod);
  //   // console.log("Filtered and Sorted Injection Data:", filteredInjectionData);

  //   // Combine and aggregate consumption data per date
  //   const combinedData: Record<string, CombinedData> = {};

  // // Aggregate electricity consumption data
  // filteredElectricityData.forEach(({ date, consumption }) => {
  //   const dateString = date.toISOString().split('T')[0]; // Convert Date to string
  //   if (!combinedData[dateString]) {
  //     combinedData[dateString] = { date: new Date(dateString), electricity: 0, injection: 0 }; // Initialize properties
  //   }
  //   // Ensure consumption is treated as a number
  //   combinedData[dateString].electricity = parseFloat(consumption as any);
  // });

  // // Aggregate injection consumption data
  // filteredInjectionData.forEach(({ date, consumption }) => {
  //   const dateString = date.toISOString().split('T')[0]; // Convert Date to string
  //   if (!combinedData[dateString]) {
  //     combinedData[dateString] = { date: new Date(dateString), electricity: 0, injection: 0 }; // Initialize properties
  //   }
  //   // Ensure consumption is treated as a number
  //   combinedData[dateString].injection = parseFloat(consumption as any);
  // });

  //   // Convert combinedData object to an array
  //   const result: CombinedData[] = Object.values(combinedData);

  //  console.log("Combined Data:", result);
  //   return result;
  // };

  // Electricity and Injection chart to combine the data and give it back into the chart
  const generateCombinedData = (selectedTimePeriod: any): CombinedData[] => {
    // Filter and sort electricity consumption data
    const filteredElectricityData = filterDataByTimePeriod(
      sortByDate(userConsumptionElectricity),
      selectedTimePeriod,
    );

    // Filter and sort injection consumption data
    const filteredInjectionData = filterDataByTimePeriod(
      sortByDate(userConsumptionInjection),
      selectedTimePeriod,
    );

    // Combine and aggregate consumption data per date
    const combinedData: Record<string, CombinedData> = {};

    // Aggregate electricity consumption data
    filteredElectricityData.forEach(({ date, consumption }) => {
      // Convert date to a Date object
      const dateObject = new Date(date);
      // Round the date to remove time components
      const roundedDate = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
      );

      if (!combinedData[roundedDate.getTime()]) {
        combinedData[roundedDate.getTime()] = {
          date: roundedDate,
          electricity: 0,
          injection: 0,
        }; // Initialize properties
      }
      // Ensure consumption is treated as a number
      combinedData[roundedDate.getTime()].electricity = parseFloat(
        consumption as any,
      );
    });

    // Aggregate injection consumption data
    filteredInjectionData.forEach(({ date, consumption }) => {
      // Convert date to a Date object
      const dateObject = new Date(date);
      // Round the date to remove time components
      const roundedDate = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
      );

      if (!combinedData[roundedDate.getTime()]) {
        combinedData[roundedDate.getTime()] = {
          date: roundedDate,
          electricity: 0,
          injection: 0,
        }; // Initialize properties
      }
      // Ensure consumption is treated as a number
      combinedData[roundedDate.getTime()].injection = parseFloat(
        consumption as any,
      );
    });

    // Convert combinedData object to an array
    const result: CombinedData[] = Object.values(combinedData);

    console.log("Combined Data:", result);
    return result;
  };

  const [toastOpen, setToastOpen] = React.useState(false);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  useEffect(() => {
    if (isModalVisibleFluvius) {
      setToastOpen(true);
    }
  }, [isModalVisibleFluvius]);

  const [electricityReading, setElectricityReading] = useState<string>("");
  const [showWarning, setShowWarning] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const handleElectricityReadingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setElectricityReading(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      if (parseFloat(value) < userConsumptionE) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }, 500); // Delay validation by 500ms

    setTypingTimeout(timeout);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Dashboard</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div
              id="verbruik"
              className="contentContainer"
              style={contentContainerStyles}
            >
              <CenterTitle
                title="Mijn verbruik"
                subtitle="Hier kan u uw verbruik bekijken en aanvullen zodat je altijd op de hoogte bent van je verbruik en besparing."
              ></CenterTitle>

              {/* Fluvius warning
            {isModalVisibleFluvius && (
                <Snackbar
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    open={toastOpen}
                    //autoHideDuration={6000}
                    onClose={handleToastClose}
                >
                  <div className="toast">
                    <div className="toast-content">
                      <div className="toast-left">
                        <img src={fluviusphoto} alt="Fluvius" className='toast-image'/>
                      </div>
                      <div className="toast-right">
                        <h2>Nauwkeurige metingen met Fluvius</h2>
                        <p>Dankzij de geavanceerde meettechnologieën van Fluvius kunnen we nu het energieverbruik op een
                          uiterst nauwkeurige wijze monitoren en beheren, waardoor we een efficiënter gebruik van onze
                          energie kunnen garanderen.</p>
                        <button className='buttonstylePopUp' onClick={handleFluviusConnect}>Verbinden met Fluvius
                        </button>
                      </div>
                    </div>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleToastClose}
                    >
                      <CloseIcon fontSize="small"/>
                    </IconButton>
                  </div>
                </Snackbar>
            )}
             */}
              {/*<Button onClick={handleToastClick}>Open Snackbar</Button>*/}
              <SwitchTextTrack
                checked={isFluviusConnected}
                offText="manueel"
                onText="fluvius"
                onClick={handleFluvius}
              />
              {!isFluviusConnected && (
                <div
                  className="dashboardContainer"
                  style={dashboardContainerStyles}
                >
                  <div style={chartContainerStyles}>
                    <div className="square" style={chartStyles}>
                      <div style={iconContainerStyles}>
                        <BsFillLightningChargeFill size={40} color="white" />
                      </div>
                      <p>Uw elektriciteitsverbruik: </p>
                      <select
                        value={selectedTimePeriod}
                        onChange={(event) =>
                          setSelectedTimePeriod(event.target.value)
                        }
                      >
                        <option value="elk uur">Elk uur</option>
                        <option value="elke 3 uur">Elke 3 uur</option>
                        <option value="elke 6 uur">Elke 6 uur</option>
                        <option value="elke 12 uur">Elke 12 uur</option>
                        <option value="dagelijks">Dagelijks</option>
                        <option value="Wekelijks">Wekelijks</option>
                        <option value="maandelijks">Maandelijks</option>
                        <option value="jaarlijks">Jaarlijks</option>
                      </select>

                      {/* {userConsumptionElectricity.length === 0 ? (
      <p>No data available</p>
    ) : (
      <ResponsiveContainer width={screenWidth > 500 ? 700 : 280}>
        <AreaChart
          data={filterDataByTimePeriod(sortByDate(userConsumptionElectricity), selectedTimePeriod)}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
            <defs>
    <linearGradient id="colorUv" x1="1" y1="0" x2="0" y2="0">
      <stop offset="30%" stopColor="#269B66" stopOpacity={0.8}/>
      <stop offset="100%" stopColor="#87BD45" stopOpacity={0.2}/>
    </linearGradient>
  </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                  
                        <XAxis
                          dataKey="date"
                          domain={['dataMin', 'dataMax + 45']}
                          scale="time"
                          // tickFormatter={(timestamp) =>
                          //   new Intl.DateTimeFormat('nl-be', {
                          //     year: 'numeric',
                          //     month: 'numeric',
                          //     day: 'numeric',
                          //   }).format(new Date(timestamp))
                          tickFormatter={(timestamp) =>
                            new Intl.DateTimeFormat('nl-be', {
                              day: 'numeric',
                              month: 'numeric',
                              year: '2-digit', // Use '2-digit' to get a two-digit year
                            }).format(new Date(timestamp))
                          }
                        />
                    
                         <YAxis domain={[0, Math.ceil(maxConsumption) + 45]} />


                       /*</div> <Tooltip
                          labelFormatter={(label) =>
                            new Intl.DateTimeFormat('nl-be', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }).format(new Date(label))
                            
                          }
                         
                        />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="consumption"
                          stroke="#87BD45"
                          fill="url(#colorUv)"
                          fillOpacity={1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                    )}
<div className="button-container">
  {userMeterE.length > 0 ? (
    <>
      <button className='buttonstyle' onClick={handleModalToggle}>
        Meterstanden toevoegen
      </button>
      <button className='buttonstyleR' onClick={handleModalToggleE}>
        Bekijk meterstanden Electriciteit
      </button>
      <button className='buttonstyleR' onClick={handleModalToggleI}>
        Bekijk meterstanden Injectie
      </button>
    </>
  ) : (
    <button className='buttonstyle' onClick={handleModalToggleInitial}>
      Initiele meterstand toevoegen
    </button>
  )}
</div> */}

                      {userConsumptionElectricity.length === 0 ? (
                        <p>No data available</p>
                      ) : (
                        <ResponsiveContainer
                          width={screenWidth > 500 ? 700 : 280}
                        >
                          <AreaChart
                            data={generateCombinedData(selectedTimePeriod)}
                            // data={filterDataByTimePeriod(sortByDate(userConsumptionElectricity), selectedTimePeriod)}
                            margin={{
                              top: 10,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient
                                id="colorUv"
                                x1="1"
                                y1="0"
                                x2="0"
                                y2="0"
                              >
                                <stop
                                  offset="30%"
                                  stopColor="#C62828"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#FF6F6F"
                                  stopOpacity={0.2}
                                />
                              </linearGradient>

                              <linearGradient
                                id="colorInjection"
                                x1="1"
                                y1="0"
                                x2="0"
                                y2="0"
                              >
                                <stop
                                  offset="30%"
                                  stopColor="#269B66"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#87BD45"
                                  stopOpacity={0.2}
                                />
                              </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis
                              // dataKey="date"
                              dataKey={(data) => data.date}
                              domain={["dataMin", "dataMax + 45"]}
                              scale="time"
                              tickFormatter={(timestamp) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "2-digit",
                                }).format(new Date(timestamp))
                              }
                            />
                            {/* <YAxis domain={[0, 'dataMax + 500']} /> */}
                            <YAxis
                              domain={[0, Math.ceil(maxConsumption) + 45]}
                            />

                            {/* <YAxis  /> */}
                            <Tooltip
                              labelFormatter={(label) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(label))
                              }
                            />
                            <Legend />
                            <Area
                              type="monotone"
                              dataKey="electricity"
                              stroke="#C62828"
                              fill="url(#colorUv)"
                              fillOpacity={1}
                              name="Electriciteitsverbruik"
                              //   data={generateCombinedData(selectedTimePeriod)}
                            />
                            <Area
                              type="monotone"
                              dataKey="injection"
                              stroke="#2E7D32"
                              fill="url(#colorInjection)"
                              fillOpacity={1}
                              name="Injectieverbruik"
                              // data={generateCombinedData(selectedTimePeriod)}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                      <div className="button-container">
                        {userMeterE.length > 0 ? (
                          <>
                            <button
                              className="buttonstyle"
                              onClick={handleModalToggle}
                            >
                              Meterstanden toevoegen
                            </button>
                            <button
                              className="buttonstyleR"
                              onClick={handleModalToggleE}
                            >
                              Bekijk meterstanden Electriciteit
                            </button>
                            <button
                              className="buttonstyleR"
                              onClick={handleModalToggleI}
                            >
                              Bekijk meterstanden Injectie
                            </button>
                          </>
                        ) : (
                          <button
                            className="buttonstyle"
                            onClick={handleModalToggleInitial}
                          >
                            Initiele meterstand toevoegen
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={chartContainerStyles}>
                    <div className="square" style={chartStyles}>
                      <div style={iconContainerStyles}>
                        <IoFlame size={40} color="white" />
                      </div>
                      <p>Uw gas verbruik: </p>
                      {/* <select
  value={selectedTimePeriodG}
  onChange={event => setSelectedTimePeriodG(event.target.value)}
>
  <option value="dagelijks">Dagelijks</option>
  <option value="wekelijks">Wekelijks</option>
  <option value="maandelijks">Maandelijks</option>
  <option value="jaarlijks">Jaarlijks</option>
</select> */}
                      {userConsumptionGas.length === 0 ? (
                        <p>No data available</p>
                      ) : (
                        <ResponsiveContainer
                          width={screenWidth > 500 ? 700 : 280}
                        >
                          <AreaChart
                            data={filterDataByTimePeriodG(
                              sortByDateG(userConsumptionGas),
                              selectedTimePeriod,
                            )}
                            margin={{
                              top: 10,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient
                                id="colorGv"
                                x1="1"
                                y1="0"
                                x2="0"
                                y2="0"
                              >
                                <stop
                                  offset="30%"
                                  stopColor="#269B66"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#87BD45"
                                  stopOpacity={0.2}
                                />
                              </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              domain={["dataMin", "dataMax"]}
                              scale="time"
                              // tickFormatter={(timestamp) =>
                              //   new Intl.DateTimeFormat('nl-be', {
                              //     year: 'numeric',
                              //     month: 'numeric',
                              //     day: 'numeric',
                              //   }).format(new Date(timestamp))
                              tickFormatter={(timestamp) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "2-digit", // Use '2-digit' to get a two-digit year
                                }).format(new Date(timestamp))
                              }
                            />
                            <YAxis
                              domain={[0, Math.ceil(maxConsumptionGas) + 45]}
                            />
                            {/* <YAxis /> */}
                            <Tooltip
                              labelFormatter={(label) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(label))
                              }
                            />
                            <Legend />
                            <Area
                              type="monotone"
                              dataKey="consumption"
                              stroke="#8dc63f"
                              fill="url(#colorGv)"
                              fillOpacity={1}
                              name="Gasverbruik"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                      <div className="button-container">
                        {userMeterG.length > 0 ? (
                          <>
                            <button
                              className="buttonstyle"
                              onClick={handleModalToggle}
                            >
                              Meterstand toevoegen
                            </button>
                            <button
                              className="buttonstyleR"
                              onClick={handleModalToggleG}
                            >
                              Bekijk meterstanden
                            </button>
                          </>
                        ) : (
                          <button
                            className="buttonstyle"
                            onClick={handleModalToggleInitial}
                          >
                            Initiele meterstand toevoegen
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isFluviusConnected && (
                <div
                  className="dashboardContainer"
                  style={dashboardContainerCouStyles}
                >
                  {totalElectricityDetailsPrice !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <GiPylon
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>
                            Elektriciteitsverbruik in € (schatting)
                          </p>
                          <h2 className="counter" style={counterStyles}>
                            €{" "}
                            {totalElectricityDetailsPrice.toLocaleString(
                              "nl-NL",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalElectricityConsumption !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <BsFillLightningChargeFill
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>Jaarlijks verbruik dag</p>
                          <h2 className="counter" style={counterStyles}>
                            {Math.round(totalElectricityConsumption)} kWh
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalGasDetailsPrice !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IoFlame
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>
                            Gasverbruik in € (schatting)
                          </p>
                          <h2 className="counter" style={counterStyles}>
                            €{" "}
                            {totalGasDetailsPrice.toLocaleString("nl-NL", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalGasConsumption !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IoFlame
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>
                            Gas Jaarlijks verbruik dag
                          </p>
                          <h2 className="counter" style={counterStyles}>
                            {Math.round(totalGasConsumption)} kWh
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                </div>
              )}
              {isFluviusConnected && (
                <div
                  className="dashboardContainer"
                  style={dashboardContainerStyles}
                >
                  <div style={chartContainerStyles}>
                    <div className="square" style={chartStyles}>
                      <div style={iconContainerStyles}>
                        <BsFillLightningChargeFill size={40} color="white" />
                      </div>
                      <p>Uw elektriciteitsverbruik (Fluvius): </p>
                      <select
                        value={selectedTimePeriod}
                        onChange={(event) =>
                          setSelectedTimePeriod(event.target.value)
                        }
                      >
                        {/* <option value="dagelijks">Dagelijks</option> */}
                        <option value="elk uur">Elk uur</option>
                        <option value="elke 3 uur">Elke 3 uur</option>
                        <option value="elke 6 uur">Elke 6 uur</option>
                        <option value="elke 12 uur">Elke 12 uur</option>
                        <option value="Wekelijks">Wekelijks</option>
                        <option value="maandelijks">Maandelijks</option>
                        <option value="jaarlijks">Jaarlijks</option>
                      </select>

                      {userConsumptionElectricityFluvius.length === 0 ? (
                        <div>
                          {/* <p>No data available</p> */}
                          <div className="button-container">
                            <button
                              className="buttonstyle"
                              onClick={handleFluviusConnect}
                            >
                              Connecteer met Fluvius
                            </button>
                          </div>
                        </div>
                      ) : (
                        <ResponsiveContainer
                          width={screenWidth > 500 ? 700 : 280}
                        >
                          <AreaChart
                            data={filterDataByTimePeriod(
                              sortByDate(userConsumptionElectricityFluvius),
                              selectedTimePeriod,
                            )}
                            margin={{
                              top: 10,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient
                                id="colorPv"
                                x1="1"
                                y1="0"
                                x2="0"
                                y2="0"
                              >
                                <stop
                                  offset="30%"
                                  stopColor="#269B66"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#87BD45"
                                  stopOpacity={0.2}
                                />
                              </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              domain={["dataMin", "dataMax + 45"]}
                              scale="time"
                              tickFormatter={(timestamp) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(timestamp))
                              }
                            />
                            <YAxis domain={[0, "dataMax + 45"]} />
                            {/* <YAxis  /> */}
                            <Tooltip
                              labelFormatter={(label) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(label))
                              }
                            />
                            <Legend />
                            <Area
                              type="monotone"
                              dataKey="consumption"
                              stroke="#8dc63f"
                              fill="url(#colorPv)"
                              fillOpacity={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                      {/* <div className="button-container">
  {userMeterE.length > 0 && userMeterG.length > 0 && (
    <>
      <button className='buttonstyle' onClick={handleModalToggle}>
        Meterstand toevoegen
      </button>
      <button className='buttonstyleR' onClick={handleModalToggleE}>
        Bekijk meterstanden
      </button>
    </>
  ) }
</div> */}
                    </div>
                  </div>
                  <div style={chartContainerStyles}>
                    <div className="square" style={chartStyles}>
                      <div style={iconContainerStyles}>
                        <IoFlame size={40} color="white" />
                      </div>
                      <p>Uw gas verbruik (Fluvius): </p>
                      {/* <select
  value={selectedTimePeriod}
  onChange={event => setSelectedTimePeriodG(event.target.value)}
>
 
  <option value="wekelijks">Wekelijks</option>
  <option value="maandelijks">Maandelijks</option>
  <option value="jaarlijks">Jaarlijks</option>
</select> */}
                      {userConsumptionGasFluvius.length === 0 ? (
                        <div>
                          {/* <p>No data available</p> */}
                          <div className="button-container">
                            <button
                              className="buttonstyle"
                              onClick={handleFluviusConnect}
                            >
                              Connecteer met Fluvius
                            </button>
                          </div>
                        </div>
                      ) : (
                        <ResponsiveContainer
                          width={screenWidth > 500 ? 700 : 280}
                        >
                          <AreaChart
                            data={filterDataByTimePeriodG(
                              sortByDateG(userConsumptionGasFluvius),
                              selectedTimePeriod,
                            )}
                            margin={{
                              top: 10,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              domain={["dataMin", "dataMax"]}
                              scale="time"
                              tickFormatter={(timestamp) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(timestamp))
                              }
                            />
                            <YAxis domain={[0, "dataMax + 45"]} />
                            {/* <YAxis /> */}
                            <Tooltip
                              labelFormatter={(label) =>
                                new Intl.DateTimeFormat("nl-be", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(label))
                              }
                            />
                            <Legend />
                            <Area
                              type="monotone"
                              dataKey="consumption"
                              stroke="#8dc63f"
                              fill="#8dc63f"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                      {/* <div className="button-container">
  {userMeterE.length > 0 && userMeterG.length > 0 ? (
    <>
      <button className='buttonstyle' onClick={handleModalToggle}>
        Meterstand toevoegen
      </button>
      <button className='buttonstyleR' onClick={handleModalToggleG}>
        Bekijk meterstanden
      </button>
    </>
  ) : (
    <button className='buttonstyle' onClick={handleModalToggleInitial}>
      Initiele meterstand toevoegen
    </button>
  )}
</div> */}
                    </div>
                  </div>
                </div>
              )}
              {isFluviusConnected && (
                <div
                  className="dashboardContainer"
                  style={dashboardContainerCouStyles}
                >
                  {totalElectricityDetailsPriceFluvius !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <GiPylon
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>
                            Elektriciteitsverbruik in €
                          </p>
                          <h2 className="counter" style={counterStyles}>
                            €{" "}
                            {totalElectricityDetailsPriceFluvius.toLocaleString(
                              "nl-NL",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalElectricityConsumptionFluvius !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <BsFillLightningChargeFill
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>Jaarlijks verbruik dag</p>
                          <h2 className="counter" style={counterStyles}>
                            {totalElectricityConsumptionFluvius} kWh
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalGasDetailsPriceFluvius !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IoFlame
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>Gasverbruik in €</p>
                          <h2 className="counter" style={counterStyles}>
                            €{" "}
                            {totalGasDetailsPriceFluvius.toLocaleString(
                              "nl-NL",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                  {totalGasConsumptionFluvius !== 0 && (
                    <div className="square" style={squareCouStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IoFlame
                          style={{
                            fontSize: "40px",
                            marginRight: "20px",
                            color: "#ffffff",
                            backgroundColor: "#8dc63f",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "-140px",
                          }}
                        />
                        <div>
                          <p style={{ margin: "0" }}>
                            GasJaarlijks verbruik dag
                          </p>
                          <h2 className="counter" style={counterStyles}>
                            {totalGasConsumptionFluvius} kWh
                          </h2>
                        </div>
                      </div>
                      <div style={bottomBorderStyles}></div>
                    </div>
                  )}
                </div>
              )}

              {/* <SectionHeroColoredSmall subText=''>Beheer</SectionHeroColoredSmall> */}
              <CenterTitle
                title="Quick links"
                subtitle="De 4  populairste links om nog sneller te navigeren."
              ></CenterTitle>

              {/* Doorverwijzingen */}
              <div
                className="dashboardContainer"
                style={dashboardContainerCouStyles}
              ></div>
              <div
                className="dashboardContainer"
                style={dashboardContainerCouStyles}
              >
                <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="squareNavTextStyles">Technieken</h3>
                      {/* <p className="squareNavParagraphsStyles">Beheer al uw diensten op één plek</p> */}
                      <button
                        className="buttonWithArrow"
                        onClick={() => navigateToMyDiensten()}
                      >
                        Klik hier
                      </button>
                    </div>
                  </div>
                </div>
                <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="squareNavTextStyles">Energie nieuws</h3>

                      <button
                        className="buttonWithArrow"
                        onClick={() => navigateToMyNews()}
                      >
                        Klik hier
                      </button>
                    </div>
                  </div>
                </div>

                <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="squareNavTextStyles">Tips & Advies</h3>

                      <button
                        className="buttonWithArrow"
                        onClick={() => navigateToMyBeurs()}
                      >
                        Klik hier
                      </button>
                    </div>
                  </div>
                </div>

                <div className="squareNavStyles">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h3 className="squareNavTextStyles">
                        Energievergelijker
                      </h3>

                      <button
                        className="buttonWithArrow"
                        onClick={() => navigateToMyEnergieVergelijken()}
                      >
                        Klik hier
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {isModalVisible && (
                <div style={modalOverlayStyles} onClick={handleModalToggle}>
                  <div style={modalContainerStyles}>
                    <div
                      style={modalStyles}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        style={closeButtonStyles}
                        onClick={handleModalToggle}
                      >
                        X
                      </button>

                      <div style={modalContentStyles}>
                        <h2 style={modalTitleStyles}>Meterstanden</h2>
                        <label>
                          <b>Elektriciteit</b>
                        </label>
                        {userConsumptionE !== null && (
                          <label>
                            Vorige meterstand: {userConsumptionE.toString()}{" "}
                          </label>
                        )}

                        {/* <input type="text" id="electricityReading" placeholder="Electricity Reading" /> */}
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Elektriciteit meterstand"
                              variant="standard"
                              type="number"
                              id="electricityReading"
                              value={electricityReading}
                              onChange={handleElectricityReadingChange}
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="electricitydatetime"
                              value={formatDate(selectedDate1)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate1(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <label>
                          <b>Injectie</b>
                        </label>
                        {userConsumptionI !== null && (
                          <label>
                            Vorige meterstand: {userConsumptionI.toString()}{" "}
                          </label>
                        )}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Injectie meterstand"
                              variant="standard"
                              type="number"
                              id="injectionReading"
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="injectiondatetime"
                              value={formatDate(selectedDate3)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate3(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <label>
                          <b>Gasmeter</b>
                        </label>
                        {userconsumptionG !== null && (
                          <label>
                            Vorige meterstand: {userconsumptionG.toString()}{" "}
                          </label>
                        )}
                        {/* <input type="text" id="gasReading" placeholder="Gas Reading" /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Gas meterstand"
                              variant="standard"
                              type="number"
                              id="gasReading"
                            />
                          </Box>
                        </div>

                        {/* <DatePicker
    selected={selectedDate2} // Use a state variable to hold the selected date
    // onChange={(date) => setSelectedDate2(date || new Date())} 
    onChange={(date) => setSelectedDate2(date || new Date())} 
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="time"
    dateFormat="MMMM d, yyyy h:mm aa"
    className="date-picker"
  />
   */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="gasdatetime"
                              value={formatDate(selectedDate2)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate2(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <button
                          className="buttonstyle"
                          onClick={() => {
                            const electricityReading = parseFloat(
                              (
                                document.querySelector(
                                  "#electricityReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            const gasReading = parseFloat(
                              (
                                document.querySelector(
                                  "#gasReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            const injectionReading = parseFloat(
                              (
                                document.querySelector(
                                  "#injectionReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            createElectricityAndGasMeterReadings(
                              isNaN(electricityReading)
                                ? null
                                : electricityReading,
                              isNaN(gasReading) ? null : gasReading,
                              isNaN(injectionReading) ? null : injectionReading,
                            );
                          }}
                        >
                          Toevoegen
                        </button>
                        <p
                          style={{
                            color: "red",
                            visibility: showWarning ? "visible" : "hidden",
                          }}
                        >
                          De opgegeven meterstand is lager dan de laatst
                          genoteerde meterstand!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isModalVisibleInitial && (
                <div
                  style={modalOverlayStyles}
                  onClick={handleModalToggleInitial}
                >
                  <div style={modalContainerStyles}>
                    <div
                      style={modalStyles}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        style={closeButtonStyles}
                        onClick={handleModalToggleInitial}
                      >
                        X
                      </button>

                      <div style={modalContentStyles}>
                        <h2 style={modalTitleStyles}>Initiële meterstanden</h2>
                        <label>
                          <b>Elektriciteit </b>
                        </label>
                        <label>
                          Vul de meterstand en datum in vanaf wanneer u
                          informatie wilt beginnen verzamelen.
                        </label>
                        {/* <input type="text" id="electricityReading" placeholder="Electricity Reading" /> */}
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Elektriciteit meterstand"
                              variant="standard"
                              type="number"
                              id="electricityReading"
                            />
                          </Box>
                        </div>

                        {/* <DatePicker
    selected={selectedDate1} // Use a state variable to hold the selected date
    onChange={(date) => setSelectedDate1(date || new Date())} // Update the selected date
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="time"
    dateFormat="MMMM d, yyyy h:mm aa"
    className="date-picker"
  /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="electricitydatetime"
                              value={formatDate(selectedDate1)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate1(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <label>
                          <b>Injectie</b>
                        </label>
                        <label>
                          Vul de meterstand en datum in vanaf wanneer u
                          informatie wilt beginnen verzamelen.
                        </label>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Injectie"
                              variant="standard"
                              type="number"
                              id="injectionReading"
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="injectiondatetime"
                              value={formatDate(selectedDate3)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate3(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <label>
                          <b>Gas</b>
                        </label>
                        <label>
                          Vul de meterstand en datum in vanaf wanneer u
                          informatie wilt beginnen verzamelen.
                        </label>
                        {/* <input type="text" id="gasReading" placeholder="Gas Reading" /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Gas meterstand"
                              variant="standard"
                              type="number"
                              id="gasReading"
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              variant="standard"
                              type="datetime-local"
                              id="gasdatetime"
                              value={formatDate(selectedDate2)} // Display formatted date
                              onChange={(event) =>
                                setSelectedDate2(new Date(event.target.value))
                              }
                            />
                          </Box>
                        </div>

                        <button
                          className="buttonstyle"
                          onClick={() => {
                            const electricityReading = parseFloat(
                              (
                                document.querySelector(
                                  "#electricityReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            const gasReading = parseFloat(
                              (
                                document.querySelector(
                                  "#gasReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            const injectionReading = parseFloat(
                              (
                                document.querySelector(
                                  "#injectionReading",
                                ) as HTMLInputElement
                              )?.value || "0",
                            );
                            console.log(gasReading);
                            createElectricityAndGasMeterReadingsInitial(
                              isNaN(electricityReading)
                                ? null
                                : electricityReading,
                              isNaN(gasReading) ? null : gasReading,
                              isNaN(injectionReading) ? null : injectionReading,
                            );
                          }}
                        >
                          toevoegen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isModalVisibleE === true ? (
                <MeterReadingsPopup
                  userAddressId={userAddressId}
                  onClose={() => handleModalToggleE()}
                />
              ) : null}
              {isModalVisibleG === true ? (
                <MeterReadingsGPopup
                  userAddressId={userAddressId}
                  onClose={() => handleModalToggleG()}
                />
              ) : null}
              {isModalVisibleI === true ? (
                <MeterReadingsIPopup
                  userAddressId={userAddressId}
                  onClose={() => handleModalToggleI()}
                />
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default IndexCustomer;
