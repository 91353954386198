import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { getCookie } from "../../../cookieUtils";
import styles from "./PricesAndAbbos.module.css";

interface Product {
  id: string;
  name: string;
  description: string;
  price: number | string; // Allow price to be a number or a string initially
  currency: string;
  interval: string;
  image: string;
}

const ProductsOverview: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [roleId, setRoleId] = useState<number | null>(null);
  const stripe = useStripe();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
      const productsWithValidPrices = response.data.map((product: Product) => ({
        ...product,
        price: parseFloat(product.price as string), // Ensure price is a number
      }));
      setProducts(productsWithValidPrices);
    } catch (error) {
      console.error("Error retrieving products:", error);
      setFetchError("An error occurred while retrieving products.");
    }
  };

  const handleCheckout = async (product: Product) => {
    try {
      const storedUser = getCookie("user");
      if (!storedUser) {
        alert("User not logged in. Please log in to proceed.");
        return;
      }
      const user = JSON.parse(storedUser);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
        products: [product],
        userId: user.id,
      });

      const { sessionId } = response.data;

      if (stripe) {
        await stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <NavBar toggleMenu={handleToggleMenu} />
      <PageHero>Prijzen en Abonnementen</PageHero>
      <div className={styles.pageContainer}>
        <NavigationCustomer
          isMenuVisible={isMenuVisible}
          toggleMenu={handleToggleMenu}
        />
        <div className={styles.contentContainer}>
          {fetchError ? (
            <div>
              <div>{fetchError}</div>
              <button onClick={fetchProducts}>Retry</button>
            </div>
          ) : !products.length ? (
            <div>Loading products, please wait...</div>
          ) : (
            <div className={styles.productsGrid}>
              {products.map((product) => (
                <div key={product.id} className={styles.productCard}>
                  <img
                    src={product.image}
                    alt={product.name}
                    className={styles.productImage}
                    onError={(e) =>
                      console.error("Error loading image:", e)
                    }
                  />
                  <h2>{product.name}</h2>
                  <p>{product.description}</p>
                  <p>
                    {product.currency}{" "}
                    {typeof product.price === "number"
                      ? product.price.toFixed(2)
                      : "0.00"}{" "}
                    / {product.interval}
                  </p>
                  <button
                    className={styles.button}
                    onClick={() => handleCheckout(product)}
                  >
                    Subscribe
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductsOverview;





// import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/Navigation/NavBar";
// import NavigationCustomer from "../../../components/customer/NavigationCustomer";
// import Footer from "../../../components/Footer/Footer";
// import PageHero from "../../../components/Pagehero/PageHero";
// import axios from "axios";
// import { useStripe } from "@stripe/react-stripe-js";
// import styles from "./PricesAndAbbos.module.css";

// interface Product {
//   id: string;
//   name: string;
//   description: string;
//   price: number | string; // Allow price to be a number or a string initially
//   currency: string;
//   interval: string;
//   image: string;
// }

// const ProductsOverview: React.FC = () => {
//   const [isMenuVisible, setMenuVisible] = useState(false);
//   const [products, setProducts] = useState<Product[]>([]);
//   const [fetchError, setFetchError] = useState<string | null>(null);
//   const stripe = useStripe();

//   const handleToggleMenu = () => {
//     setMenuVisible(!isMenuVisible);
//   };

//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_DB_URL}/products`);
//       const productsWithValidPrices = response.data.map((product: Product) => ({
//         ...product,
//         price: parseFloat(product.price as string), // Ensure price is a number
//       }));
//       setProducts(productsWithValidPrices);
//     } catch (error) {
//       console.error("Error retrieving products:", error);
//       setFetchError("An error occurred while retrieving products.");
//     }
//   };

//   const handleCheckout = async (product: Product) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
//         products: [product],
//       });

//       const { id } = response.data;

//       if (stripe) {
//         await stripe.redirectToCheckout({ sessionId: id });
//       }
//     } catch (error) {
//       console.error("Error redirecting to checkout:", error);
//       alert("Failed to initiate payment. Please try again.");
//     }
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   return (
//     <>
//       <NavBar toggleMenu={handleToggleMenu} />
//       <PageHero>Prijzen en Abonnementen</PageHero>
//       <div className={styles.pageContainer}>
//         <NavigationCustomer
//           isMenuVisible={isMenuVisible}
//           toggleMenu={handleToggleMenu}
//         />
//         <div className={styles.contentContainer}>
//           {fetchError ? (
//             <div>
//               <div>{fetchError}</div>
//               <button onClick={fetchProducts}>Retry</button>
//             </div>
//           ) : !products.length ? (
//             <div>Loading products, please wait...</div>
//           ) : (
//             <div className={styles.productsGrid}>
//               {products.map((product) => (
//                 <div key={product.id} className={styles.productCard}>
//                   <img
//                     src={product.image}
//                     alt={product.name}
//                     className={styles.productImage}
//                     onError={(e) =>
//                       console.error("Error loading image:", e)
//                     }
//                   />
//                   <h2>{product.name}</h2>
//                   <p>{product.description}</p>
//                   <p>
//                     {product.currency}{" "}
//                     {typeof product.price === "number"
//                       ? product.price.toFixed(2)
//                       : "0.00"}{" "}
//                     / {product.interval}
//                   </p>
//                   <button
//                     className={styles.button}
//                     onClick={() => handleCheckout(product)}
//                   >
//                     Subscribe
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ProductsOverview;




