import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import axios from "axios";
import ContractDetailsPopup from "./ChangeEnergyContractCustomer/ContractDetailsPopup/ContractDetailsPopup";
import { useNavigate } from "react-router";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import styles from "./ContractOverview.module.css";
import CenterTitle from "../../../components/CenterTitle/CenterTitle";
import { getCookie, setCookie } from "../../../cookieUtils";
import ScoreStars from "../../../components/ScoreStars/ScoreStars";
import {
  Contract,
  ContractCombiDetails,
  ContractElectricityDetails,
  ContractGasDetails,
  EnergySupplier,
  NetCostsZipCodeElectricity,
  NetCostsZipCodeGas,
} from "../../../interfaces/interfaces";
import { useParams } from "react-router-dom";
import ContractDetailsPopupGas from "./ChangeEnergyContractCustomer/ContractDetailsPopup/ContractDetailsPopupGas";
import ContractDetailsPopupCombi from "./ChangeEnergyContractCustomer/ContractDetailsPopup/ContractDetailsPopupCombi";

const ContractOverview = () => {
  const { energySource, tarif, isPro } = useParams();

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [contractElectricityDetails, setContractElectricityDetails] = useState<
    ContractElectricityDetails[]
  >([]);
  const [contractGasDetails, setContractGasDetails] = useState<
    ContractGasDetails[]
  >([]);
  const [contractCombiDetails, setContractCombiDetails] = useState<
    ContractCombiDetails[]
  >([]);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] =
    useState<NetCostsZipCodeElectricity | null>(null);
  const [netCostsZipCodeGas, setNetCostsZipCodeGas] =
    useState<NetCostsZipCodeGas | null>(null);
  const [selectedContractId, setSelectedContractId] = useState<string | null>(
    null,
  );
  const [roleId, setRoleId] = useState<number | null>(null);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const navigate = useNavigate();

  const excludedSupplierId = getCookie("supplierId");
  const excludedVerbruikValue = getCookie("verbruikValue");
  const excludedVerbruikGasValue = getCookie("verbruikGasValue");
  const isCheckedAlt = getCookie("isCheckedAlt");
  const excludedNettoAfnameDag = getCookie("nettoAfnameDag");
  const excludedNettoAfnameWeekend = getCookie("nettoAfnameWeekend");
  const excludedNettoAfnameUitNacht = getCookie("nettoAfnameUitNacht");
  const excludedOmvormersVermogen = getCookie("omvormersVermogen");
  const hasDigitalMeter = getCookie("hasDigitalMeter");
  const hasSolarPanels = getCookie("hasSolarPanels");
  const zipcode = getCookie("postcode");

  const setContractTypeCookie = () => {
    let contractType = "";
    switch (energySource) {
      case "elektriciteit":
        contractType += "E";
        break;
      case "gas":
        contractType += "G";
        break;
      case "combi":
        contractType += "C";
        break;
    }
    if (isPro === "professioneel") {
      contractType += "P";
    }
    setCookie("contractType", contractType || "defaultEnergySource", 2);
  };

  const verbruikValue = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;
  const verbruikGasValue = excludedVerbruikGasValue
    ? parseFloat(excludedVerbruikGasValue)
    : 0;
  const nettoAfnameDag = excludedNettoAfnameDag
    ? parseFloat(excludedNettoAfnameDag)
    : 0;
  const nettoAfnameWeekend = excludedNettoAfnameWeekend
    ? parseFloat(excludedNettoAfnameWeekend)
    : 0;
  const nettoAfnameUitNacht = excludedNettoAfnameUitNacht
    ? parseFloat(excludedNettoAfnameUitNacht)
    : 0;
  const omvormersVermogen = excludedOmvormersVermogen
    ? parseFloat(excludedOmvormersVermogen)
    : 0;

  const fetchContracts = async () => {
    let path = "";
    switch (energySource) {
      case "elektriciteit":
        path += "E";
        break;
      case "gas":
        path += "G";
        break;
      case "combi":
        path += "C";
        break;
    }
    switch (tarif) {
      case "vast":
        path += "Vast";
        break;
      case "variabel":
        path += "Variabel";
        break;
      case "multi":
        path += "VV";
        break;
    }
    if (isPro === "professioneel") {
      path = "P" + path;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractsSorted${path}`,
        {
          params: {
            verbruikValue: excludedVerbruikValue,
            nettoAfnameDag: excludedNettoAfnameDag,
            nettoAfnameWeekend: excludedNettoAfnameWeekend,
            nettoAfnameUitNacht: excludedNettoAfnameUitNacht,
          },
        },
      );
      const fetchedContracts: Contract[] = response.data;
      const filteredContracts = fetchedContracts.filter(
        (contract) => contract.supplierId !== excludedSupplierId,
      );

      setContracts(filteredContracts);
    } catch (error) {
      console.error("Error retrieving contracts:", error);
      setFetchError("Er ging iets mis bij het ophalen van contractgegevens.");
    }
  };

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
    }
  };

  const fetchContractElectricityDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
      );
      setContractElectricityDetails(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
    }
  };

  const fetchContractGasDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractGasDetails`,
      );
      setContractGasDetails(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
    }
  };

  const fetchContractCombiDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/contractCombiDetails`,
      );
      setContractCombiDetails(response.data);
    } catch (error) {
      console.error("Error retrieving energy suppliers:", error);
    }
  };

  const fetchNetCostsZipCodeElectricity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts/${zipcode}`,
      );
      setNetCostsZipCodeElectricity(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
    }
  };

  const fetchNetCostsZipCodeGas = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCostsGas/${zipcode}`,
      );
      setNetCostsZipCodeGas(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
    }
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    setContractTypeCookie();
    fetchContracts();
    fetchEnergySuppliers();
    switch (energySource) {
      case "elektriciteit":
        fetchContractElectricityDetails();
        fetchNetCostsZipCodeElectricity();
        break;
      case "gas":
        fetchContractGasDetails();
        fetchNetCostsZipCodeGas();
        break;
      case "combi":
        fetchContractElectricityDetails();
        fetchContractGasDetails();
        fetchContractCombiDetails();
        fetchNetCostsZipCodeElectricity();
        fetchNetCostsZipCodeGas();
        break;
    }
  }, []);

  const retryFetchContracts = () => {
    setFetchError(null); // Reset the error state before retrying
    fetchContracts();
  };

  const NavigateToDocumentSign = (
    contractId: string,
    documentTemplateid: string,
  ) => {
    setCookie("verandercontract", contractId, 2);
    navigate(`/DocumentFill/${documentTemplateid}`);
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  interface ContractCardProps {
    isPreferred?: boolean;
    supplier: EnergySupplier | undefined;
    contract: Contract;
    totalConsumptionCostPrice: number;
  }

  const ContractCard: React.FC<ContractCardProps> = ({
    isPreferred = false,
    supplier,
    contract,
    totalConsumptionCostPrice,
  }) => {
    return (
      <div
        className={`${styles.square} ${
          isPreferred ? styles.squarePreferred : ""
        }`}
      >
        {isPreferred && (
          <div className={styles.preferredText}>Beste keuze!</div>
        )}
        <img
          src={supplier?.logo}
          alt={supplier?.name}
          style={{ height: "60px", marginRight: "10px" }}
          onError={(e) => {
            console.error(
              "Er liep iets mis tijdens het laden van de afbeelding:",
              e,
            );
          }}
        />
        <h2>{contract.name}</h2>
        <p>{contract.description}</p>
        <p>Jaarlijks: €{totalConsumptionCostPrice.toFixed(2)} incl. BTW</p>
        {supplier?.id ? (
          <ScoreStars energySupplierId={supplier.id} />
        ) : (
          <div>Supplier information is not available</div>
        )}
        <button
          className={styles.button}
          onClick={() =>
            NavigateToDocumentSign(contract.id, contract.documentTemplateId)
          }
        >
          Contract Aanvragen
        </button>
        <p onClick={() => setSelectedContractId(contract.contractDetailsId)}>
          <u>Details bekijken</u>
        </p>
      </div>
    );
  };

  const calculateTotalConsumptionCostPriceElectricity = (
    contractElectricityDetail: ContractElectricityDetails | undefined,
  ) => {
    if (
      !netCostsZipCodeElectricity ||
      !contractElectricityDetail ||
      verbruikValue === null ||
      isNaN(verbruikValue)
    ) {
      return 0;
    }

    let baseCost =
      contractElectricityDetail.yearlySubscription +
      netCostsZipCodeElectricity.dataManagementPrice +
      netCostsZipCodeElectricity.purchaseRatePrice * verbruikValue +
      netCostsZipCodeElectricity.taxAccijns * verbruikValue +
      netCostsZipCodeElectricity.contributionEnergy * verbruikValue +
      netCostsZipCodeElectricity.capacityRatePrice +
      netCostsZipCodeElectricity.distributionDayPrice * verbruikValue +
      netCostsZipCodeElectricity.distributionNightPrice * nettoAfnameUitNacht +
      netCostsZipCodeElectricity.ditributionCombiDayPrice * nettoAfnameDag +
      netCostsZipCodeElectricity.ditributionCombiNightPrice *
        nettoAfnameWeekend +
      netCostsZipCodeElectricity.transportCostPrice * verbruikValue +
      netCostsZipCodeElectricity.DNB;

    let consumptionCost = 0;
    switch (isCheckedAlt) {
      case "isChecked1Alt":
        consumptionCost =
          contractElectricityDetail.kwhConsumptionPrice * verbruikValue;
        break;
      case "isChecked2Alt":
        consumptionCost =
          contractElectricityDetail.kwhConsumptionPriceTwoDay * nettoAfnameDag +
          contractElectricityDetail.kwhConsumptionPriceTwoNight *
            nettoAfnameWeekend;
        break;
      case "isChecked3Alt":
        consumptionCost =
          contractElectricityDetail.kwhConsumptionPrice * verbruikValue +
          contractElectricityDetail.kwhConsumptionPriceNight *
            nettoAfnameUitNacht;
        break;
      case "isChecked4Alt":
        consumptionCost =
          contractElectricityDetail.kwhConsumptionPriceTwoDay * nettoAfnameDag +
          contractElectricityDetail.kwhConsumptionPriceTwoNight *
            nettoAfnameWeekend +
          contractElectricityDetail.kwhConsumptionPriceNight *
            nettoAfnameUitNacht;
        break;
      default:
        throw new Error("Invalid selection for isCheckedAlt.");
    }

    if (hasSolarPanels === "JA") {
      if (hasDigitalMeter === "Ja") {
        // No additional cost is added, consumption cost calculated as above
      } else {
        // Add costs for prosumers without a digital meter
        consumptionCost +=
          netCostsZipCodeElectricity.prosumentPrice * omvormersVermogen;
      }
    }

    // Total cost is the sum of base cost and consumption cost
    return baseCost + consumptionCost;
  };

  const calculateTotalConsumptionCostPriceGas = (
    contractGasDetail: ContractGasDetails | undefined,
  ) => {
    if (
      !contractGasDetail ||
      verbruikGasValue === null ||
      isNaN(verbruikGasValue) ||
      !netCostsZipCodeGas
    ) {
      return 0;
    }

    const baseCost =
      contractGasDetail.yearlySubscription +
      netCostsZipCodeGas.dataManagementPrice +
      netCostsZipCodeGas.contributionEnergy * verbruikGasValue +
      netCostsZipCodeGas.taxAccijns * verbruikGasValue +
      netCostsZipCodeGas.connectionFee * verbruikGasValue;

    let consumptionCost = 0;
    switch (true) {
      case verbruikGasValue < 5000:
        consumptionCost =
          contractGasDetail.kwhConsumptionPrice * verbruikGasValue +
          netCostsZipCodeGas.distributionSmallFixedPrice +
          verbruikGasValue * netCostsZipCodeGas.distributionSmallVariablePrice +
          netCostsZipCodeGas.transportCostPrice * verbruikGasValue;
        break;

      case verbruikGasValue >= 5000 && verbruikGasValue < 150000:
        consumptionCost =
          contractGasDetail.kwhConsumptionPrice * verbruikGasValue +
          netCostsZipCodeGas.distributionMediumFixedPrice +
          verbruikGasValue *
            netCostsZipCodeGas.distributionMediumVariablePrice +
          netCostsZipCodeGas.transportCostPrice * verbruikGasValue;
        break;

      case verbruikGasValue >= 150000:
        consumptionCost =
          contractGasDetail.kwhConsumptionPrice * verbruikGasValue +
          netCostsZipCodeGas.distributionLargeFixedPrice +
          verbruikGasValue * netCostsZipCodeGas.distributionLargeVariablePrice +
          netCostsZipCodeGas.transportCostPrice * verbruikGasValue;
        break;

      default:
        throw new Error("Invalid verbruikGasValue.");
    }

    return baseCost + consumptionCost;
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          {energySource === "elektriciteit" ? (
            <PageHero>Elektriciteits contracten</PageHero>
          ) : energySource === "gas" ? (
            <PageHero>Gas Contracten</PageHero>
          ) : energySource === "combi" ? (
            <PageHero>Combi Contracten</PageHero>
          ) : (
            ""
          )}

          <div className={styles.pageContainerE}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className={styles.contentContainer}>
              <CenterTitle
                title="Contracten vergelijken"
                subtitle="Hieronder worden alle contracten teruggegeven die interessant zijn in uw situatie. We raden aan alle details te bekijken."
              />

              {
                // Loading state: wait until data is available
                !contracts.length ||
                (energySource === "elektriciteit" &&
                  !netCostsZipCodeElectricity) ||
                (energySource === "gas" && !netCostsZipCodeGas) ||
                (energySource === "combi" &&
                  (!netCostsZipCodeElectricity || !netCostsZipCodeGas)) ? (
                  <div>Loading contracts, please wait...</div>
                ) : fetchError ? (
                  <div>
                    <div>{fetchError}</div>
                    <button onClick={retryFetchContracts}>
                      Probeer opnieuw
                    </button>
                  </div>
                ) : (
                  <div className={styles.squareContainer}>
                    {contracts
                      .map((contract) => {
                        const supplier = energySuppliers.find(
                          (supplier) => supplier.id === contract.supplierId,
                        );

                        let contractElectricityDetail =
                          contractElectricityDetails.find(
                            (detail) => detail.contractId === contract.id,
                          );
                        let contractGasDetail = contractGasDetails.find(
                          (detail) => detail.contractId === contract.id,
                        );
                        const contractCombiDetail = contractCombiDetails.find(
                          (detail) => detail.contractId === contract.id,
                        );

                        if (energySource === "combi") {
                          contractElectricityDetail =
                            contractElectricityDetails.find(
                              (detail) =>
                                detail.id ===
                                contractCombiDetail?.contractElectricityDetailsId,
                            );
                          contractGasDetail = contractGasDetails.find(
                            (detail) =>
                              detail.id ===
                              contractCombiDetail?.contractGasDetailsId,
                          );
                        }

                        let totalConsumptionCostPrice = 0;
                        let totnewElec = 0;
                        let totnewGas = 0;
                        switch (energySource) {
                          case "elektriciteit":
                            totalConsumptionCostPrice =
                              calculateTotalConsumptionCostPriceElectricity(
                                contractElectricityDetail,
                              );
                              totnewElec = totalConsumptionCostPrice / 12;
                               setCookie("totalElectricity", totnewElec.toString(), 2);
                            break;
                          case "gas":
                            totalConsumptionCostPrice =
                              calculateTotalConsumptionCostPriceGas(
                                contractGasDetail,
                              );
                              totnewGas = totalConsumptionCostPrice / 12;
                              setCookie("totalGas", totnewGas.toString(), 2);
                            break;
                          case "combi":
                            totalConsumptionCostPrice =
                              calculateTotalConsumptionCostPriceElectricity(
                                contractElectricityDetail,
                              ) +
                              calculateTotalConsumptionCostPriceGas(
                                contractGasDetail,
                              );
                              totnewElec = totalConsumptionCostPrice / 12;
                              totnewGas = totalConsumptionCostPrice / 12;
                              setCookie("totalElectricity", totnewElec.toString(), 2);
                              setCookie("totalGas", totnewGas.toString(), 2);
                            break;
                        }

                        // Return both contract and its price for sorting later
                        return {
                          contract,
                          supplier,
                          totalConsumptionCostPrice,
                        };
                      })
                      // Sort by total price (ascending)
                      .sort(
                        (a, b) =>
                          a.totalConsumptionCostPrice -
                          b.totalConsumptionCostPrice,
                      )
                      // Render sorted contracts
                      .map(
                        (
                          { contract, supplier, totalConsumptionCostPrice },
                          index,
                        ) => (
                          <ContractCard
                            key={contract.id}
                            isPreferred={index === 0} // The first one will be the preferred contract
                            supplier={supplier}
                            contract={contract}
                            totalConsumptionCostPrice={
                              totalConsumptionCostPrice
                            }
                          />
                        ),
                      )}
                  </div>
                )
              }

              {selectedContractId &&
                (energySource === "elektriciteit" ? (
                  <ContractDetailsPopup
                    contractId={selectedContractId}
                    onClose={() => setSelectedContractId(null)}
                  />
                ) : energySource === "gas" ? (
                  <ContractDetailsPopupGas
                    contractId={selectedContractId}
                    onClose={() => setSelectedContractId(null)}
                  />
                ) : energySource === "combi" ? (
                  <ContractDetailsPopupCombi
                    contractId={selectedContractId}
                    onClose={() => setSelectedContractId(null)}
                  />
                ) : null)}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ContractOverview;
