import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Verbruiksadres.css";
import { useNavigate } from "react-router";
import "./../../theme/styles.css";
import Card from "@mui/material/Card";
import { Divider, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

interface UserAddress {
  id: string;
  name: string;
  userId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  dateTimeCreated: Date;
}

interface VerbruiksadresProps {
  userId: string;
  showDeleteConfirmation: (action: () => void) => void;
}

const Verbruiksadres: React.FC<VerbruiksadresProps> = ({
  userId,
  showDeleteConfirmation,
}) => {
  const [userAddresses, setUserAddresses] = useState<UserAddress[]>([]);
  const [openRows, setOpenRows] = useState<string[]>([]); // Initialize openRows as an empty string array
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserAddresses();
  }, []);

  const fetchUserAddresses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/user-addresses/user/${userId}`,
      );
      setUserAddresses(response.data);
    } catch (error) {
      console.error("Error fetching user addresses:", error);
    }
  };

  const handleRowToggle = (rowId: string) => {
    const isRowOpen = openRows.includes(rowId);
    setOpenRows(
      isRowOpen ? openRows.filter((id) => id !== rowId) : [...openRows, rowId],
    );
  };

  const handleDeleteAddress = async (addressId: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_DB_URL}/user-addresses/${addressId}`,
      );
      // After successful deletion, you can fetch the user addresses again to update the list
      fetchUserAddresses();
    } catch (error) {
      console.error("Error deleting user address:", error);
      // Handle error here
    }
  };

  const handleAddUserAddressCustomer = (userId: any) => {
    // Navigate to add user address page
    navigate(`/AddUserAddressCustomer/${userId}`);
  };

  const handleEditUserAddressCustomer = (userAddressId: any) => {
    navigate(`/EditUserAddressCustomer/${userAddressId}`);
  };

  const confirmDeleteAddress = (addressId: string) => {
    showDeleteConfirmation(() => handleDeleteAddress(addressId));
  };

  return (
    <div>
      {userAddresses.length > 0 ? (
        userAddresses.map((address) => (
          <Card key={address.eane} className="address-card">
            <div className="card-header">
              <h2>{address.name}</h2>
              <div className="edit-buttons">
                <button
                  className="buttonstyleR33Q"
                  onClick={() => handleEditUserAddressCustomer(address.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="buttonstyleR33Q"
                  onClick={() => confirmDeleteAddress(address.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
            <Divider orientation="horizontal" />
            <p>
              <b>Straat:</b> {address.streetname}
            </p>
            <p>
              <b>Huisnummer + busnummer:</b> {address.housenumber}
            </p>
            <p>
              <b>Postcode:</b> {address.zipcode}
            </p>
            <p>
              <b>Stad:</b> {address.city}
            </p>
            <p>
              <b>EAN Elektriciteit:</b> {address.eane}
            </p>
            <p>
              <b>EAN Gas:</b> {address.eang}
            </p>
          </Card>
        ))
      ) : (
        <div>
          <p>
            Er zijn geen verbruiksadressen aangesloten aan dit account. Druk op
            onderstaande knop om een verbruiksadres toe te voegen. <br />
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

export default Verbruiksadres;
