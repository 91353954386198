import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import Checkbox from "@mui/material/Checkbox";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import MenuItem from '@mui/material/MenuItem';
import { Select } from '@mui/material';
import Footer from '../../../../../components/Footer/Footer';
import PageHero from '../../../../../components/Pagehero/PageHero';
import './DocumentFill.css'
import SquareComponentIt from '../../../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt';
import SquareComponent from '../../../../../components/InfoSquares/InfoSquaresSales/InfoSquares';
import './../../../../../theme/styles.css'
import { getCookie, setCookie, eraseCookie } from '../../../../../cookieUtils';
import { values } from "lodash";

interface DocumentTemplate {
  id: string;
  documentPath: string;
  documentName: string;
  status: number;
}

const DocumentFill: React.FC = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentDayString = currentDay.toString();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
  const currentMonthString = currentMonth.toString();
  const currentYear = currentDate.getFullYear();
  const currentYearString = currentYear.toString();

  const contractType = getCookie("contractType");

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [checked, setChecked] = useState(true);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [showDropField, setShowDropField] = useState(false);
  const [documentTemplate, setDocumentTemplate] =
    useState<DocumentTemplate | null>(null);
  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);
  const { documentTemplateId } = useParams<{ documentTemplateId?: string }>(); // Extract the contract ID from the URL
  const [userId, setUserid] = useState("");
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [nutsvoorzieningen, setNutsvoorzieningen] = useState(0);
  const [nutsvoorzieningenJa, setNutsvoorzieningenJa] = useState("");
  const [nutsvoorzieningenNee, setNutsvoorzieningenNee] = useState("X");

  const [elektriciteitsmetergeopend, setElektriciteitsmetergeopend] = useState(1);
  const [elektriciteitsmetergeopendJa, setElektriciteitsmetergeopendJa] = useState("X");
  const [elektriciteitsmetergeopendNee, setElektriciteitsmetergeopendNee] = useState("");

  const [elektriciteitsaansluitingen, setElektriciteitsaansluitingen] = useState(1);
  const [elektriciteitsaansluitingenJa, setElektriciteitsaansluitingenJa] = useState("X");
  const [elektriciteitsaansluitingenNee, setElektriciteitsaansluitingenNee] = useState("");

  const [gasaansluitingen, setGasaansluitingen] = useState(1);
  const [gasaansluitingenJa, setGasaansluitingenJa] = useState("X");
  const [gasaansluitingenNee, setGasaansluitingenNee] = useState("");

  const [gasmetergeopend, setGasmetergeopend] = useState(1);
  const [gasmetergeopendJa, setGasmetergeopendJa] = useState("X");
  const [gasmetergeopendNee, setGasmetergeopendNee] = useState("");

  const [eane, setEane] = useState("54");
  const [eang, setEang] = useState("54");
  const navigate = useNavigate();
  const [identitynumber, setIdentitynumber] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthdatedd, setBirthdatedd] = useState("");
  const [birthdatemm, setBirthdatemm] = useState("");
  const [birthdateyy, setBirthdateyy] = useState("");
  const [dhr, setDhr] = useState("X");
  const [mevr, setMevr] = useState("");
  const [gender, setGender] = useState("");
  const [bus, setBus] = useState("");
  const [telphone, setTelphone] = useState("");
  const [installaddress, setInstalladdress] = useState("");
  const [installnumber, setInstallnumber] = useState("");
  const [installbus, setInstallbus] = useState("");
  const [installfloor, setInstallfloor] = useState("");
  const [installzipcode, setInstallzipcode] = useState("");
  const [installcity, setInstallcity] = useState("");
  const [eba, setEba] = useState("X");
  const [ebn, setEbn] = useState("");
  const [ebo, setEbo] = useState("");
  const [gba, setGba] = useState("X");
  const [gbn, setGbn] = useState("");
  const [gbo, setGbo] = useState("");
  const [leverancier, setLeverancier] = useState("");
  const [netbeheerder, setNetbeheerder] = useState("");
  const [datumoverschakeling, setDatumoverschakeling] = useState("");
  const [datumoverschakelingdd, setDatumoverschakelingdd] = useState("");
  const [datumoverschakelingmm, setDatumoverschakelingmm] = useState("");
  const [datumoverschakelingyy, setDatumoverschakelingyy] = useState("");
  const [verhuis, setVerhuis] = useState(0);
  const [verhuisJa, setVerhuisJa] = useState("");
  const [verhuisNee, setVerhuisNee] = useState("X");

  // Luminus
  const [leverancierg, setLeverancierg] = useState("");
  const [aansluiting, setAansluiting] = useState("");
  const [netbeheerderg, setNetbeheerderg] = useState("");
  const [datumoverschakelingG, setDatumoverschakelingG] = useState("");
  const [datumoverschakelingddg, setDatumoverschakelingddg] = useState("");
  const [datumoverschakelingmmg, setDatumoverschakelingmmg] = useState("");
  const [datumoverschakelingyyg, setDatumoverschakelingyyg] = useState("");
  const [df, setDf] = useState("X");
  const [dom, setDom] = useState("X");
  const [vsa, setVsa] = useState("X");
  const [vse, setVse] = useState("");
  const [ove, setOve] = useState("");
  const [jed, setJed] = useState("");
  const [jen, setJen] = useState("");
  const [jeen, setJeen] = useState("");
  const [sjep, setSjep] = useState("");
  const [mve, setMve] = useState("");
  const [jg, setJg] = useState("");
  const [sjgp, setSjgp] = useState("");
  const [mvg, setMvg] = useState("");
  const [vs, setVs] = useState("X");
  const [vh, setVh] = useState("");
  const [maandelijks, setMaandelijks] = useState("X");
  const [tweemaandelijks, setTweemaandelijks] = useState("");
  const [driemaandelijks, setDriemaandelijks] = useState("");
  const [namesign, setNamesign] = useState("");
  const [surnamesign, setSurnamesign] = useState("");
  const [ddtod, setDdtod] = useState(currentDayString);
  const [mmtod, setMmtod] = useState(currentMonthString);
  const [yytod, setYytod] = useState(currentYearString);
  const [citysign, setCitysign] = useState("");
  // luminus pro
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [vennootschapsvorm, setVennootschapsvorm] = useState("Eenmanszaak");
  const [ondernemingsnummer, setOndernemingsnummer] = useState("BE");
  // Engie
  const [verzendname, setVerzendname] = useState("");
  const [verzendsurname, setVerzendsurname] = useState("");
  const [klantnummer, setKlantnummer] = useState("");
  const [eann, setEann] = useState("54");
  const [ace, setAce] = useState("");
  const [gce, setGce] = useState("X");
  const [neg, setNeg] = useState("X");
  const [vb, setVb] = useState("");
  const [ela, setEla] = useState("X");
  const [bew, setBew] = useState("");
  const [een, setEen] = useState("");
  const [vbg, setVbg] = useState("X");
  const [elag, setElag] = useState("");
  const [bewg, setBewg] = useState("");
  const [eeng, setEeng] = useState("");
  const [voore, setVoore] = useState("");
  const [voorg, setVoorg] = useState("");
  const [pp, setPp] = useState("");
  const [ib, setIb] = useState("");
  const [iban, setIban] = useState("");

  // Engie pro here
  const [RPR, setRPR] = useState("");
  const [nace, setNace] = useState("");
  const [verzendfirmname, setVerzendfirmname] = useState("");
  const [verzendjurform, setVerzendjurform] = useState("");
  const [gpv, setGpv] = useState("");
  const [upv, setUpv] = useState("X");

  // Eneco
  const [ver, setVer] = useState('');
  const [move, setMove] = useState('');
  const [ddver, setDdver] = useState('');
  const [mmver, setMmver] = useState('');
  const [yyver, setYyver] = useState('');
  const [maj, setMaj] = useState('X');
  const [man, setMan] = useState('');
  const [productname, setProductname] = useState('');
  const [productnameg, setProductnameg] = useState('');
  const [cme, setCme] = useState('');
  const [cmedd, setCmedd] = useState('');
  const [cmemm, setCmemm] = useState('');
  const [cmeyy, setCmeyy] = useState('');
  const [oec, setOec] = useState('');
  const [osm, setOsm] = useState('');
  const [cmeg, setCmeg] = useState('');
  const [cmeddg, setCmeddg] = useState('');
  const [cmemmg, setCmemmg] = useState('');
  const [cmeyyg, setCmeyyg] = useState('');
  const [oecg, setOecg] = useState('');
  const [osmg, setOsmg] = useState('');
  const [domgf, setDomgf] = useState('X');
  const [domwf, setDomwf] = useState('');
  const [vmae, setVmae] = useState('');
  const [vmaegf, setVmaegf] = useState('');
  const [vmaewf, setVmaewf] = useState('');
  const [negg, setNegg] = useState('X');

  const [genderSelected, setGenderSelected] = useState(false);
  const [genderError, setGenderError] = useState(false);
  


  useEffect(() => {
    const totalElectricityCookie = getCookie("totalElectricity") ?? "";
    const totalGasCookie = getCookie("totalGas") ?? "";
    const jedCookie = getCookie("jed") ?? ""; 
    const jenCookie = getCookie("jen")?? ""; 
    const jeenCookie = getCookie("jeen") ?? ""; 
    const jgCookie = getCookie("jg") ?? ""; 
    const energieLeverancier = getCookie("energieleverancier") ?? "";
    setLeverancier(energieLeverancier?.toString());
    setLeverancierg(energieLeverancier?.toString());
    if(contractType === "E" || contractType === "C" || contractType === "EP" || contractType === "CP"){
      setJed(jedCookie?.toString());
      setJen(jenCookie?.toString());
      setJeen(jeenCookie?.toString());
      setMve(totalElectricityCookie?.toString());
      setMvg(totalGasCookie?.toString());
      console.log("EXECUTED");
    } if(contractType === "G" || contractType === "C" || contractType === "GP" || contractType === "CP"){
      setJg(jgCookie?.toString());
      setMve(totalElectricityCookie?.toString());
      setMvg(totalGasCookie?.toString());
      console.log("EXECUTED");
    }

    
  }, []);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
  
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
      
      const fetchUserData = async () => {
        try {
          const storedUser = getCookie("user");
          if (storedUser) {
            const user = JSON.parse(storedUser);
            setUserid(user.id);
            setSurname(user.surname);
            setVerzendsurname(user.surname);
            setSurnamesign(user.surname);
            setName(user.name);
            setVerzendname(user.name);
            setNamesign(user.name);
            setEmail(user.email);
            setPhone(user.phone);
            setStreetname(user.streetname);
            setHousenumber(user.housenumber);
            setZipcode(user.zipcode);
            setCity(user.city);
            
            // Log the user data for debugging
            console.log("User data loaded: ", user);
          }
        } catch (error) {
          console.error("Error retrieving user data:", error);
        }
      };
  
      fetchUserData();
    }
  
    fetchDocumentTemplate();
  }, []);
  
  useEffect(() => {
    // Ensure that all user data is available before constructing the URL
    if (documentTemplateId === "191daa35-8613-5bce-aa7d-z8c86p88n100" && name && surname && email && phone && streetname && housenumber && zipcode && city) {
      // Construct the URL with the user data
      const constructedUrl = `https://www.energie.be/nl/klant-worden/contact-info?referer=68626&firstname=${encodeURIComponent(name)}&lastname=${encodeURIComponent(surname)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}&street=${encodeURIComponent(streetname)}&streetNumber=${encodeURIComponent(housenumber)}&postcode=${encodeURIComponent(zipcode)}&City=${encodeURIComponent(city)}`;
      
      // Log the constructed URL for debugging
      console.log("Redirecting to: ", constructedUrl);
  
      // Redirect to the constructed URL
      window.location.href = constructedUrl;
    }
  }, [documentTemplateId, name, surname, email, phone]);
  
  

  useEffect(() => {
    // Calculate the default date (1st of the month after the following month)
    const today = new Date();
    const nextMonth = today.getMonth() + 3; // Following month + 1
    const year = today.getFullYear() + Math.floor(nextMonth / 12);
    const month = nextMonth % 12 || 12; // Correct for month overflow (12 => December)
    const defaultDate = `01/${month.toString().padStart(2, "0")}/${year}`;

    setDatumoverschakeling(defaultDate);
    setDatumoverschakelingG(defaultDate);

    // Set individual day, month, and year values
  if(contractType === 'E' || contractType === 'C' || contractType === 'EP' || contractType === 'CP'){
    setDatumoverschakelingdd("01");
  setDatumoverschakelingmm(month.toString().padStart(2, "0"));
  setDatumoverschakelingyy(year.toString());
}
if(contractType === 'G' || contractType === 'C' || contractType === 'GP' || contractType === 'CP'){
  setDatumoverschakelingddg("01");
  setDatumoverschakelingmmg(month.toString().padStart(2, "0"));
  setDatumoverschakelingyyg(year.toString());
}

  }, []);

  useEffect(() => {
    if (nutsvoorzieningenJa === "X") {
      // Function to get today's date in dd/mm/yyyy format
      const getTodayDate = () => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, "0");
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const year = today.getFullYear().toString(); // Convert year to string
        return { day, month, year, formatted: `${day}/${month}/${year}` };
      };
  
      const today = getTodayDate();
  
      // Set the formatted date
      setDatumoverschakeling(today.formatted);
      setDatumoverschakelingG(today.formatted);
  
      // Update day, month, and year states with strings
      if (contractType === "E" || contractType === "C" || contractType === "EP" || contractType === "CP") {
        setDatumoverschakelingdd(today.day);
        setDatumoverschakelingmm(today.month);
        setDatumoverschakelingyy(today.year); // Year is now a string
      }
  
      if (contractType === "G" || contractType === "C" || contractType === "GP" || contractType === "CP") {
        setDatumoverschakelingddg(today.day);
        setDatumoverschakelingmmg(today.month);
        setDatumoverschakelingyyg(today.year); // Year is now a string
      }
    }
  }, [nutsvoorzieningenJa, contractType]);

  useEffect(() => {
    // Calculate the default date (1st of the month after the following month)
    const today = new Date();
    const nextMonth = today.getMonth() + 3; // Following month + 1
    const year = today.getFullYear() + Math.floor(nextMonth / 12);
    const month = nextMonth % 12 || 12; // Correct for month overflow (12 => December)
    const defaultDate = `01/${month.toString().padStart(2, "0")}/${year}`;

    setDatumoverschakeling(defaultDate);
    setDatumoverschakelingG(defaultDate);

    // Set individual day, month, and year values
  if(contractType === 'E' || contractType === 'C' || contractType === 'EP' || contractType === 'CP'){
    setDatumoverschakelingdd("01");
  setDatumoverschakelingmm(month.toString().padStart(2, "0"));
  setDatumoverschakelingyy(year.toString());
}
if(contractType === 'G' || contractType === 'C' || contractType === 'GP' || contractType === 'CP'){
  setDatumoverschakelingddg("01");
  setDatumoverschakelingmmg(month.toString().padStart(2, "0"));
  setDatumoverschakelingyyg(year.toString());
}

  }, []);

  const fetchDocumentTemplate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/documentTemplates/${documentTemplateId}`,
      );
      const fetchedDocumentTemplate: DocumentTemplate = response.data;
      setDocumentTemplate(fetchedDocumentTemplate);
      console.log(fetchedDocumentTemplate);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        setUserid(user.id);
        setSurname(user.surname);
        setVerzendsurname(user.surname);
        setSurnamesign(user.surname);
        setName(user.name);
        setVerzendname(user.name);
        setNamesign(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setStreetname(user.streetname);
        setHousenumber(user.housenumber);
        setZipcode(user.zipcode);
        setCity(user.city);
      }
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
    setNamesign(event.target.value);
    setVerzendname(event.target.value);

  };

  const handleSurnameChange = (event: any) => {
    setSurname(event.target.value);
    setSurnamesign(event.target.value);
    setVerzendsurname(event.target.value);

  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };
  const handleStreetnameChange = (event: any) => {
    setStreetname(event.target.value);
  };


  const handleNaceChange = (event: any) => {
    setNace(event.target.value);
  };

  const handleVerzendfirmnameChange = (event: any) => {
    setVerzendfirmname(event.target.value);
  };

  const handleVerzendjurformChange = (event: any) => {
    setVerzendjurform(event.target.value);
  };

  const handleHousenumberChange = (event: any) => {
    setHousenumber(event.target.value);
  };

  const handleZipcodeChange = (event: any) => {
    setZipcode(event.target.value);
  };

  const handleCityChange = (event: any) => {
    setCity(event.target.value);
    setCitysign(event.target.value);
  };

  const handleEaneChange = (event: any) => {
    setEane(event.target.value);
  };

  const handleEangChange = (event: any) => {
    setEang(event.target.value);
  };

  const handleIdentityNumberChange = (event: any) => {
    setIdentitynumber(event.target.value);
  };


  const handleSetVerhuisChange = (selectedVerhuis: any) => {
    if (selectedVerhuis === "Ja") {
      setVerhuisJa("X");
      setVerhuisNee("");
      setVerhuis(1);
      setVb("X");
      setEla("");
      setVbg("X");
      setElag("");
      setEba("X");
      setEbn("");
    } else if (selectedVerhuis === "Nee") {
      setVerhuisJa("");
      setVerhuisNee("X");
      setVerhuis(0);
      setVb("");
      setEla("X");
      setVbg("");
      setElag("X");
      setGba("");
      setGbn("X");
    }
  };


  const handleSetNutsvoorzieningenChange = (value: any) => {
    console.log("value", value);
    if (value === "Ja") {
      setNutsvoorzieningenJa("X");
      console.log("setNutsvoorzieningenJa", nutsvoorzieningenJa);
      setNutsvoorzieningenNee("");
      console.log("setNutsvoorzieningenNee", nutsvoorzieningenNee);
      setNutsvoorzieningen(1);
      console.log("setNutsvoorzieningen", nutsvoorzieningen);
    } else if (value === "Nee") {
      setNutsvoorzieningenJa("");
      console.log("setNutsvoorzieningenJa", nutsvoorzieningenJa);
      setNutsvoorzieningenNee("X");
      console.log("setNutsvoorzieningenNee", nutsvoorzieningenNee);
      setNutsvoorzieningen(0);
      console.log("setNutsvoorzieningen", nutsvoorzieningen);
    }
  };
  
  const handleSetelektriciteitsaansluitingChange = (value: any) => {
    console.log("value", value);
    if (value === "Ja") {
      setElektriciteitsaansluitingenJa("X");
      console.log("setElektriciteitsaansluitingenJa", elektriciteitsaansluitingenJa);
      setElektriciteitsaansluitingenNee("");
      console.log("setElektriciteitsaansluitingenNee", elektriciteitsaansluitingenNee);
      setElektriciteitsaansluitingen(1);
      console.log("setElektriciteitsaansluitingen", elektriciteitsaansluitingen);
    } else if (value === "Nee") {
      setElektriciteitsaansluitingenJa("");
      console.log("setElektriciteitsaansluitingenJa", elektriciteitsaansluitingenJa);
      setElektriciteitsaansluitingenNee("X");
      console.log("setElektriciteitsaansluitingenNee", elektriciteitsaansluitingenNee);
      setElektriciteitsaansluitingen(0);
      console.log("setElektriciteitsaansluitingen", elektriciteitsaansluitingen);
    }
  };
  
  const handleSetelektriciteitsmetergeopendChange = (value: any) => {
    console.log("value", value);
    if (value === "Ja") {
      setElektriciteitsmetergeopendJa("X");
      console.log("setElektriciteitsmetergeopendJa", elektriciteitsmetergeopendJa);
      setElektriciteitsmetergeopendNee("");
      console.log("setElektriciteitsmetergeopendNee", elektriciteitsmetergeopendNee);
      setElektriciteitsmetergeopend(1);
      console.log("setElektriciteitsmetergeopend", elektriciteitsmetergeopend);
    } else if (value === "Nee") {
      setElektriciteitsmetergeopendJa("");
      console.log("setElektriciteitsmetergeopendJa", elektriciteitsmetergeopendJa);
      setElektriciteitsmetergeopendNee("X");
      console.log("setElektriciteitsmetergeopendNee", elektriciteitsmetergeopendNee);
      setElektriciteitsmetergeopend(0);
      console.log("setElektriciteitsmetergeopend", elektriciteitsmetergeopend);
    }
  };
  
  const handleSetgasaansluitingChange = (value: any) => {
    console.log("value", value);
    if (value === "Ja") {
      setGasaansluitingenJa("X");
      console.log("setGasaansluitingenJa", gasaansluitingenJa);
      setGasaansluitingenNee("");
      console.log("setGasaansluitingenNee", gasaansluitingenNee);
      setGasaansluitingen(1);
      console.log("setGasaansluitingen", gasaansluitingen);
    } else if (value === "Nee") {
      setGasaansluitingenJa("");
      console.log("setGasaansluitingenJa", gasaansluitingenJa);
      setGasaansluitingenNee("X");
      console.log("setGasaansluitingenNee", gasaansluitingenNee);
      setGasaansluitingen(0);
      console.log("setGasaansluitingen", gasaansluitingen);
    }
  };
  
  const handleSetgasmetergeopendChange = (value: any) => {
    console.log("value", value);
    if (value === "Ja") {
      setGasmetergeopendJa("X");
      console.log("setGasmetergeopendJa", gasmetergeopendJa);
      setGasmetergeopendNee("");
      console.log("setGasmetergeopendNee", gasmetergeopendNee);
      setGasmetergeopend(1);
      console.log("setGasmetergeopend", gasmetergeopend);
    } else if (value === "Nee") {
      setGasmetergeopendJa("");
      console.log("setGasmetergeopendJa", gasmetergeopendJa);
      setGasmetergeopendNee("X");
      console.log("setGasmetergeopendNee", gasmetergeopendNee);
      setGasmetergeopend(0);
      console.log("setGasmetergeopend", gasmetergeopend);
    }
  };


  // const handleSetNutsvoorzieningenChange = (value: any) => {
  //   if (value === "Ja") {
  //     setNutsvoorzieningenJa("X");
  //     setNutsvoorzieningenNee("");
  //     setNutsvoorzieningen(1);

  //   } else if (value === "Nee") {
  //     setNutsvoorzieningenJa("");
  //     setNutsvoorzieningenNee("X");
  //     setNutsvoorzieningen(0);

  //   }
  // };

  // const handleSetelektriciteitsaansluitingChange = (value: any) => {
  //   if (value === "Ja") {
  //     setElektriciteitsaansluitingenJa("X");
  //     setElektriciteitsaansluitingenNee("");
  //     setElektriciteitsaansluitingen(1);

  //   } else if (value === "Nee") {
  //     setElektriciteitsaansluitingenJa("");
  //     setElektriciteitsaansluitingenNee("X");
  //     setElektriciteitsaansluitingen(0);

  //   }
  // };

  // const handleSetelektriciteitsmetergeopendChange = (value: any) => {
  //   if (value === "Ja") {
  //     setElektriciteitsmetergeopendJa("X");
  //     setElektriciteitsmetergeopendNee("");
  //     setElektriciteitsmetergeopend(1);

  //   } else if (value === "Nee") {
  //     setElektriciteitsmetergeopendJa("");
  //     setElektriciteitsmetergeopendNee("X");
  //     setElektriciteitsmetergeopend(0);

  //   }
  // };

  // const handleSetgasaansluitingChange = (value: any) => {
  //   if (value === "Ja") {
  //     setGasaansluitingenJa("X");
  //     setGasaansluitingenNee("");
  //     setGasaansluitingen(1);

  //   } else if (value === "Nee") {
  //     setGasaansluitingenJa("");
  //     setGasaansluitingenNee("X");
  //     setGasaansluitingen(0);

  //   }
  // };


  // const handleSetgasmetergeopendChange = (value: any) => {
  //   if (value === "Ja") {
  //     setGasmetergeopendJa("X");
  //     setGasmetergeopendNee("");
  //     setGasmetergeopend(1);

  //   } else if (value === "Nee") {
  //     setGasmetergeopendJa("");
  //     setGasmetergeopendNee("X");
  //     setGasmetergeopend(0);

  //   }
  // };

  const handleBusChange = (event: any) => {
    setBus(event.target.value);
  };

  const handleTelphoneChange = (event: any) => {
    setTelphone(event.target.value);
  };

  const handleInstallAddressChange = (event: any) => {
    setInstalladdress(event.target.value);
  };

  const handleInstallNumberChange = (event: any) => {
    setInstallnumber(event.target.value);
  };

  const handleInstallBusChange = (event: any) => {
    setInstallbus(event.target.value);
  };

  const handleInstallFloorChange = (event: any) => {
    setInstallfloor(event.target.value);
  };

  const handleInstallZipcodeChange = (event: any) => {
    setInstallzipcode(event.target.value);
  };

  const handleInstallCityChange = (event: any) => {
    setInstallcity(event.target.value);
    setCitysign(event.target.value);
  };

  const handleEbaChange = () => {
    setEba("X");
    setEbn("");
    setEbo("");
  };

  const handleEbnChange = () => {
    setEba("");
    setEbn("X");
    setEbo("");
  };

  const handleEboChange = () => {
    setEba("");
    setEbn("");
    setEbo("X");
  };

  const handleEbaEbnEboChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "eba") {
      handleEbaChange();
    } else if (selectedValue === "ebn") {
      handleEbnChange();
    } else if (selectedValue === "ebo") {
      handleEboChange();
    }
  };

  const handleGbaGbnGboChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "gba") {
      handleGbaChange();
    } else if (selectedValue === "gbn") {
      handleGbnChange();
    } else if (selectedValue === "gbo") {
      handleGboChange();
    }
  };

  const handleMaandChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "maandelijks") {
      setMaandelijks("X");
      setTweemaandelijks("");
      setDriemaandelijks("");
    } else if (selectedValue === "tweemaandelijks") {
      setMaandelijks("");
      setTweemaandelijks("X");
      setDriemaandelijks("");
    } else if (selectedValue === "driemaandelijks") {
      setMaandelijks("");
      setTweemaandelijks("");
      setDriemaandelijks("X");
    }
  };

  const handleCorrespondentieChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "df") {
      setDf("X");
      setPp("");
    } else if (selectedValue === "pp") {
      setDf("");
      setPp("X");
    }
  };

  const handleBetalingChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "ove") {
      setOve("X");
      setDom("");
      setIb("");
    } else if (selectedValue === "dom") {
      setOve("");
      setDom("X");
      setIb("");
    } else if (selectedValue === "ib") {
      setOve("");
      setDom("");
      setIb("X");
    }
  };

  const handleGbaChange = () => {
    setGba("X");
    setGbn("");
    setGbo("");
  };

  const handleGbnChange = () => {
    setGba("");
    setGbn("X");
    setGbo("");
  };

  const handleGboChange = () => {
    setGba("");
    setGbn("");
    setGbo("X");
  };

  const handleLeverancierChange = (event: any) => {
    setLeverancier(event.target.value);
  };
  const handleLeverancierGChange = (event: any) => {
    setLeverancierg(event.target.value);
  };

  const handleNetbeheerderChange = (event: any) => {
    setNetbeheerder(event.target.value);
  };
  const handleNetbeheerderGChange = (event: any) => {
    setNetbeheerderg(event.target.value);
  };



  const handleLeveranciergChange = (event: any) => {
    setLeverancierg(event.target.value);
  };



  const handleDfDomOveChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "df") {
      setDf("X");
      setDom("");
      setOve("");
    } else if (selectedValue === "dom") {
      setDf("");
      setDom("X");
      setOve("");
    } else if (selectedValue === "ove") {
      setDf("");
      setDom("");
      setOve("X");
    }
  };

  const handleDomChange = () => {
    setDom("X");
    setVmae("");
    setOve("");
  };


  const handleVsaVseChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vsa") {
      setVsa("X");
      setVse("");
      setDom("X");
    } else if (selectedValue === "vse") {
      setVsa("");
      setVse("X");
      setDom("X");
    }
  };

  const handleOveChange = () => {
    setDom("");
    setVmae("");
    setOve("X");
  };

  const handleJedChange = (event: any) => {
    setJed(event.target.value);
  };

  const handleJenChange = (event: any) => {
    setJen(event.target.value);
  };

  const handleJeenChange = (event: any) => {
    setJeen(event.target.value);
  };

  const handleSjepChange = (event: any) => {
    setSjep(event.target.value);
  };

  const handleMveChange = (event: any) => {
    setMve(event.target.value);
  };



  const handleJgChange = (event: any) => {
    setJg(event.target.value);
  };

  const handleSjgpChange = (event: any) => {
    setSjgp(event.target.value);
  };

  const handleMvgChange = (event: any) => {
    setMvg(event.target.value);
  };

  const handleVsChange = () => {
    setVs("X");
    setVh("");
  };

  const handleVhChange = () => {
    setVh("X");
    setVs("");
  };

  const handleVsVhChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vs") {
      handleVsChange();
    } else if (selectedValue === "vh") {
      handleVhChange();
    }
  };


  const handleBedrijfsnaamChange = (event: any) => {
    setBedrijfsnaam(event.target.value);
  };

  const handleVennootschapsvormChange = (event: any) => {
    setVennootschapsvorm(event.target.value);
  };

  const handleOndernemingsnummerChange = (event: any) => {
    setOndernemingsnummer(event.target.value);
    setRPR(event.target.value);
  };



  const handleKlantnummerChange = (event: any) => {
    setKlantnummer(event.target.value);
  };

  const handleEannChange = (event: any) => {
    setEann(event.target.value);
  };

  const handleAceChange = () => {
    setAce("X");
    setGce("");
    setNeg("");
  };

  const handleGceChange = () => {
    setAce("");
    setGce("X");
    setNeg("");
  };

  const handleNegChange = () => {
    setAce("");
    setGce("");
    setNeg("X");
    setCme("");
  };

  const handleAceGceNegChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "ace") {
      handleAceChange();
    } else if (selectedValue === "gce") {
      handleGceChange();
    } else if (selectedValue === "neg") {
      handleNegChange();
    }
  };



  const handleVbChange = () => {
    setVb("X");
    setEla("");
    setBew("");
    setEen("");
  };

  const handleElaChange = () => {
    setVb("");
    setEla("X");
    setBew("");
    setEen("");
  };

  const handleBewChange = () => {
    setVb("");
    setEla("");
    setBew("X");
    setEen("");
  };

  const handleEenChange = () => {
    setVb("");
    setEla("");
    setBew("");
    setEen("X");
  };

  const handleGpvChange = () => {
    setGpv("X");
    setUpv("");
  };

  const handleUpvChange = () => {
    setGpv("");
    setUpv("X");
  };

  const handleVbElaBewEenChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vb") {
      handleVbChange();
    } else if (selectedValue === "ela") {
      handleElaChange();
    } else if (selectedValue === "bew") {
      handleBewChange();
    } else if (selectedValue === "een") {
      handleEenChange();
    }
  };

  const handleGpvUpvChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "gpv") {
      handleGpvChange();
    } else if (selectedValue === "upv") {
      handleUpvChange();
    }
  };

  const handleVbgElagBewgEengChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vbg") {
      handleVbgChange();
    } else if (selectedValue === "elag") {
      handleElagChange();
    } else if (selectedValue === "bewg") {
      handleBewgChange();
    } else if (selectedValue === "eeng") {
      handleEengChange();
    }
  };

  const handleVbgChange = () => {
    setVbg("X");
    setElag("");
    setBewg("");
    setEeng("");
  };

  const handleElagChange = () => {
    setVbg("");
    setElag("X");
    setBewg("");
    setEeng("");
  };

  const handleBewgChange = () => {
    setVbg("");
    setElag("");
    setBewg("X");
    setEeng("");
  };

  const handleEengChange = () => {
    setVbg("");
    setElag("");
    setBewg("");
    setEeng("X");
  };

  

  const handleVerChange = (event: any) => {
    const newValue = event.target.checked ? "X" : "";
    setVer(newValue);
  };


  const handleMajChange = () => {
    setMaj("X");
    setMan("");
  };

  const handleManChange = () => {
    setMaj("");
    setMan("X");
  };

  const handleMajManChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "maj") {
      handleMajChange();
    } else if (selectedValue === "man") {
      handleManChange();
    }
  };


  const handleCmeChange = () => {
    setNeg("");
    setCme("X");
  };


  const handleBirthdateChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setBirthdate(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setBirthdatedd(day || "");
    setBirthdatemm(month || "");
    setBirthdateyy(year || "");
  };

  const handleDatumoverschakelingChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setDatumoverschakeling(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setDatumoverschakelingdd(day || "");
    setDatumoverschakelingmm(month || "");
    setDatumoverschakelingyy(year || "");
  };

  const handleMoveChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
    }

   setMove(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split('/');
    setDdver(day || '');
    setMmver(month || '');
    setYyver(year || '');
  };




  const handleDatumoverschakelingGChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
        4,
        8,
      )}`;
    }

    setDatumoverschakelingG(formattedValue);

    // Update day, month, year based on the formatted value
    const [day, month, year] = formattedValue.split("/");
    setDatumoverschakelingddg(day || "");
    setDatumoverschakelingmmg(month || "");
    setDatumoverschakelingyyg(year || "");
  };

  const handleNegCmeChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "neg") {
      handleNegChange();
    } else if (selectedValue === "cme") {
      handleCmeChange();
    }
  };

  const handleNeggCmegChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "negg") {
      setNegg("X");
      setCmeg("");
    } else if (selectedValue === "cmeg") {
      setNegg("");
      setCmeg("X");
    }
  };

  const handleCmeddChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmedd(ver);
  };

  const handleCmemmChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmemm(ver);
  };

  const handleCmeyyChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeyy(ver);
  };

  const handleOecChange = () => {
    setOec("X");
    setOsm("");
  };

  const handleOsmChange = () => {
    setOsm("X");
    setOec("");
  };

  const handleOecOsmChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "oec") {
      handleOecChange();
    } else if (selectedValue === "osm") {
      handleOsmChange();
    }
  };

  const handleOecgOsmgChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "oecg") {
      setOecg("X");
      setOsmg("");
    } else if (selectedValue === "osmg") {
      setOecg("");
      setOsmg("X");
    }
  };

  const handleCmegChange = (event: any) => {
    setCmeg(event.target.value);
  };

  const handleCmeddgChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeddg(ver);
  };

  const handleCmemmgChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmemmg(ver);
  };

  const handleCmeyygChange = (event: any) => {
    const ver = event.target.value.toString();
    setCmeyyg(ver);
  };

  

  const handleDomgfChange = () => {
    setDomgf("X");
    setDomwf("");
    setVmaegf("");
    setVmaewf("");
  };

  const handleDomwfChange = () => {
    setDomgf("");
    setDomwf("X");
    setVmaegf("");
    setVmaewf("");
  };

  const handleDomgfDomwfChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "domgf") {
      handleDomgfChange();
    } else if (selectedValue === "domwf") {
      handleDomwfChange();
    }
  };

  const handleVmaeChange = () => {
    setDom("");
    setVmae("X");
    setOve("");
  };

  const handleDomVmaeOveChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "dom") {
      handleDomChange();
    } else if (selectedValue === "vmae") {
      handleVmaeChange();
    } else if (selectedValue === "ove") {
      handleOveChange();
    }
  };

  const handleVmaegfChange = () => {
    setDomgf("");
    setDomwf("");
    setVmaegf("X");
    setVmaewf("");
  };

  const handleVmaewfChange = () => {
    setVmaegf("");
    setVmaewf("X");
    setDomgf("");
    setDomwf("");
  };

  const handleVmaegfVmaewfChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "vmaegf") {
      handleVmaegfChange();
    } else if (selectedValue === "vmaewf") {
      handleVmaewfChange();
    }
  };



  const handleIbanChange = (event: any) => {
    setIban(event.target.value);
  };

  const handleGenderChange = (selectedGender: string) => {
    if (selectedGender === "dhr") {
      setMevr("");
      setDhr("X");
      setGenderSelected(true);
      setGenderError(false); 
     
    } else if (selectedGender === "mevr") {
      setMevr("X");
      setDhr("");
      setGenderSelected(true);
      setGenderError(false); 
     }
  };

    // Validate field when trying to submit (example)
  const validateGender = () => {
    if (!genderSelected) {
      setGenderError(true);
    }
  };

  useEffect(() => {
    // Automatically fill in installation address when checkbox is checked by default
    if (checked) {
      setInstalladdress(streetname);
      setInstallnumber(housenumber);
      setInstallbus(bus);
      setInstallzipcode(zipcode);
      setInstallcity(city);
    }
  }, [checked, streetname, housenumber, bus, zipcode, city]);

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      // Fill in installation address fields based on billing address fields
      setInstalladdress(streetname);
      setInstallnumber(housenumber);
      setInstallbus(bus);
      setInstallzipcode(zipcode);
      setInstallcity(city);
    } else {
      // Reset installation address fields if checkbox is unchecked
      setInstalladdress("");
      setInstallnumber("");
      setInstallbus("");
      setInstallzipcode("");
      setInstallcity("");
    }
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
    alignItems: "flex-start", // To place the dynamic form on top
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };



  const handleSaveFilledDocument = async () => {
    try {
      if (documentTemplateId) {
        setCookie("documentTemplateId", documentTemplateId, 2);
        console.log(documentTemplateId);
      } else {
        // Handle the case where documentTemplateId is undefined.
        console.error("documentTemplateId is undefined!");
      }
      setCookie(
        "contactgegevens",
        JSON.stringify({
          surname,
          name,
          email,
          phone,
          streetname,
          housenumber,
          zipcode,
          city,
          eane,
          eang,
          verhuis,
          nutsvoorzieningen,
          elektriciteitsmetergeopend,
          elektriciteitsaansluitingen,
          gasaansluitingen,
          gasmetergeopend
        }),
        2,
      );

      if (
        documentTemplate &&
        surname &&
        name &&
        email &&
        phone &&
        streetname &&
        housenumber &&
        zipcode &&
        city
      ) {
        const trimmedEane = eane.substring(2);
        const trimmedEang = eang.substring(2);
        const trimmedEann = eann.substring(2);
        const trimmedOndernemingsnummer = ondernemingsnummer.substring(2);
        const response = await axios.post(
          `${process.env.REACT_APP_DB_URL}/generateAndSavePDF/`,
          {
            userId: userId,
            documentTemplateId: documentTemplateId,
            surname,
            name,
            email,
            phone,
            streetname,
            housenumber,
            zipcode,
            city,
            eane: trimmedEane,
            eang: trimmedEang,
            identitynumber,
            birthdatedd,
            birthdatemm,
            birthdateyy,
            dhr,
            mevr,
            bus,
            telphone,
            installaddress,
            installnumber,
            installbus,
            installfloor,
            installzipcode,
            installcity,
            eba,
            ebn,
            ebo,
            gba,
            gbn,
            gbo,
            leverancier,
            netbeheerder,
            datumoverschakelingdd,
            datumoverschakelingmm,
            datumoverschakelingyy,
            leverancierg,
            netbeheerderg,
            datumoverschakelingddg,
            datumoverschakelingmmg,
            datumoverschakelingyyg,
            df,
            dom,
            vsa,
            vse,
            ove,
            jed,
            jen,
            jeen,
            sjep,
            mve,
            jg,
            sjgp,
            mvg,
            vs,
            vh,
            maandelijks,
            tweemaandelijks,
            driemaandelijks,
            namesign,
            surnamesign,
            ddtod,
            mmtod,
            yytod,
            citysign,
            bedrijfsnaam,
            vennootschapsvorm,
            ondernemingsnummer: trimmedOndernemingsnummer,
            verzendname,
            verzendsurname,
            klantnummer,
            eann: trimmedEann,
            ace,
            gce,
            neg,
            negg,
            vb,
            ela,
            bew,
            een,
            vbg,
            elag,
            bewg,
            eeng,
            voore,
            voorg,
            ver,
            ddver,
            mmver,
            yyver,
            maj,
            man,
            productname,
            productnameg,
            cme,
            cmedd,
            cmemm,
            cmeyy,
            oec,
            osm,
            cmeg,
            cmeddg,
            cmemmg,
            cmeyyg,
            oecg,
            osmg,
            domgf,
            domwf,
            vmae,
            vmaegf,
            vmaewf,
            // verhuisJa,
            //verhuisNee,
            pp,ib,iban, RPR, nace, verzendfirmname, verzendjurform, gpv,upv
          }
        );

        console.log("Filled document saved:", response.data);

        navigate("/DocumentSign");
      } else {
        console.log("Fill in all the required fields before saving.");
      }
    } catch (error) {
      console.error("Error saving filled document:", error);
    }
  };




  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Aanvullende gegevens</PageHero>
          <div className="pageContainerF" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />

            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                <div className="userCardRequestsolarF">
           
          
              
  {/* Luminus */}
     { documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" || documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138" ?  (  <><h3>Basis gegevens</h3>
     <div className="userCardRequestColumnssolarF">
          <div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">
     { contractType === "EP" || contractType === "GP" || contractType === "CP" ? ( <>

  
      
      
 

      <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          label="Bedrijfsnaam"
          variant="standard"
          type="text"
          id="Bedrijfsnaam"
          name="bedrijfsnaam"
          value={bedrijfsnaam}
          onChange={handleBedrijfsnaamChange}
          required
          fullWidth
        />
      </Box>

      {/* Error message if the "Bedrijfsnaam" field is empty */}
      {bedrijfsnaam === '' && (
        <span style={{ color: 'red' }}>
          Bedrijfsnaam is verplicht.
        </span>
      )}
    </div>

    <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="Vennootschapsvorm"
          select
          label="Vennootschapsvorm"
          // value={vennootschapsvorm}
          onChange={handleVennootschapsvormChange}
          variant="standard"
          fullWidth
          required
          defaultValue='Eenmanszaak'
        >
          <MenuItem value="">Kies een vennootschapsvorm</MenuItem>
          <MenuItem value="Eenmanszaak">Eenmanszaak</MenuItem>
          <MenuItem value="Vennootschap met beperkte aansprakelijkheid (BV)">
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value="Naamloze vennootschap (NV)">
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value="Coöperatieve vennootschap (CV)">
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value="Vennootschap onder firma (VOF)">
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value="Commanditaire vennootschap (CommV)">
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value="Maatschap">Maatschap</MenuItem>
          <MenuItem value="Europese vennootschap (SE)">
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value="Vereniging zonder winstoogmerk (VZW)">
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField>
      </Box>

      {/* Error message if the "Vennootschapsvorm" field is not selected */}
      {/* {vennootschapsvorm === '' && (
        <span style={{ color: 'red' }}>
          Vennootschapsvorm is verplicht.
        </span>
      )} */}
    </div>





    <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          label="Ondernemingsnummer"
          variant="standard"
          type="text"
          id="Ondernemingsnummer"
          value={ondernemingsnummer}
          onChange={handleOndernemingsnummerChange}
          required
          fullWidth
        />
      </Box>

      {/* Error message if the "Ondernemingsnummer" field is empty */}
      {ondernemingsnummer === '' && (
        <span style={{ color: 'red' }}>
          Ondernemingsnummer is verplicht.
        </span>
      )}
    </div>
     </> ): <div></div>}


    {/*
    <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                    id="gender"
                    select
                    label="Aanspreektitel"
                    // value={birthdatedd}
                    onChange={(event) => handleGenderChange(event.target.value)}
                    variant="standard"
                    fullWidth
                  >


        <MenuItem value={'dhr'}>Dhr</MenuItem>
        <MenuItem value={'mevr'}>Mevr</MenuItem>

                  </TextField> 
                
                </Box>
              </div>


   
          <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        
                <TextField
                    label="Voornaam"
                    variant="standard"
                    type="text"
                    id="surNameFilter"
                    value={surname}
                    onChange={handleSurnameChange}
                    required
                    fullWidth
                  />
        
                </Box>
                </div>

                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             
                  <TextField
                    label="Naam"
                    variant="standard"
                    type="text"
                    id="nameFilter"
                    value={name}
                    onChange={handleNameChange}
                    required
                    fullWidth
                  />
    
                </Box>
                </div>
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Email"
                    variant="standard"
                    type="text"
                    id="emailFilter"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                  />
            
                </Box>
                </div>
*/}

<div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            id="gender"
            select
            label="Aanspreektitel"
            onChange={(event) => handleGenderChange(event.target.value)}
            variant="standard"
            fullWidth
            defaultValue='dhr'
          >
            <MenuItem value={'dhr'}>Dhr</MenuItem>
            <MenuItem value={'mevr'}>Mevr</MenuItem>
          </TextField>
        </Box>
        {genderError && (
          <span style={{ color: 'red' }}>Aanspreektitel is verplicht.</span>
        )}
     
      </div>

      {/* Voornaam Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Voornaam"
            variant="standard"
            type="text"
            id="surNameFilter"
            value={surname}
            onChange={handleSurnameChange}
            required
            fullWidth
          />
        </Box>

        {/* Error message if "Voornaam" is empty */}
        {surname === '' && (
          <span style={{ color: 'red' }}>
            Voornaam is verplicht.
          </span>
        )}
      </div>

      {/* Naam Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Naam"
            variant="standard"
            type="text"
            id="nameFilter"
            value={name}
            onChange={handleNameChange}
            required
            fullWidth
          />
        </Box>

        {/* Error message if "Naam" is empty */}
        {name === '' && (
          <span style={{ color: 'red' }}>
            Naam is verplicht.
          </span>
        )}
      </div>

      {/* Email Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Email"
            variant="standard"
            type="email"
            id="emailFilter"
            value={email}
            onChange={handleEmailChange}
            required
            fullWidth
          />
        </Box>

        {/* Error message if "Email" is empty or invalid */}
        {email === '' && (
          <span style={{ color: 'red' }}>
            Email is verplicht.
          </span>
        )}
        {/* Simple email format check */}
        {email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) && (
          <span style={{ color: 'red' }}>
            Ongeldig emailadres.
          </span>
        )}
      </div>


{/* { contractType === "E" || contractType === "G" || contractType === "C" ? (
    <>
                <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
  <TextField
          label="Identiteitskaartnummer"
          variant="standard"
          type="text"
          id="identitynumber"
          name="identitynumber"
          value={identitynumber}
          onChange={handleIdentityNumberChange}
          required
          fullWidth
        />
        </Box>
     
      {identitynumber === '' && (
        <span style={{ color: 'red' }}>
          Identiteitskaartnummer is verplicht.
        </span>
      )}



          
            </div>
            </>
               ): <div></div>}  */}



                {/* <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          label="Identiteitskaartnummer"
          variant="standard"
          type="text"
          id="identitynumber"
          name="identitynumber"
          value={identitynumber}
          onChange={handleIdentityNumberChange}
          required
          fullWidth
        />
      </Box>

     
      {identitynumber === '' && (
        <span style={{ color: 'red' }}>
          Identiteitskaartnummer is verplicht.
        </span>
      )}
    </div> */}

{/*
<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        

            </Box>
            </div>
*/}
    {/*        <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Straatnaam"
                    variant="standard"
                    type="text"
                    id="streetname"
                    value={streetname}
                    onChange={handleStreetnameChange}
                    required
                    fullWidth
                  />
 
                </Box>
                </div>
                </div>
                <div className="columnFill">
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

                  <TextField
                    label="Huisnummer"
                    variant="standard"
                    type="text"
                    id="housenumber"
                    value={housenumber}
                    onChange={handleHousenumberChange}
                    required
                    fullWidth
                  />
 
                </Box>
                </div>

                <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Bus"
                variant="standard"
                type="text"
                id="bus"
                value={bus}
                onChange={handleBusChange}
                fullWidth
              /> 
    
            </Box>
            </div>

            
                <div className="form-group"> 
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              
              <TextField
                label="Postcode"
                variant="standard"
                type="text"
                id="zipcode"
                value={zipcode}
                onChange={handleZipcodeChange}
                required
                fullWidth
              />
  
            </Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>

              <TextField
                label="Stad / Gemeente"
                variant="standard"
                type="text"
                id="city"
                value={city}
                onChange={handleCityChange}
                required
                fullWidth
              />
               
            </Box>
            </div>
*/}

<div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Straatnaam"
            variant="standard"
            type="text"
            id="streetname"
            value={streetname}
            onChange={handleStreetnameChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message for Straatnaam */}
        {streetname === '' && (
          <span style={{ color: 'red' }}>Straatnaam is verplicht.</span>
        )}
      </div>

      {/* Huisnummer Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Huisnummer"
            variant="standard"
            type="text"
            id="housenumber"
            value={housenumber}
            onChange={handleHousenumberChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message for Huisnummer */}
        {housenumber === '' && (
          <span style={{ color: 'red' }}>Huisnummer is verplicht.</span>
        )}
      </div>

      {/* Bus Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Bus"
            variant="standard"
            type="text"
            id="bus"
            value={bus}
            onChange={handleBusChange}
            fullWidth
          />
        </Box>
      </div>

      {/* Postcode Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Postcode"
            variant="standard"
            type="text"
            id="zipcode"
            value={zipcode}
            onChange={handleZipcodeChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message for Postcode */}
        {zipcode === '' && (
          <span style={{ color: 'red' }}>Postcode is verplicht.</span>
        )}
      </div>

      {/* Stad / Gemeente Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Stad / Gemeente"
            variant="standard"
            type="text"
            id="city"
            value={city}
            onChange={handleCityChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message for Stad / Gemeente */}
        {city === '' && (
          <span style={{ color: 'red' }}>Stad / Gemeente is verplicht.</span>
        )}
      </div>

{contractType === "E" || contractType === "G" || contractType === "C" ? (
       <div className="form-group">
       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
         <TextField
           id="Birthdate"
           label="Geboortedatum (dd/mm/yyyy)"
           value={birthdate}
           onChange={handleBirthdateChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"
            inputProps={{
              pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
              placeholder: "dd/mm/yyyy",
            }}
         /> 
     
       </Box>
       {birthdate === '' && (
        <span style={{ color: 'red' }}>
          Geboortedatum is verplicht.
        </span>
      )}
  
     </div> 


      ) : (
        <div></div>
      )}
                  
              </div>
              
</div>

</div>
{/* Checkbox adres luminus */}
<div className="form-group">
            <Box sx={{ display: "flex", alignItems: "center" }}>
            
            <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      Het installatieadres is dezelfde als het facturatieadres.
            </Box>
            </div>


            <h3>Informatie Installatieadres</h3>
            <div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">
         {/*   <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Straatnaam Afnamepunt"
                variant="standard"
                type="text"
                id="installaddress"
                value={installaddress}
                onChange={handleInstallAddressChange}
                required
                fullWidth
              />
           
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Huisnummer Afnamepunt"
                variant="standard"
                type="text"
                id="installnumber"
                value={installnumber}
                onChange={handleInstallNumberChange}
                required
                fullWidth
              />
  
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             <TextField
                label="Bus Afnamepunt"
                variant="standard"
                type="text"
                id="installbus"
                value={installbus}
                onChange={handleInstallBusChange}
                fullWidth

              /> 
        
            </Box>
            </div>
*/}
 <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Straatnaam Afnamepunt"
            variant="standard"
            type="text"
            id="installaddress"
            value={installaddress}
            onChange={handleInstallAddressChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message if the "Straatnaam Afnamepunt" field is empty */}
        {installaddress === '' && (
          <span style={{ color: 'red' }}>Straatnaam Afnamepunt is verplicht.</span>
        )}
      </div>

      {/* Huisnummer Afnamepunt Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Huisnummer Afnamepunt"
            variant="standard"
            type="text"
            id="installnumber"
            value={installnumber}
            onChange={handleInstallNumberChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message if the "Huisnummer Afnamepunt" field is empty */}
        {installnumber === '' && (
          <span style={{ color: 'red' }}>Huisnummer Afnamepunt is verplicht.</span>
        )}
      </div>

      {/* Bus Afnamepunt Field (Optional) */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Bus Afnamepunt"
            variant="standard"
            type="text"
            id="installbus"
            value={installbus}
            onChange={handleInstallBusChange}
            fullWidth
          />
        </Box>
      </div>

            </div>

     <div className="columnFill">

       {/*     <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Verdiep Afnamepunt"
                variant="standard"
                type="text"
                id="installfloor"
                value={installfloor}
                onChange={handleInstallFloorChange}
                fullWidth
              /> 
         
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Postcode Afnamepunt"
                variant="standard"
                type="text"
                id="installzipcode"
                value={installzipcode}
                onChange={handleInstallZipcodeChange}
                required
                fullWidth
              />
           
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             <TextField
                label="Gemeente Afnamepunt"
                variant="standard"
                type="text"
                id="installcity"
                value={installcity}
                onChange={handleInstallCityChange}
                required
                fullWidth
              />
             
            </Box>
            </div>
          */}
           {/* <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Verdiep Afnamepunt"
            variant="standard"
            type="text"
            id="installfloor"
            value={installfloor}
            onChange={handleInstallFloorChange}
            fullWidth
          />
        </Box>
      </div> */}

      {/* Postcode Afnamepunt Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Postcode Afnamepunt"
            variant="standard"
            type="text"
            id="installzipcode"
            value={installzipcode}
            onChange={handleInstallZipcodeChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message if the "Postcode Afnamepunt" field is empty */}
        {installzipcode === '' && (
          <span style={{ color: 'red' }}>Postcode Afnamepunt is verplicht.</span>
        )}
      </div>

      {/* Gemeente Afnamepunt Field */}
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Gemeente Afnamepunt"
            variant="standard"
            type="text"
            id="installcity"
            value={installcity}
            onChange={handleInstallCityChange}
            required
            fullWidth
          />
        </Box>
        {/* Error message if the "Gemeente Afnamepunt" field is empty */}
        {installcity === '' && (
          <span style={{ color: 'red' }}>Gemeente Afnamepunt is verplicht.</span>
        )}

<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>

<TextField
            id="verhuis"
            select
            label="Is dit een verhuis?"
            onChange={(event) => handleSetVerhuisChange(event.target.value)}
            variant="standard"
            fullWidth
            defaultValue='Nee'
          >
            <MenuItem value={'Ja'}>Ja</MenuItem>
            <MenuItem value={'Nee'}>Nee</MenuItem>
          </TextField>
        </Box>
      </div>


{verhuis === 1 && (
  <>
        <div className="form-group">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              id="nutsvoorziening"
              select
              label="Wilt u dat wij de overdracht van de watermeters regelen op dit adres?"
              onChange={(event) => handleSetNutsvoorzieningenChange(event.target.value)}
              variant="standard"
              fullWidth
              defaultValue='Nee'
            >
              <MenuItem value={'Ja'}>Ja</MenuItem>
              <MenuItem value={'Nee'}>Nee</MenuItem>
            </TextField>
          </Box>
         
     
   
     </div>
     <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecaanslaanw"
             select
             label="Is er reeds een elektriciteitsaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecmeterreedsgeopend"
             select
             label="Is de elektriciteitsmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasaanslaanw"
             select
             label="Is er reeds een gasaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasmeterreedsgeopend"
             select
             label="Is de aardgasmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>

    </>
      )}
      </div>
            </div>
            </div>

                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>

<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">


   {/*  <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="AansluitingRadio"
                    select
                    label="Aansluiting *"
                    fullWidth
                    onChange={(event) => handleEbaEbnEboChange(event)}
                    variant="standard"
                    
                  >


        <MenuItem value={'eba'}>Bestaande aansluiting</MenuItem>
        <MenuItem value={'ebn'}>Nieuwe aansluiting</MenuItem>


                  </TextField> 
        

                </Box>
              </div>



            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Huidige leverancier"
                variant="standard"
                type="text"
                id="leverancier"
                value={leverancier}
                onChange={handleLeverancierChange}
                fullWidth

              /> 
      
            </Box>
            </div>

            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Meternummer *"
                variant="standard"
                type="text"
                id="netbeheerder"
                value={netbeheerder}
                onChange={handleNetbeheerderChange}
                fullWidth

              /> 
             
            </Box>
            </div> */}

{/* <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            id="AansluitingRadio"
            select
            label="Aansluiting *"
            fullWidth
            onChange={handleEbaEbnEboChange}
            variant="standard"
            defaultValue='eba'
          >
            <MenuItem value={'eba'}>Bestaande aansluiting</MenuItem>
            <MenuItem value={'ebn'}>Nieuwe aansluiting</MenuItem>
          </TextField>
        </Box>
      </div>

      { eba === "X" && (
    <>

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huidige leverancier *"
      variant="standard"
      type="text"
      id="leverancier"
      value={leverancier}
      onChange={handleLeverancierChange}
      fullWidth
    />
  </Box>

  {leverancier === '' && (
    <span style={{ color: 'red' }}>Huidige leverancier is verplicht.</span>
  )}
</div>
</>)} */}
                      

     {/* <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            label="Meternummer *"
            variant="standard"
            type="text"
            id="netbeheerder"
            value={netbeheerder}
            onChange={handleNetbeheerderChange}
            fullWidth
          />
        </Box>
       {netbeheerder === '' && (
          <span style={{ color: 'red' }}>Meternummer is verplicht.</span>
        )}
      </div> */}

            <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Ean elektriciteit *"
      variant="standard"
      type="text"
      id="eane"
      value={eane}
      onChange={handleEaneChange}
      fullWidth
      inputProps={{
        pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
      }}
    />
   
   
  </Box>
</div>

                              {eane.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>

     <div className="columnFill">


 <div className="form-group">
       <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      
       </Box>
     </div>
</div>
</div>
</>
): <div></div>}


{ contractType === "G" || contractType === "C" || contractType === "GP" || contractType === "CP" ? ( <>
<h3>Gas</h3>
<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">


     {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="AansluitingRadio"
                    select
                    label="Aansluiting *"
                    fullWidth
                    onChange={(event) => handleGbaGbnGboChange(event)}
                    variant="standard"
                    defaultValue='gba'
                  >


        <MenuItem value={'gba'}>Bestaande aansluiting</MenuItem>
        <MenuItem value={'gbn'}>Nieuwe aansluiting</MenuItem>


                  </TextField> 

                </Box>
              </div>

              { gba === "X" && ( <>
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huidige leverancier *"
      variant="standard"
      type="text"
      id="leverancier"
      value={leverancierg}
      onChange={handleLeverancierGChange}
      fullWidth
    />
  </Box>
 
  {leverancierg === '' && (
    <span style={{ color: 'red' }}>Huidige leverancier is verplicht.</span>
  )}
</div>
</>)} */}


{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Meternummer *"
      variant="standard"
      type="text"
      id="netbeheerder"
      value={netbeheerderg}
      onChange={handleNetbeheerderGChange}
      fullWidth
    />
  </Box>
 {netbeheerderg === '' && (
    <span style={{ color: 'red' }}>Meternummer is verplicht.</span>
  )}
</div> */}



            <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
        label="Ean gas *"
        variant="standard"
        type="text"
        id="eang"
        value={eang}
        onChange={handleEangChange}
        fullWidth
      inputProps={{
        pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
      }}
    />

  </Box>
</div>
{eang.length !== 18 && (
      <span style={{ color: "red" }}>
        Vul alsjeblieft 18 getallen in startende met 54.
      </span>
  )}
</div>
     <div className="columnFill">

     <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                      

<TextField
  id="datumoverschakeling"
  label="Datum gewenste overschakeling (dd/mm/yyyy)"
  value={datumoverschakelingG}
  onChange={handleDatumoverschakelingGChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  inputProps={{
    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
    title: "Datum moet het formaat dd/mm/yyyy hebben",
  }}
/>
                            </Box>
                            {datumoverschakelingG === '' && (
    <span style={{ color: 'red' }}>Datum gewenste overschakeling is verplicht.</span>
  )}
                          </div>

</div>

</div>
</>
): <div></div>}
{/* </>
)} */}
                      <h3>
                        Verzend- en betalingswijze van de facturen voor
                        elektriciteit en/of gas
                      </h3>

<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="VoorschotbedragRadio"
                    select
                    label="Verzend- en betalingswijze"
                    onChange={(event) => handleDfDomOveChange(event)}
                    variant="standard"
                    required
                    fullWidth
                    defaultValue="dom"
                  >

<MenuItem value={'dom'}>SEPA</MenuItem>
        <MenuItem value={'df'}>E-billing</MenuItem>
        <MenuItem value={'ove'}>Overschrijving</MenuItem>
     
      
                  </TextField>
                </Box>
              </div>


    {dom === 'X' && ( // Render the nested radio buttons only when dom is checked
  <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      id="VoorschotfacRadio"
      select
      label="Voor"
     required
      onChange={(event) => handleVsaVseChange(event)}
      variant="standard"
      fullWidth
      defaultValue='vsa'
    >


<MenuItem value={'vsa'}>Voor mijn voorschotfacturen en mijn afrekeningen.</MenuItem>
<MenuItem value={'vse'}>Alleen voor mijn voorschotfacturen en niet voor mijn afrekeningen.</MenuItem>


    </TextField>

  </Box>
</div>
          )}

{ contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
{/* <h3>Schatting van de jaarlijkse energieprijs en voorschotbedrag</h3>
<h5>Schatting jaarverbruik</h5> */}
<div className="userCardRequestColumnssolarCleaning">  
     <div className="columnFill">

     { contractType === "E" || contractType === "C" ? (
    <>

{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="kWh dag (Elektriciteit) *"
      variant="standard"
      type="text"
      id="jed"
      value={jed}
      onChange={handleJedChange}
      fullWidth
    />
  </Box>
  {jed === '' && (
    <span style={{ color: 'red' }}>kWh dag (Elektriciteit) is verplicht.</span>
  )}
</div> */}


{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="kWh nacht (Elektriciteit) *"
      variant="standard"
      type="text"
      id="jen"
      value={jen}
      onChange={handleJenChange}
      fullWidth
    />
  </Box>
  {jen === '' && (
    <span style={{ color: 'red' }}>kWh nacht (Elektriciteit) is verplicht.</span>
  )}
</div> */}


{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="kWh excl. nacht (Elektriciteit) *"
      variant="standard"
      type="text"
      id="jeen"
      value={jeen}
      onChange={handleJeenChange}
      fullWidth
    />
  </Box>
  {jeen === '' && (
    <span style={{ color: 'red' }}>kWh excl. nacht (Elektriciteit) is verplicht.</span>
  )}
</div> */}



{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Schatting jaarlijkse energieprijs voor elektriciteit (alle taksen inbegrepen) *"
      variant="standard"
      type="text"
      id="sjep"
      value={sjep}
      onChange={handleSjepChange}
      fullWidth
    />
  </Box>
  {sjep === '' && (
    <span style={{ color: 'red' }}>Schatting jaarlijkse energieprijs voor elektriciteit is verplicht.</span>
  )}
</div> */}

{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Maandelijks voorschotbedrag Electriciteit *"
      variant="standard"
      type="text"
      id="mve"
      value={mve}
      onChange={handleMveChange}
      required
      fullWidth
    />
  </Box>
  {mve === '' && (
    <span style={{ color: 'red' }}>Maandelijks voorschotbedrag electriciteit is verplicht.</span>
  )}
</div> */}



</>):<div></div>}

{ contractType === "G" || contractType === "C" ? (
    <>
{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="kWh verbruik Gas *"
      variant="standard"
      type="text"
      id="jg"
      value={jg}
      onChange={handleJgChange}
      fullWidth
    />
  </Box>
  {jg === '' && (
    <span style={{ color: 'red' }}>kWh verbruik Gas is verplicht.</span>
  )}
</div> */}


{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Schatting jaarlijkse energieprijs voor gas (alle taksen inbegrepen) *"
      variant="standard"
      type="text"
      id="sjgp"
      value={sjgp}
      onChange={handleSjgpChange}
      fullWidth
    />
  </Box>
  {sjgp === '' && (
    <span style={{ color: 'red' }}>Schatting jaarlijkse energieprijs voor gas is verplicht.</span>
  )}
</div> */}


     <div className="columnFill">
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           
            </Box>
            </div>
{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Maandelijks voorschotbedrag gas *"
      variant="standard"
      type="text"
      id="mvg"
      value={mvg}
      onChange={handleMvgChange}
      required
      fullWidth
    />
  </Box>
  {mvg === '' && (
    <span style={{ color: 'red' }}>Maandelijks voorschotbedrag gas is verplicht.</span>
  )}
</div> */}
</div>
</>):<div></div>}
            { contractType === "E" || contractType === "G" || contractType === "C" ? (
    <>
<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="VoorschotbedragRadio"
                    select
                    label="Mijn voorschotbedrag wordt"
                   required
                    onChange={(event) => handleVsVhChange(event)}
                    variant="standard"
                    fullWidth
                    defaultValue='vs'
                  >


        <MenuItem value={'vs'}>bepaald in samenspraak met Luminus</MenuItem>
        <MenuItem value={'vh'}>gebaseerd op wat ik nu betaal bij mijn huidige leverancier</MenuItem>


                  </TextField> 
  

                </Box>
              </div>
              </>
            ) : <div></div>}

                      {/*        <div className="form-group">
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      id="FrequentieRadio"
                                      select
                                      label="Frequentie van voorschotten"
                                      required
                                      variant="standard"
                                      fullWidth
                                      defaultValue={"maandelijks"}
                                      disabled // Make the field uneditable
                                    >
                                      <MenuItem value={"maandelijks"}>
                                        maandelijks
                                      </MenuItem>
                                     
                                    </TextField>
                                  </Box>
                                </div>
                              
</div> */}
</div>
</div>
</>):<div></div>}
{/*// Error messaging hieronder bekijken */}
{ contractType === "EP" || contractType === "GP" || contractType === "CP" ? (
  <>
 {/*   <h3>Voorschotbedrag energie (incl. btw)</h3> */}
    <div className="userCardRequestColumnssolarCleaning">
      <div className="columnFill">

        {/* Jaarverbruik elektriciteit (dag) Field - Only for EP or CP */}
        {/* {(contractType === "EP" || contractType === "CP") && (
          // <div className="form-group">
          //   <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          //     <TextField
          //       label="Jaarverbruik elektriciteit (dag) *"
          //       variant="standard"
          //       type="text"
          //       id="jed"
          //       value={jed}
          //       onChange={handleJedChange}
          //       fullWidth
          //     />
          //   </Box>
          //   {jed === '' && (
          //     <span style={{ color: 'red' }}>Jaarverbruik elektriciteit (dag) is verplicht.</span>
          //   )}
          // </div>
        )} */}

        {/* Jaarverbruik elektriciteit (nacht) Field - Only for EP or CP */}
        {/* {(contractType === "EP" || contractType === "CP") && (
          // <div className="form-group">
          //   <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          //     <TextField
          //       label="Jaarverbruik elektriciteit (nacht) *"
          //       variant="standard"
          //       type="text"
          //       id="jen"
          //       value={jen}
          //       onChange={handleJenChange}
          //       fullWidth
          //     />
          //   </Box>
          //   {jen === '' && (
          //     <span style={{ color: 'red' }}>Jaarverbruik elektriciteit (nacht) is verplicht.</span>
          //   )}
          // </div>
        )} */}

        {/* Jaarverbruik elektriciteit (excl. nacht) Field - Only for EP or CP */}
        {/* {(contractType === "EP" || contractType === "CP") && (
          // <div className="form-group">
          //   <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          //     <TextField
          //       label="Jaarverbruik elektriciteit (excl. nacht)"
          //       variant="standard"
          //       type="text"
          //       id="jeen"
          //       value={jeen}
          //       onChange={handleJeenChange}
          //       fullWidth
          //     />
          //   </Box>
          //   {jeen === '' && (
          //     <span style={{ color: 'red' }}>Jaarverbruik elektriciteit (excl. nacht) is verplicht.</span>
          //   )}
          // </div>
        )} */}

        {/* Jaarverbruik gas Field - Only for GP or CP */}
         {/* {(contractType === "GP" || contractType === "CP") && (
          <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                label="Jaarverbruik gas"
                variant="standard"
                type="text"
                id="jg"
                value={jg}
                onChange={handleJgChange}
                fullWidth
              />
            </Box>
            {jg === '' && (
              <span style={{ color: 'red' }}>Jaarverbruik gas is verplicht.</span>
            )}
          </div> 
        )}
      </div> */}

      {/* Maandelijks voorschotbedrag elektriciteit Field - Only for EP or CP */}
      {/* {(contractType === "EP" || contractType === "CP") && (
        // <div className="columnFill">
        //   <div className="form-group">
        //     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        //       <TextField
        //         label="Maandelijks voorschotbedrag elektriciteit"
        //         variant="standard"
        //         type="text"
        //         id="mve"
        //         value={mve}
        //         onChange={handleMveChange}
        //         required
        //         fullWidth
        //       />
        //     </Box>
        //     {mve === '' && (
        //       <span style={{ color: 'red' }}>Maandelijks voorschotbedrag elektriciteit is verplicht.</span>
        //     )}
        //   </div>
        // </div>
      )} */}

      {/* Maandelijks voorschotbedrag gas Field - Only for GP or CP */}
      {/* {(contractType === "GP" || contractType === "CP") && (
        // <div className="form-group">
        //   <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        //     <TextField
        //       label="Maandelijks voorschotbedrag gas"
        //       variant="standard"
        //       type="text"
        //       id="mvg"
        //       value={mvg}
        //       onChange={handleMvgChange}
        //       required
        //       fullWidth
        //     />
        //   </Box>
        //   {mvg === '' && (
        //     <span style={{ color: 'red' }}>Maandelijks voorschotbedrag gas is verplicht.</span>
        //   )}
        // </div>
      )} */}
    </div>
    </div>
  </>
) : (
  <div></div>
)}


          </>) : (<div></div>)}
{/* Eneco */}
          { documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" || documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882"|| documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||      documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" || documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" || documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882"|| documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883" ?  (  <><h3>Klantgegevens</h3>
          {/* <div className="userCardRequestColumnssolarF"> */}
          <div className="userCardRequestColumnssolarCleaning">
     <div className="columnFill">

     <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
             
                     <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                    defaultValue='Nee'
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField>
                </Box>
              </div>

          

    <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 <TextField
                    id="gender"
                    select
                    label="Aanspreektitel"
                    onChange={(event) => handleGenderChange(event.target.value)}
                    variant="standard"
                    required
                    fullWidth   
                    defaultValue='dhr' 
               >


        <MenuItem value={'dhr'}>Dhr</MenuItem>
        <MenuItem value={'mevr'}>Mevr</MenuItem>

                  </TextField>
     
                </Box>
              </div>


   {/* Naam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Naam"
      variant="standard"
      type="text"
      id="nameFilter"
      value={name}
      onChange={handleNameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Naam" field is empty */}
  {name === '' && (
    <span style={{ color: 'red' }}>Naam is verplicht.</span>
  )}
</div>

{/* Voornaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Voornaam"
      variant="standard"
      type="text"
      id="surNameFilter"
      value={surname}
      onChange={handleSurnameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Voornaam" field is empty */}
  {surname === '' && (
    <span style={{ color: 'red' }}>Voornaam is verplicht.</span>
  )}
</div>

{/* Telefoonnummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Telefoonnummer"
      variant="standard"
      type="text"
      id="phone"
      value={phone}
      onChange={handlePhoneChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Telefoonnummer" field is empty */}
  {phone === '' && (
    <span style={{ color: 'red' }}>Telefoonnummer is verplicht.</span>
  )}
</div>

{/* Email Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Email"
      variant="standard"
      type="text"
      id="emailFilter"
      value={email}
      onChange={handleEmailChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Email" field is empty */}
  {email === '' && (
    <span style={{ color: 'red' }}>Email is verplicht.</span>
  )}
</div>

                    </div>
<div className="columnFill">

{ contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
                    {/* Rijksregisternummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Rijksregisternummer"
      variant="standard"
      type="text"
      id="identitynumber"
      value={identitynumber}
      onChange={handleIdentityNumberChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Rijksregisternummer" field is empty */}
  {identitynumber === '' && (
    <span style={{ color: 'red' }}>Rijksregisternummer is verplicht.</span>
  )}
</div>

                    
              


<div className="form-group">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
              id="Birthdate"
              label="Geboortedatum (dd/mm/yyyy)"
              value={birthdate}
              onChange={handleBirthdateChange}
              variant="standard"
              required
              fullWidth
              placeholder='dd/mm/yyyy'
               inputProps={{
                 pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
                 placeholder: "dd/mm/yyyy",
               }}
            /> 
       
          </Box>
           {/* Error message if the "Geboortedatum" field is empty */}
  {birthdate === '' && (
    <span style={{ color: 'red' }}>Geboortedatum is verplicht.</span>
  )}
        </div>

                    </>):<div></div>}
                    { contractType === "EP" || contractType === "GP" || contractType === "CP" ? ( <>
                  {/* Bedrijfsnaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Bedrijfsnaam"
      variant="standard"
      type="text"
      id="Bedrijfsnaam"
      value={bedrijfsnaam}
      onChange={handleBedrijfsnaamChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Bedrijfsnaam" field is empty */}
  {bedrijfsnaam === '' && (
    <span style={{ color: 'red' }}>Bedrijfsnaam is verplicht.</span>
  )}
</div>


                    <div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="Vennootschapsvorm"
          select
          label="Vennootschapsvorm"
          value={vennootschapsvorm}
          onChange={handleVennootschapsvormChange}
          variant="standard"
          fullWidth
          required
           defaultValue='Eenmanszaak'
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField> 
      
      </Box>
    </div>
             {/* Ondernemingsnummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Ondernemingsnummer"
      variant="standard"
      type="text"
      id="Ondernemingsnummer"
      value={ondernemingsnummer}
      onChange={handleOndernemingsnummerChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Ondernemingsnummer" field is empty */}
  {ondernemingsnummer === '' && (
    <span style={{ color: 'red' }}>Ondernemingsnummer is verplicht.</span>
  )}
</div>

</>):<div></div>}         
                    </div>
                    </div>

                      <h3>Facturatieadres</h3>
                      <div className="userCardRequestColumnssolarF">
                        <div className="columnFill">
                         
                         
                         {/* Straatnaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam"
      variant="standard"
      type="text"
      id="streetname"
      value={streetname}
      onChange={handleStreetnameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Straatnaam" field is empty */}
  {streetname === '' && (
    <span style={{ color: 'red' }}>Straatnaam is verplicht.</span>
  )}
</div>

{/* Huisnummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer"
      variant="standard"
      type="text"
      id="housenumber"
      value={housenumber}
      onChange={handleHousenumberChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Huisnummer" field is empty */}
  {housenumber === '' && (
    <span style={{ color: 'red' }}>Huisnummer is verplicht.</span>
  )}
</div>

{/* Bus Field (optional) */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Bus"
      variant="standard"
      type="text"
      id="bus"
      value={bus}
      onChange={handleBusChange}
      fullWidth
    />
  </Box>
</div>

                        </div>
                        <div className="columnFill">
                         
                         {/* Postcode Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode"
      variant="standard"
      type="text"
      id="zipcode"
      value={zipcode}
      onChange={handleZipcodeChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Postcode" field is empty */}
  {zipcode === '' && (
    <span style={{ color: 'red' }}>Postcode is verplicht.</span>
  )}
</div>

{/* Stad / Gemeente Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Stad / Gemeente"
      variant="standard"
      type="text"
      id="city"
      value={city}
      onChange={handleCityChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Stad / Gemeente" field is empty */}
  {city === '' && (
    <span style={{ color: 'red' }}>Stad / Gemeente is verplicht.</span>
  )}
</div>

                        </div>
                      </div>

                      {/* Checkbox adres Eneco */}
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Het aansluitingsadres is dezelfde als het
                          facturatieadres.
                        </Box>
                      </div>

                      <h3>Aansluitingsadres</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                         
                      {/* Straatnaam Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam Afnamepunt *"
      variant="standard"
      type="text"
      id="installaddress"
      value={installaddress}
      onChange={handleInstallAddressChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Straatnaam Afnamepunt" field is empty */}
  {installaddress === '' && (
    <span style={{ color: 'red' }}>Straatnaam Afnamepunt is verplicht.</span>
  )}
</div>

{/* Huisnummer Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer Afnamepunt"
      variant="standard"
      type="text"
      id="installnumber"
      value={installnumber}
      onChange={handleInstallNumberChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Huisnummer Afnamepunt" field is empty */}
  {installnumber === '' && (
    <span style={{ color: 'red' }}>Huisnummer Afnamepunt is verplicht.</span>
  )}
</div>


                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus Afnamepunt"
                                variant="standard"
                                type="text"
                                id="installbus"
                                value={installbus}
                                onChange={handleInstallBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>

                         {/* Postcode Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode Afnamepunt"
      variant="standard"
      type="text"
      id="installzipcode"
      value={installzipcode}
      onChange={handleInstallZipcodeChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Postcode Afnamepunt" field is empty */}
  {installzipcode === '' && (
    <span style={{ color: 'red' }}>Postcode Afnamepunt is verplicht.</span>
  )}
</div>

                        </div>
                        <div className="columnFill">
                         
                          {/* Gemeente Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Gemeente Afnamepunt"
      variant="standard"
      type="text"
      id="installcity"
      value={installcity}
      onChange={handleInstallCityChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Gemeente Afnamepunt" field is empty */}
  {installcity === '' && (
    <span style={{ color: 'red' }}>Gemeente Afnamepunt is verplicht.</span>
  )}
</div>
{verhuis == 1 && (
       <>
       <div className="form-group">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              id="nutsvoorziening"
              select
              label="Wilt u dat wij de overdracht van de watermeters regelen op dit adres?"
              onChange={(event) => handleSetNutsvoorzieningenChange(event.target.value)}
              variant="standard"
              fullWidth
               defaultValue='Nee'
            >
              <MenuItem value={'Ja'}>Ja</MenuItem>
              <MenuItem value={'Nee'}>Nee</MenuItem>
            </TextField>
          </Box>
        </div>
          <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecaanslaanw"
             select
             label="Is er reeds een elektriciteitsaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
        <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecmeterreedsgeopend"
             select
             label="Is de elektriciteitsmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasaanslaanw"
             select
             label="Is er reeds een gasaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasmeterreedsgeopend"
             select
             label="Is de aardgasmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
        </>
      )}

                          {contractType === "E" ||
                          contractType === "G" ||
                          contractType === "C" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <InputLabel htmlFor="aansluitingRadio"></InputLabel>
                                  <div>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={ver === "X"}
                                        onChange={handleVerChange}
                                      />
                                      Ik verhuis/Ik ben afgelopen maand verhuist
                                      naar dit adres.
                                    </label>
                                  </div>
                                </Box>
                              </div>

{ver === 'X' && (
  <div>
     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
         <TextField
           id="move"
           label="Datum van verhuis (dd/mm/yyyy)"
           value={move}
           onChange={handleMoveChange}
           variant="standard"
           required
           fullWidth
          placeholder="dd/mm/yyyy"

         />

       </Box>

  {/* Error message if the "Datum van verhuis" field is empty */}
  {move === '' && (
    <span style={{ color: 'red' }}>Datum van verhuis is verplicht.</span>
  )}

</div>
)}
 

                            
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="majmanradio"
                                    select
                                    label="Kan op dit adres al elektriciteit en/of aardgas afgenomen worden?"
                                    onChange={(event) =>
                                      handleMajManChange(event)
                                    }
                                    variant="standard"
                                    fullWidth
                                    defaultValue='maj'
                                  >
                                    <MenuItem value={"maj"}>Ja</MenuItem>
                                    <MenuItem value={"man"}>
                                      Nee, de meter is (nog) niet aangesloten
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>
                          <div className="userCardRequestColumnssolarCleaning">
                            <div className="columnFill">
                            

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code dag-nacht"
                                    variant="standard"
                                    type="text"
                                    id="eane"
                                    value={eane}
                                    onChange={handleEaneChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eane.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>
                            {/* <div className="columnFill">
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code excl. nacht"
                                    variant="standard"
                                    type="text"
                                    id="eann"
                                    value={eann}
                                    onChange={handleEannChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eann.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )} 
                            </div> */}
                          </div>

                          <h5>Voor de levering van Elektriciteit</h5>
                          
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="elektriciteitRadio"
                                select
                                label="Bestaand contract"
                                required
                                onChange={(event) => handleNegCmeChange(event)}
                                variant="standard"
                                fullWidth
                                defaultValue="neg"
                              >
                                <MenuItem value={"neg"}>
                                  Heb ik geen contract
                                </MenuItem>
                                <MenuItem value={"cme"}>
                                  Heb ik een contract met einddatum
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {cme === "X" && (
                        <div>
                        {/* Overstapdd mm yy veranderen */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapdd"
                                select
                                label="Dag einddatum"
                                value={cmedd}
                                onChange={handleCmeddChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 31 },
                                  (_, index) => index + 1,
                                ).map((day) => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                      

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapmm"
                                select
                                label="Maand einddatum"
                                value={cmemm}
                                onChange={handleCmemmChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 12 },
                                  (_, index) => index + 1,
                                ).map((month) => (
                                  <MenuItem key={month} value={month}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                        
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapyy"
                                select
                                label="Jaar einddatum"
                                value={cmeyy}
                                onChange={handleCmeyyChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 2 },
                                  (_, index) =>
                                    new Date().getFullYear() + index,
                                ).map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapRadio"
                                select
                                label="Overstap"
                                fullWidth
                                onChange={(event) => handleOecOsmChange(event)}
                                variant="standard"
                                
                              >
                                <MenuItem value={"oec"}>
                                  Ik wens over te stappen na de einddatum van
                                  mijn huidig contract
                                </MenuItem>
                                <MenuItem value={"osm"}>
                                  Ik wens zo snel mogelijk over te stappen naar
                                  Eneco
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>
                      )}
                      {contractType === "G" ||
                      contractType === "C" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Gas</h3>
                          <div className="userCardRequestColumnssolarCleaning">
                            <div className="columnFill">
                              

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="EAN-code gas *"
                                    variant="standard"
                                    type="text"
                                    id="eang"
                                    value={eang}
                                    onChange={handleEangChange}
                                    fullWidth
                                    inputProps={{
                                      pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                    }}
                                  />
                                </Box>
                              </div>
                              {eang.length !== 18 && (
                                <span style={{ color: "red" }}>
                                  Vul alsjeblieft 18 getallen in startende met
                                  54.
                                </span>
                              )}
                            </div>
                         
                          </div>
                          <h5>Voor de levering van Gas</h5>
                         
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="elektriciteitRadio"
                                select
                                label="Bestaand contract"
                                required
                                onChange={(event) =>
                                  handleNeggCmegChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="negg"
                              >
                                <MenuItem value={"negg"}>
                                  Heb ik geen contract
                                </MenuItem>
                                <MenuItem value={"cmeg"}>
                                  Heb ik een contract met einddatum
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}
                      {cmeg === "X" && (
                        <div>
                        
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapdd"
                                select
                                label="Dag einddatum"
                                value={cmeddg}
                                onChange={handleCmeddgChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 31 },
                                  (_, index) => index + 1,
                                ).map((day) => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapmm"
                                select
                                label="Maand einddatum"
                                value={cmemmg}
                                onChange={handleCmemmgChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 12 },
                                  (_, index) => index + 1,
                                ).map((month) => (
                                  <MenuItem key={month} value={month}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

            

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapyy"
                                select
                                label="Jaar einddatum"
                                value={cmeyyg}
                                onChange={handleCmeyygChange}
                                variant="standard"
                                fullWidth
                              >
                                {Array.from(
                                  { length: 2 },
                                  (_, index) =>
                                    new Date().getFullYear() + index,
                                ).map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="overstapRadio"
                                select
                                label="Overstap"
                                fullWidth
                                onChange={(event) =>
                                  handleOecgOsmgChange(event)
                                }
                                variant="standard"
                              
                              >
                                <MenuItem value={"oecg"}>
                                  Ik wens over te stappen na de einddatum van
                                  mijn huidig contract
                                </MenuItem>
                                <MenuItem value={"osmg"}>
                                  Ik wens zo snel mogelijk over te stappen naar
                                  Eneco
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>
                      )}
                      {contractType === "E" ||
                      contractType === "G" ||
                      contractType === "C" ? (
                        <>
                          <h5>Wijze van betaling</h5>

                          {/* <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                           <TextField
  id="VoorschotbedragRadio"
  select
  label="Wijze van betaling"
  required
  onChange={(event) => handleDomVmaeOveChange(event)}
  variant="standard"
  fullWidth
 
  disabled 
>
  <MenuItem value={"dom"}>
    Maandelijks via bankdomiciliëring
  </MenuItem>
  <MenuItem value={"vmae"}>
    Maandelijks via Visa/MasterCard/American Express
  </MenuItem> 
  <MenuItem value={"ove"}>
    Maandelijks via overschrijving
  </MenuItem>
</TextField> 

                            </Box>
                          </div>
*/}
                          {dom === "X" && (
                            <div>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="bankRadio"
                                    select
                                    label="Tussentijdse facturen"
                                    required
                                    onChange={(event) =>
                                      handleDomgfDomwfChange(event)
                                    }
                                    variant="standard"
                                    fullWidth
                                    defaultValue="domgf"
                                  >
                                    <MenuItem value={"domgf"}>
                                      Ik ontvang geen tussentijdse Eneco
                                      facturen
                                    </MenuItem>
                                    <MenuItem value={"domwf"}>
                                      Ik ontvang wel tussentijdse Eneco facturen
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                      
                            </div>
                          )}
                        </>
                      ) : (
                        <div></div>
                      )}
                      {contractType === "EP" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h5>Wijze van betaling</h5>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="VoorschotbedragRadio"
                                select
                                label="Wijze van betaling"
                                required
                                onChange={(event) =>
                                  handleDomVmaeOveChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="dom"
                                disabled
                                InputProps={{
                                  readOnly: true, // Make the field non-adjustable
                                }}
                              >
                                <MenuItem value={"dom"}>
                                  Maandelijks via bankdomiciliëring
                                </MenuItem>
                                <MenuItem value={"ove"}>
                                  Maandelijks via overschrijving
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {/* {vmae === "X" || dom === "X"  && ( */}
                      {vmae === "X"  && (
                        <div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="vmaeRadio"
                                select
                                label="Tussentijdse facturen"
                                required
                                onChange={(event) =>
                                  handleVmaegfVmaewfChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="vmaewf"
                              >
                                <MenuItem value={"vmaegf"}>
                                  Ik ontvang geen tussentijdse Eneco facturen
                                </MenuItem>
                                <MenuItem value={"vmaewf"}>
                                  Ik ontvang wel tussentijdse Eneco facturen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                  
                        </div>
                      )}
                

                  
                    </>
                  ) : (
                    <div></div>
                  )}
                  {/* Engie */}
                  {documentTemplateId ===
                    "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                  documentTemplateId ===
                    "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                  documentTemplateId ===
                    "062daa35-8613-5bce-aa7d-e7a24f79d108" ? (
                    <>
                      <h3>Basis gegevens</h3>

                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                         



    { contractType === "E" || contractType === "G" || contractType === "C" ? ( <>
      <div className="form-group">
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            id="gender"
            select
            label="Aanspreektitel"
            onChange={(event) => handleGenderChange(event.target.value)}
            variant="standard"
            fullWidth
            defaultValue='dhr'
          >
            <MenuItem value={'dhr'}>Dhr</MenuItem>
            <MenuItem value={'mevr'}>Mevr</MenuItem>
          </TextField>
        </Box>
        {genderError && (
          <span style={{ color: 'red' }}>Aanspreektitel is verplicht.</span>
        )}
     
      </div>
    </> ):<div></div>}

                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                             {/* Firmanaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Firmanaam"
      variant="standard"
      type="text"
      id="firmanaam"
      value={bedrijfsnaam}
      onChange={handleBedrijfsnaamChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Firmanaam" field is empty */}
  {bedrijfsnaam === '' && (
    <span style={{ color: 'red' }}>Firmanaam is verplicht.</span>
  )}
</div>

                             

                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="Vennootschapsvorm"
                                    select
                                    label="Vennootschapsvorm"
                                    value={vennootschapsvorm}
                                    onChange={handleVennootschapsvormChange}
                                    variant="standard"
                                    fullWidth
                                    required
                                    defaultValue='Eenmanszaak'
                                  >
                                    <MenuItem value="Eenmanszaak">
                                      Eenmanszaak
                                    </MenuItem>
                                    <MenuItem value="Vennootschap met beperkte aansprakelijkheid (BV)">
                                      Vennootschap met beperkte
                                      aansprakelijkheid (BV)
                                    </MenuItem>
                                    <MenuItem value="Naamloze vennootschap (NV)">
                                      Naamloze vennootschap (NV)
                                    </MenuItem>
                                    <MenuItem value="Coöperatieve vennootschap (CV)">
                                      Coöperatieve vennootschap (CV)
                                    </MenuItem>
                                    <MenuItem value="Vennootschap onder firma (VOF)">
                                      Vennootschap onder firma (VOF)
                                    </MenuItem>
                                    <MenuItem value="Commanditaire vennootschap (CommV)">
                                      Commanditaire vennootschap (CommV)
                                    </MenuItem>
                                    <MenuItem value="Maatschap">
                                      Maatschap
                                    </MenuItem>
                                    <MenuItem value="Europese vennootschap (SE)">
                                      Europese vennootschap (SE)
                                    </MenuItem>
                                    <MenuItem value="Vereniging zonder winstoogmerk (VZW)">
                                      Vereniging zonder winstoogmerk (vzw)
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>

                             {/* Ondernemingsnummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Ondernemingsnummer"
      variant="standard"
      type="text"
      id="Ondernemingsnummer"
      value={ondernemingsnummer}
      onChange={handleOndernemingsnummerChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Ondernemingsnummer" field is empty */}
  {ondernemingsnummer === '' && (
    <span style={{ color: 'red' }}>Ondernemingsnummer is verplicht.</span>
  )}
</div>


                            

                           {/* NACE Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="NACE"
      variant="standard"
      type="text"
      id="NACE"
      value={nace}
      onChange={handleNaceChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "NACE" field is empty */}
  {nace === '' && (
    <span style={{ color: 'red' }}>NACE is verplicht.</span>
  )}
</div>

                            </>
                          ) : (
                            <div></div>
                          )}

                          {/* Naam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Naam"
      variant="standard"
      type="text"
      id="nameFilter"
      value={name}
      onChange={handleNameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Naam" field is empty */}
  {name === '' && (
    <span style={{ color: 'red' }}>Naam is verplicht.</span>
  )}
</div>

{/* Voornaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Voornaam"
      variant="standard"
      type="text"
      id="surNameFilter"
      value={surname}
      onChange={handleSurnameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Voornaam" field is empty */}
  {surname === '' && (
    <span style={{ color: 'red' }}>Voornaam is verplicht.</span>
  )}
</div>

{/* Klantnummer Field (optional) */}
{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Klantnummer"
      variant="standard"
      type="text"
      id="klantnummerFilter"
      value={klantnummer}
      onChange={handleKlantnummerChange}
      fullWidth
    />
  </Box>
</div> */}



                        </div>

                        <div className="columnFill">
                        <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Telefoonnummer"
      variant="standard"
      type="text"
      id="phone"
      value={phone}
      onChange={handlePhoneChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Telefoonnummer" field is empty */}
  {phone === '' && (
    <span style={{ color: 'red' }}>Telefoonnummer is verplicht.</span>
  )}
</div>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Vaste Lijn"
                                variant="standard"
                                type="text"
                                id="telphone"
                                value={telphone}
                                onChange={handleTelphoneChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                          {contractType === "E" ||
                          contractType === "G" ||
                          contractType === "C" ? (
                            <>
                         
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
           
   <TextField
              id="Birthdate"
              label="Geboortedatum (dd/mm/yyyy)"
              value={birthdate}
              onChange={handleBirthdateChange}
              variant="standard"
              required
              fullWidth
              placeholder='dd/mm/yyyy'
               inputProps={{
                 pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
                 placeholder: "dd/mm/yyyy",
               }}
            /> 
                                </Box>
                                {birthdate === '' && (
    <span style={{ color: 'red' }}>Geboortedatum is verplicht.</span>
  )}
                              </div>
                              {/* Email Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Email"
      variant="standard"
      type="text"
      id="emailFilter"
      value={email}
      onChange={handleEmailChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Email" field is empty */}
  {email === '' && (
    <span style={{ color: 'red' }}>Email is verplicht.</span>
  )}
</div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <h3>Facturatieadres</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                          {/* Firmanaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Firmanaam"
      variant="standard"
      type="text"
      id="Firmanaam"
      value={verzendfirmname}
      onChange={handleVerzendfirmnameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Firmanaam" field is empty */}
  {verzendfirmname === '' && (
    <span style={{ color: 'red' }}>Firmanaam is verplicht.</span>
  )}
</div>



<div className="form-group">
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="Juridische vorm"
          select
          label="Juridische vorm"
          value={verzendjurform}
          onChange={handleVerzendjurformChange}
          variant="standard"
          fullWidth
          required
        >
          <MenuItem value='Eenmanszaak'>Eenmanszaak</MenuItem>
          <MenuItem value='Vennootschap met beperkte aansprakelijkheid (BV)'>
            Vennootschap met beperkte aansprakelijkheid (BV)
          </MenuItem>
          <MenuItem value='Naamloze vennootschap (NV)'>
            Naamloze vennootschap (NV)
          </MenuItem>
          <MenuItem value='Coöperatieve vennootschap (CV)'>
            Coöperatieve vennootschap (CV)
          </MenuItem>
          <MenuItem value='Vennootschap onder firma (VOF)'>
            Vennootschap onder firma (VOF)
          </MenuItem>
          <MenuItem value='Commanditaire vennootschap (CommV)'>
            Commanditaire vennootschap (CommV)
          </MenuItem>
          <MenuItem value='Maatschap'>Maatschap</MenuItem>
          <MenuItem value='Europese vennootschap (SE)'>
            Europese vennootschap (SE)
          </MenuItem>
          <MenuItem value='Vereniging zonder winstoogmerk (VZW)'>
            Vereniging zonder winstoogmerk (vzw)
          </MenuItem>
        </TextField> 
      
      </Box>
    </div>

                            </>
                          ) : (
                            <div></div>
                          )}
                        {/* Straatnaam Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam"
      variant="standard"
      type="text"
      id="streetname"
      value={streetname}
      onChange={handleStreetnameChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Straatnaam" field is empty */}
  {streetname === '' && (
    <span style={{ color: 'red' }}>Straatnaam is verplicht.</span>
  )}
</div>

{/* Huisnummer Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer"
      variant="standard"
      type="text"
      id="housenumber"
      value={housenumber}
      onChange={handleHousenumberChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Huisnummer" field is empty */}
  {housenumber === '' && (
    <span style={{ color: 'red' }}>Huisnummer is verplicht.</span>
  )}
</div>


                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Bus"
                                variant="standard"
                                type="text"
                                id="bus"
                                value={bus}
                                onChange={handleBusChange}
                                fullWidth
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="columnFill">
                    {/* Postcode Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode"
      variant="standard"
      type="text"
      id="zipcode"
      value={zipcode}
      onChange={handleZipcodeChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Postcode" field is empty */}
  {zipcode === '' && (
    <span style={{ color: 'red' }}>Postcode is verplicht.</span>
  )}
</div>

{/* Stad / Gemeente Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Stad / Gemeente"
      variant="standard"
      type="text"
      id="city"
      value={city}
      onChange={handleCityChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Stad / Gemeente" field is empty */}
  {city === '' && (
    <span style={{ color: 'red' }}>Stad / Gemeente is verplicht.</span>
  )}
</div>

                        </div>
                      </div>
                      {/* Checkbox adres Engie */}
                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Het leveringsadres (afnamepunt) is dezelfde als het
                          facturatieadres.
                        </Box>
                      </div>

                      <h3>Leveringsadres (Afnamepunt)</h3>
                      <div className="userCardRequestColumnssolarCleaning">
                        <div className="columnFill">
                       {/* Straatnaam Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam Afnamepunt"
      variant="standard"
      type="text"
      id="installaddress"
      value={installaddress}
      onChange={handleInstallAddressChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Straatnaam Afnamepunt" field is empty */}
  {installaddress === '' && (
    <span style={{ color: 'red' }}>Straatnaam Afnamepunt is verplicht.</span>
  )}
</div>

{/* Huisnummer Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer Afnamepunt"
      variant="standard"
      type="text"
      id="installnumber"
      value={installnumber}
      onChange={handleInstallNumberChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Huisnummer Afnamepunt" field is empty */}
  {installnumber === '' && (
    <span style={{ color: 'red' }}>Huisnummer Afnamepunt is verplicht.</span>
  )}
</div>

{/* Bus Afnamepunt Field (optional) */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Bus Afnamepunt"
      variant="standard"
      type="text"
      id="installbus"
      value={installbus}
      onChange={handleInstallBusChange}
      fullWidth
    />
  </Box>
</div>

{/* Verdiep Afnamepunt Field (optional) */}
{/* <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Verdiep Afnamepunt"
      variant="standard"
      type="text"
      id="installfloor"
      value={installfloor}
      onChange={handleInstallFloorChange}
      fullWidth
    />
  </Box>
</div> */}

                        </div>

                        <div className="columnFill">
                    {/* Postcode Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode Afnamepunt"
      variant="standard"
      type="text"
      id="installzipcode"
      value={installzipcode}
      onChange={handleInstallZipcodeChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Postcode Afnamepunt" field is empty */}
  {installzipcode === '' && (
    <span style={{ color: 'red' }}>Postcode Afnamepunt is verplicht.</span>
  )}
</div>

{/* Gemeente Afnamepunt Field */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Gemeente Afnamepunt"
      variant="standard"
      type="text"
      id="installcity"
      value={installcity}
      onChange={handleInstallCityChange}
      required
      fullWidth
    />
  </Box>
  {/* Error message if the "Gemeente Afnamepunt" field is empty */}
  {installcity === '' && (
    <span style={{ color: 'red' }}>Gemeente Afnamepunt is verplicht.</span>
  )}
</div>
<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                 
                     <TextField
                    id="verhuis"
                    select
                    label="Is dit een verhuis?"
                    onChange={(event) => handleSetVerhuisChange(event.target.value)}
                    variant="standard"
                    fullWidth
                    defaultValue='Nee'
                  >
        <MenuItem value={'Ja'}>Ja</MenuItem>
        <MenuItem value={'Nee'}>Nee</MenuItem>

                  </TextField>
                </Box>
              </div>

{verhuis == 1 && (
        <>
        <div className="form-group">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              id="nutsvoorziening"
              select
              label="Wilt u dat wij de overdracht van de watermeters regelen op dit adres?"
              //value={nutsvoorzieningen}
              onChange={(event) => handleSetNutsvoorzieningenChange(event.target.value)}
              variant="standard"
              fullWidth
             defaultValue='Nee'
            >
              <MenuItem value={'Ja'}>Ja</MenuItem>
              <MenuItem value={'Nee'}>Nee</MenuItem>
            </TextField>
          </Box>
        </div>
        
         <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecaanslaanw"
             select
             label="Is er reeds een elektriciteitsaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="elecmeterreedsgeopend"
             select
             label="Is de elektriciteitsmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetelektriciteitsmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasaanslaanw"
             select
             label="Is er reeds een gasaansluiting aanwezig?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasaansluitingChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       <div className="form-group">
         <Box sx={{ display: "flex", alignItems: "flex-end" }}>
           <TextField
             id="gasmeterreedsgeopend"
             select
             label="Is de aardgasmeter reeds geopend?"
             //value={nutsvoorzieningen}
             onChange={(event) => handleSetgasmetergeopendChange(event.target.value)}
             variant="standard"
             fullWidth
            defaultValue='Ja'
           >
             <MenuItem value={'Ja'}>Ja</MenuItem>
             <MenuItem value={'Nee'}>Nee</MenuItem>
           </TextField>
         </Box>
       </div>
       </>
      )}

{ contractType === "E" || contractType === "C" || contractType === "EP" || contractType === "CP" ? (
    <>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean elektriciteit *"
                                variant="standard"
                                type="text"
                                id="eane"
                                value={eane}
                                onChange={handleEaneChange}
                                fullWidth
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eane.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}
</>):<div></div>}
{ contractType === "G" || contractType === "C" || contractType === "GP" || contractType === "CP" ? (
    <>

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean aardgas *"
                                variant="standard"
                                type="text"
                                id="eang"
                                value={eang}
                                fullWidth
                                onChange={handleEangChange}
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eang.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}
                 </>):<div></div>}
                 {/* { contractType === "E" || contractType === "C" || contractType === "EP" || contractType === "CP" ? (
    <>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                label="Ean exclusief nacht"
                                variant="standard"
                                type="text"
                                id="eann"
                                value={eann}
                                fullWidth
                                onChange={handleEannChange}
                                inputProps={{
                                  pattern: "^54[0-9]{16}$", // Enforce 18 digits starting with "54"
                                }}
                              />
                            </Box>
                          </div>
                          {eann.length !== 18 && (
                            <span style={{ color: "red" }}>
                              Vul alsjeblieft 18 getallen in startende met 54.
                            </span>
                          )}
</>):<div></div>} */}
                          {contractType === "EP" ||
                          contractType === "GP" ||
                          contractType === "CP" ? (
                            <>
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    id="usageRadio"
                                    select
                                    label="Gemengd of uitsluitend professioneel vebruik?"
                                    required
                                    onChange={(event) =>
                                      handleGpvUpvChange(event)
                                    }
                                    variant="standard"
                                    defaultValue="upv"
                                    fullWidth
                                  >
                                    <MenuItem value={"gpv"}>
                                      Gemengd professioneel verbruik
                                    </MenuItem>
                                    <MenuItem value={"upv"}>
                                      Uitsluitend professioneel verbruik
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      {/* <h3>Klant</h3>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="aansluitingRadio"
                            select
                            label="Contract"
                            required
                            onChange={(event) => handleAceGceNegChange(event)}
                            variant="standard"
                            fullWidth
                          
                          >
                            <MenuItem value={"ace"}>
                              U hebt al een contract bij ENGIE
                            </MenuItem>
                            <MenuItem value={"gce"}>
                              U hebt nog geen contract bij ENGIE
                            </MenuItem>
                            <MenuItem value={"neg"}>
                              U hebt nog nooit een energiecontract gehad
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div> */}
                     
                      {contractType === "E" ||
                      contractType === "C" ||
                      contractType === "EP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Elektriciteit</h3>

                          {/* <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="supplierRadio"
                                select
                                label="Situatie"
                                required
                                onChange={(event) =>
                                  handleVbElaBewEenChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="vb"
                              >
                                <MenuItem value={"vb"}>
                                  U verhuist of bouwt
                                </MenuItem>
                                <MenuItem value={"ela"}>
                                  U hebt al een elektriciteitscontract bij een
                                  andere leverancier
                                </MenuItem>
                                <MenuItem value={"bew"}>
                                  U wilt uw bestaande elektriciteitsconstract
                                  wijzigen
                                </MenuItem>
                                <MenuItem value={"een"}>
                                  U wilt een contract voor een extra energievorm
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                          {ela === "X" && ( // Render the nested radio buttons only when ela is checked
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                              <TextField
                                  label="Huidige leverancier"
                                  variant="standard"
                                  type="text"
                                  id="leverancier"
                                  value={leverancier}
                                  onChange={handleLeverancierChange}
                                  fullWidth
                                /> 
            
 
                              </Box>
                              {leverancier === '' && (
    <span style={{ color: 'red' }}>Huidige leverancier is verplicht.</span>
  )}
                            </div>
                          )}
                     */}

                          <h3>Meter</h3>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="meterRadio"
                                select
                                label="Staat meter"
                                required
                                onChange={(event) =>
                                  handleEbaEbnEboChange(event)
                                }
                                variant="standard"
                                fullWidth
                               defaultValue='eba'
                              >
                                <MenuItem value={"eba"}>
                                  Uw elektriciteitsmeter is al geopend
                                </MenuItem>
                                <MenuItem value={"ebn"}>
                                  U wilt een nieuwe elektriciteitsmeter openen
                                </MenuItem>
                                <MenuItem value={"ebo"}>
                                  U wilt een bestaande elektriciteitsmeter
                                  openen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

           

                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                      

<TextField
  id="datumoverschakeling"
  label="Datum gewenste overschakeling (dd/mm/yyyy)"
  value={datumoverschakeling}
  onChange={handleDatumoverschakelingChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  inputProps={{
    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
    title: "Datum moet het formaat dd/mm/yyyy hebben",
  }}
/>
                            </Box>
                            {datumoverschakeling === '' && (
    <span style={{ color: 'red' }}>Datum gewenste overschakeling is verplicht.</span>
  )}
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      {contractType === "G" ||
                      contractType === "C" ||
                      contractType === "GP" ||
                      contractType === "CP" ? (
                        <>
                          <h3>Gas</h3>

                          {/* <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="supplierRadio"
                                select
                                label="Situatie"
                                required
                                onChange={(event) =>
                                  handleVbgElagBewgEengChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue="vbg"
                              >
                                <MenuItem value={"vbg"}>
                                  U verhuist of bouwt
                                </MenuItem>
                                <MenuItem value={"elag"}>
                                  U hebt al een gascontract bij een andere
                                  leverancier
                                </MenuItem>
                                <MenuItem value={"bewg"}>
                                  U wilt uw bestaande gascontract wijzigen
                                </MenuItem>
                                <MenuItem value={"eeng"}>
                                  U wilt een contract voor een extra energievorm
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                          {elag === "X" && ( // Render the nested radio buttons only when ela is checked
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  label="Huidige leverancier"
                                  variant="standard"
                                  type="text"
                                  id="leverancierg"
                                  value={leverancierg}
                                  onChange={handleLeveranciergChange}
                                  fullWidth
                                /> 
    
                              </Box>
                              {leverancierg === '' && (
    <span style={{ color: 'red' }}>Huidige leverancier is verplicht.</span>
  )}
                            </div>
                          )} */}

                          <h3>Meter</h3>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="meterRadio"
                                select
                                label="Staat meter"
                                required
                                onChange={(event) =>
                                  handleGbaGbnGboChange(event)
                                }
                                variant="standard"
                                fullWidth
                                defaultValue='gba'
                              
                              >
                                <MenuItem value={"gba"}>
                                  Uw gasmeter is al geopend
                                </MenuItem>
                                <MenuItem value={"gbn"}>
                                  U wilt een nieuwe gasmeter openen
                                </MenuItem>
                                <MenuItem value={"gbo"}>
                                  U wilt een bestaande gasmeter openen
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

                          <h3>Voorschot</h3>
                    
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                      

<TextField
  id="datumoverschakeling"
  label="Datum gewenste overschakeling (dd/mm/yyyy)"
  value={datumoverschakelingG}
  onChange={handleDatumoverschakelingGChange}
  variant="standard"
  required
  fullWidth
  placeholder="dd/mm/yyyy"
  inputProps={{
    pattern: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
    title: "Datum moet het formaat dd/mm/yyyy hebben",
  }}
/>
                            </Box>
                            {datumoverschakelingG === '' && (
    <span style={{ color: 'red' }}>Datum gewenste overschakeling is verplicht.</span>
  )}
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}

                      <h3>Betalingsmodaliteiten</h3>

                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="opteertRadio"
                            select
                            label="U opteert voor"
                            required
                            onChange={(event) => handleMaandChange(event)}
                            variant="standard"
                            fullWidth
                            defaultValue="maandelijks"
                            disabled // Make the field uneditable
                            InputProps={{
                              style: {
                                color: "rgba(0, 0, 0, 0.38)", // Change the color for disabled state
                              },
                            }}
                          >
                            <MenuItem value={"maandelijks"}>
                              maandelijks
                            </MenuItem>
                  
                          </TextField>
                        </Box>
                      </div> */}

                

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="vooRadio"
                            select
                            label="U wenst uw facturen en bijbehorende correspondentie te ontvangen"
                            required
                            onChange={(event) =>
                              handleCorrespondentieChange(event)
                            }
                            variant="standard"
                            fullWidth
                            defaultValue='df'
                           
                          >
                            <MenuItem value={"df"}>E-billing</MenuItem>
                            <MenuItem value={"pp"}>Per post</MenuItem>
                          </TextField>
                        </Box>
                      </div>

                    

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="betRadio"
                            select
                            label="U betaalt"
                            required
                            onChange={(event) => handleBetalingChange(event)}
                            variant="standard"
                            fullWidth
                            defaultValue="dom"
                          >
                            <MenuItem value={"dom"}>SEPA</MenuItem>
                            <MenuItem value={"ove"}>
                              Per overschrijving
                            </MenuItem>
                            <MenuItem value={"ib"}>
                              Via een nieuwe domiciliëring met een nieuw
                              rekeningnummer
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div>

 
    {ib === 'X' || dom ==='X' && ( 
             <div className="form-group">
             <Box sx={{ display: "flex", alignItems: "flex-end" }}>
               <TextField
                 label="IBAN rekeningnummer"
                 variant="standard"
                 type="text"
                 id="iban"
                 value={iban}
                 required
                 onChange={handleIbanChange}
                 fullWidth
               />
             </Box>
             </div>
          )}


    
    
    
              </>) : (<div></div>)}

{/*              
                  {documentTemplateId !==
                  "191daa35-8613-5bce-aa7d-z8c86p88n100" ? (
                  
                    
                    <button
                      className="buttonstyle33"
                      onClick={() => handleSaveFilledDocument()}
                    >
                      Doorgaan
                    </button>
                  ) : (
                    <div>Redirecting...</div>
                  )}  */}
                   
          
          {/*luminus*/}
          {contractType === "E" &&
      (documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
       documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138") ? (
  <>
    {/* Fields specific to E */}
    {(
      // jed && jen && jeen && sjep && mve &&
      (vs || vh)
       && birthdate &&
      (eba || ebn) && eane) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "G" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138")  ? (
  <>
    {/* Fields specific to G */}
    {(
      // jg && sjgp && mvg &&
      (vs || vh) 
       && birthdate &&
      (gba || gbn) 
       && eang && datumoverschakelingG) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "C" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138") ? (
  <>
    {/* Fields specific to C */}
    {(
      // jed && jen && jeen && sjep && mve &&
      (vs || vh) 
       && birthdate &&
      (gba || gbn) 
       && eang) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "EP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138") ? (
  <>
    {/* Fields specific to EP */}
    {(
      bedrijfsnaam && vennootschapsvorm && ondernemingsnummer &&
      // jed && jen && jeen && mve &&
      (mevr || dhr) &&
      surname && name && email &&
      streetname && housenumber && zipcode && city &&
      installaddress && installnumber && installzipcode && installcity &&
      (dom || df || ove) &&
      (vsa || vse) &&
      (eba || ebn) 
       && eane) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "GP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138") ? (
  <>
    {/* Fields specific to GP */}
    {(
      bedrijfsnaam && vennootschapsvorm && ondernemingsnummer &&
      (mevr || dhr) &&
      surname && name && email &&
      streetname && housenumber && zipcode && city &&
      installaddress && installnumber && installzipcode && installcity &&
      (dom || df || ove) &&
      (vsa || vse) &&
      (gba || gbn) 
       && eang && datumoverschakelingG) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "CP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d861" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d120" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d121" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d122" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d123" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d124" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d125" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d130" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d131" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d132" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d126" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d127" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d128" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d133" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d134" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d135" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d136" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d137" ||
 documentTemplateId === "058daa35-8613-5bce-aa7d-e7a24f79d138")  ? (
  <>
    {/* Fields specific to CP */}
    {(
      bedrijfsnaam && vennootschapsvorm && ondernemingsnummer &&
      // jen && jeen && mve &&
      // jg && mvg && sjep &&
      (mevr || dhr) &&
      surname && name && email &&
      streetname && housenumber && zipcode && city &&
      installaddress && installnumber && installzipcode && installcity &&
      (dom || df || ove) &&
      (vsa || vse) &&
      (eba || ebn) 
       && eane ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : (
  <div></div>
)}



{/* Eneco */} 
{contractType === "E" &&
      (documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
       documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
       documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
       documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
       documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
       documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
       documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
       documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
       documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883") ? (
  <>
    {(
     (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity &&
     eane && (neg || cme)  && identitynumber && birthdate && (maj|| man)) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "G" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
 documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883")  ? (
  <>
    {(
      (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity &&
    identitynumber && birthdate && (maj || man) && eang && (negg || cmeg) 
    ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "C" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
 documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883")  ? (
  <>
    {(
        (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity &&
        eane && (neg || cme) && identitynumber && birthdate && (maj|| man)  && eang && (negg || cmeg) ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "EP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
 documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883") ? (
  <>
    {(
           bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && eane && (neg || cme) ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "GP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
 documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883") ? (
  <>
    {(
     bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && eang && (negg || cmeg)) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "CP" &&
(documentTemplateId === "473daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "880daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "881daa35-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "890daa35-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "981daa35-8613-5bce-aa7d-e7a24f79d883" ||
 documentTemplateId === "47312335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "88112335-8613-4bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "89012335-8613-4bce-aa7d-e7a22f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d881" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d882" ||
 documentTemplateId === "98112335-8613-5bce-aa7d-e7a24f79d883")  ? (
  <>
    {(
     bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && (dhr || mevr) && name && surname && phone && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && eang && (negg || cmeg) && eane && (neg || cme)) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : (
  <div></div>
)}


{/* Engie */} 
{contractType === "E" &&
      (
        documentTemplateId ===
                            "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                          documentTemplateId ===
                            "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                          documentTemplateId ===
                            "062daa35-8613-5bce-aa7d-e7a24f79d108") ? (
  <>
    {(
     (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && (ace || gce || neg) && (df || pp) && birthdate

     && eane && datumoverschakeling) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "G" &&
(
  documentTemplateId ===
                      "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d108")  ? (
  <>
    {(
      (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && (ace || gce || neg) && (df || pp) && birthdate

      && eang && datumoverschakelingG
    ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "C" &&
(
  documentTemplateId ===
                      "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d108")  ? (
  <>
    {(
        (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && (ace || gce || neg) && (df || pp) && birthdate

        && eane && (vb || ela || bew || een) && datumoverschakeling
        && eang && datumoverschakelingG
         ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "EP" &&
(
  documentTemplateId ===
                      "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d108") ? (
  <>
    {(
           (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && (ace || gce || neg) && (df || pp) && bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && nace && verzendfirmname && verzendjurform

           && eane && datumoverschakeling
           ) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "GP" &&
(
  documentTemplateId ===
                      "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d108") ? (
  <>
    {(
     (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber && installzipcode && installcity && (ace || gce || neg) && (df || pp) && bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && nace && verzendfirmname && verzendjurform

     && eang && datumoverschakelingG) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : contractType === "CP" &&
(
  documentTemplateId ===
                      "473daa35-8613-4bce-aa7d-e7a22f79d871" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d110" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d111" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d112" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d113" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d114" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d115" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d116" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d117" ||
                    documentTemplateId ===
                      "058daa35-8613-5bce-aa7d-e7a24f79d118" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d100" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d101" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d102" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d103" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d104" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d105" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d106" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d107" ||
                    documentTemplateId ===
                      "062daa35-8613-5bce-aa7d-e7a24f79d108")  ? (
  <>
    {(
     (dhr || mevr) && name && surname && email && streetname && housenumber && zipcode && city && installaddress && installnumber  && installzipcode && installcity && (ace || gce || neg) && (df || pp) && bedrijfsnaam && vennootschapsvorm && ondernemingsnummer && nace && verzendfirmname && verzendjurform

     && eane && (vb || ela || bew || een) && datumoverschakeling
     && eang && datumoverschakelingG) ? (
        <button className="buttonstyle33" onClick={() => handleSaveFilledDocument()}>Doorgaan</button>
      ) : (
        <div>Vul alle velden correct in alvorens een contract aan te vragen</div>
      )}
  </>
) : (
  <div></div>
)}
                </div>
              </div>
              <div className="textColumnsolar">
                <div className="textColumnElement">
                  <SquareComponent />
                  <SquareComponentIt />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default DocumentFill;
